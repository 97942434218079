import { createReducer } from '@reduxjs/toolkit';
import { changeShowGeopupAction } from '../../actions/UtilActions';

export interface UtilState {
  showGeoPopup: boolean;
}

const initialState: UtilState = {
  showGeoPopup: false,
};

export const utilReducer = createReducer<UtilState>(initialState, (builder) => {
  builder.addCase(changeShowGeopupAction, (state, action) => {
    state.showGeoPopup = action.payload.show;
  });
});

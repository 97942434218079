import { createReducer } from '@reduxjs/toolkit';
import { DeliveryMethod, DeliveryMethodType } from '../../../models';
import {
  getDeliveryMethodsAction,
  getDeliveryMethodsSuccessAction,
} from '../../actions/DeliveryMethodsActions';
import { addOrderDeliveryMethodSuccessAction } from '../../actions/OrderActions';
import { getDefaultUserAddressesSuccessAction } from '../../actions/UserAddressesActions';

export interface DeliveryMethodsState {
  loading: boolean;
  deliveryMethods: DeliveryMethod[];
}

const initialState: DeliveryMethodsState = {
  deliveryMethods: [],
  loading: false,
};

export const deliveryMethodsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDeliveryMethodsAction, (state) => {
      state.loading = true;
    })
    .addCase(getDeliveryMethodsSuccessAction, (state, action) => {
      state.loading = false;
      state.deliveryMethods = action.payload.deliveryMethods;
    })
    .addCase(addOrderDeliveryMethodSuccessAction, (state, action) => {
      state.deliveryMethods = state.deliveryMethods.map((deliveryMethod) => {
        if (deliveryMethod.id === action.payload.deliveryMethod.id) {
          return action.payload.deliveryMethod;
        }
        return deliveryMethod;
      });
    })
    .addCase(getDefaultUserAddressesSuccessAction, (state, action) => {
      state.deliveryMethods = state.deliveryMethods.map((deliveryMethod) => {
        if (deliveryMethod.type !== DeliveryMethodType.parcelLocker) {
          return deliveryMethod;
        }

        let parcelLockerData;
        // remove check once backend is returning correct data
        if (Array.isArray(action.payload.defaultAddresses[DeliveryMethodType.parcelLocker])) {
          parcelLockerData = action.payload.defaultAddresses[DeliveryMethodType.parcelLocker].find(
            (dm) => dm.provider === deliveryMethod.code
          );
        }

        // const parcelLockerData = action.payload.defaultAddresses[DeliveryMethodType.parcelLocker] as unknown as ParcelLockerAddress;
        if (parcelLockerData) {
          return { ...deliveryMethod, parcelLockerCode: parcelLockerData.parcelLockerCode };
        }
        return deliveryMethod;
      });
    });
});

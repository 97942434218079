import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import {
  deleteOrderDeliveryAddress,
  updateOrderBillingAddress,
  updateOrderDeliveryAddress,
  updateOrderDeliveryAddressId,
} from 'api/order/OrderAddressClient';
import {
  DeliveryAddress,
  DeliveryMethod,
  DeliveryMethodType,
  InvoiceData,
  OrderStatus,
  UserAddress,
} from 'models/data';
import { addOrderDeliveryMethod, getDeliveryMethods } from 'api/order/OrderDeliveryMethodClient';
import { addOrderInvoice, deleteOrderInvoice } from 'api/order/OrderInvoiceClient';
import { push } from 'connected-react-router';
import {
  selectBillingAddress,
  selectInvoiceData,
  selectOrderId,
  selectOrderNotes,
  selectOrderState,
  selectSelectedDeliveryMethod,
} from '../../selectors';
import {
  addOrderBillingAddressAction,
  addOrderBillingAddressSuccessAction,
  addOrderDeliveryAddressAction,
  addOrderDeliveryAddressByIdAction,
  addOrderDeliveryAddressSuccessAction,
  addOrderDeliveryMethodAction,
  addOrderDeliveryMethodSuccessAction,
  addOrderInvoiceDataAction,
  addOrderInvoiceDataSuccessAction,
  addOrderNotesAction,
  addOrderNotesSuccessAction,
  addOrderPaymentMethodAction,
  addOrderPaymentMethodSuccessAction,
  changeProductAmountAction,
  changeProductAmountSuccessAction,
  deleteOrderInvoiceDataAction,
  deleteOrderInvoiceDataSuccessAction,
  deleteOrderNotesAction,
  deleteOrderNotesSuccessAction,
  deleteProductAction,
  deleteProductSuccessAction,
  getOrderAction,
  getOrderStatusAction,
  getOrderStatusSuccessAction,
  getOrderSuccessAction,
  renewOrderStatusAction,
  renewOrderStatusSuccessAction,
  setAddDeliveryAddressToOrderAction,
  submitOrderAction,
  submitOrderErrorAction,
  submitOrderSuccessAction,
} from '../../actions/OrderActions';
import { Order } from '../../../models/data/Order';
import { cancelOrder, getOrder, submitOrder } from '../../../api/order/OrderClient';
import { addOrderPaymentMethod } from '../../../api/order/OrderPaymentMethodClient';
import { FormValidationError } from '../../../models/errors/FormValidationError';
import {
  addBillingAddressFormErrorsAction,
  addDeliveryAddressFormErrorsAction,
  addInvoiceFormErrorsAction,
} from '../../actions/FormActions';
import {
  selectDefaultAddresses,
  selectUserAddresses,
} from '../../selectors/userAddressesSelectors';
import { addOrderNotes, deleteOrderNotes } from '../../../api/order/OrderNotesClient';
import { Maybe } from '../../../utils/maybe';
import { DefaultAddresses } from '../../../models/data/DefaultAddresses';
import { NotesData } from '../../../models/data/NotesData';
import { OrderValidationError } from '../../../models/errors/OrderValidationError';
import { OrderState } from '../../reducers/order/orderReducer';
import { calculateOrderValue } from '../../../utils/orderValue/orderValue';
import { PaymentFinalizeResponse } from '../../../models/data/PaymentFinalizeResponse';
import { getOrderStatus, renewOrderStatus } from '../../../api/order/OrderStatusClient';
import { getEnvs } from '../../../utils/env';
import { selectPathname } from '../../selectors/reduxRouterSelectors';
import { paymentPendingAction } from '../../actions/PaymentActions';
import { changeProductQuantity, deleteProduct } from '../../../api/order/OrderProductClient';
import { cancelOrderAction, cancelOrderSuccessAction } from '../../slices/cartSlice';
import { getDeliveryMethodsAction } from '../../actions/DeliveryMethodsActions';

function* addOrderDeliveryMethodSaga(action: Action) {
  if (addOrderDeliveryMethodAction.match(action)) {
    const orderId: number = yield select(selectOrderId);

    yield call(
      addOrderDeliveryMethod,
      orderId,
      action.payload.deliveryMethod.id,
      action.payload.deliveryMethod.parcelLockerCode
    );
    yield put(addOrderDeliveryMethodSuccessAction(action.payload.deliveryMethod));
  }
}

function* addOrderPaymentMethodSaga(action: Action) {
  if (addOrderPaymentMethodAction.match(action)) {
    const orderId: number = yield select(selectOrderId);
    yield call(
      addOrderPaymentMethod,
      orderId,
      action.payload.paymentMethodId,
      action.payload.subPaymentId
    );
    yield put(addOrderPaymentMethodSuccessAction(action.payload.paymentMethodId));
  }
}

function* addOrderBillingAddressSaga(action: Action) {
  if (addOrderBillingAddressAction.match(action)) {
    const orderId: string = yield select(selectOrderId);
    try {
      yield call(updateOrderBillingAddress, action.payload.address, orderId);
      yield put(addOrderBillingAddressSuccessAction(action.payload.address));
    } catch (e) {
      console.log(e);
      if (e instanceof FormValidationError) {
        yield put(addBillingAddressFormErrorsAction(e.formErrors()));
        return;
      }
      throw e;
    }
  }
}

function* addOrderDeliveryAddressSaga(action: Action) {
  if (addOrderDeliveryAddressAction.match(action)) {
    const orderId: string = yield select(selectOrderId);
    try {
      yield call(updateOrderDeliveryAddress, action.payload.address, orderId);
      yield put(addOrderDeliveryAddressSuccessAction(action.payload.address));
    } catch (e) {
      if (e instanceof FormValidationError) {
        yield put(addDeliveryAddressFormErrorsAction(e.formErrors()));
        return;
      }
      throw e;
    }
  }

  if (setAddDeliveryAddressToOrderAction.match(action)) {
    if (action.payload.addDeliveryAddressToOrder) {
      return;
    }
    const orderId: string = yield select(selectOrderId);

    yield call(deleteOrderDeliveryAddress, orderId);
  }
}

function* addOrderInvoiceDataSaga(action: Action) {
  if (addOrderInvoiceDataAction.match(action)) {
    const orderId: string = yield select(selectOrderId);
    try {
      yield call(addOrderInvoice, action.payload.invoice, orderId);
      yield put(addOrderInvoiceDataSuccessAction(action.payload.invoice));
    } catch (e) {
      if (e instanceof FormValidationError) {
        yield put(addInvoiceFormErrorsAction(e.formErrors()));
        return;
      }
      throw e;
    }
  }
}

function* deleteOrderInvoiceDataSaga() {
  const orderId: string = yield select(selectOrderId);
  yield call(deleteOrderInvoice, orderId);
  yield put(deleteOrderInvoiceDataSuccessAction());
}

function* deleteOrderNotesSaga() {
  const orderId: string = yield select(selectOrderId);
  yield call(deleteOrderNotes, orderId);
  yield put(deleteOrderNotesSuccessAction());
}

export function* getOrderSaga(action: Action) {
  if (getOrderAction.match(action)) {
    const order: Order = yield call(getOrder, action.payload.id);

    const pathname: string = yield select(selectPathname);
    const orderUrlRegex = /order\/[0-9a-zA-z\-]*/;
    if (pathname.match(orderUrlRegex)) {
      if (
        ![
          OrderStatus.CREATED,
          OrderStatus.PAYMENT_RENEW,
          OrderStatus.PAYMENT_STARTED,
          OrderStatus.PAYMENT_NOT_STARTED,
          OrderStatus.FINALIZED,
        ].includes(order.status)
      ) {
        yield put(push(`/order/${order.id}/thank-you`));
      }
    }
    yield put(getOrderSuccessAction(order));
  }
}

function* addOrderDeliveryAddressIdSaga(action: Action) {
  if (addOrderDeliveryAddressByIdAction.match(action)) {
    const orderId: string = yield select(selectOrderId);
    const userAddresses: UserAddress[] = yield select(selectUserAddresses);
    const selectedAddress = userAddresses.find(
      (address) => address.id === action.payload.addressId
    );
    const address: DeliveryAddress = selectedAddress!;
    yield call(updateOrderDeliveryAddressId, selectedAddress!, orderId);
    yield put(addOrderDeliveryAddressSuccessAction(address));
  }
}

function* addOrderNotesSaga(action: Action) {
  if (addOrderNotesAction.match(action)) {
    const orderId: string = yield select(selectOrderId);
    yield call(addOrderNotes, action.payload.notes, orderId);
    yield put(addOrderNotesSuccessAction(action.payload.notes));
  }
}

function* submitOrderSaga() {
  const orderState: OrderState = yield select(selectOrderState);
  const {
    billingAddress,
    parcelLockerAddress,
    addInvoiceToOrder,
    addNotesToOrder,
    products,
    commission,
  } = orderState;
  const deliveryMethod: DeliveryMethod = yield select(selectSelectedDeliveryMethod);

  if (!deliveryMethod) {
    yield put(submitOrderErrorAction(['Proszę wybrać metodę dostawy']));
    yield call(window.scrollTo, 0, 0);
    return;
  }
  if (deliveryMethod.type === DeliveryMethodType.parcel && !billingAddress) {
    yield put(submitOrderErrorAction(['Proszę uzupełnić adres dostawy']));
    yield call(window.scrollTo, 0, 0);
    return;
  }

  if (deliveryMethod.type === DeliveryMethodType.parcelLocker && !parcelLockerAddress) {
    yield put(submitOrderErrorAction(['Proszę uzupełnić adres paczkomatu']));
    yield call(window.scrollTo, 0, 0);
    return;
  }

  if (addInvoiceToOrder) {
    const invoiceData: Maybe<InvoiceData> = yield select(selectInvoiceData);
    if (!invoiceData) {
      yield put(
        submitOrderErrorAction([
          'Proszę uzupełnić dane do faktury, bądź odznaczyć opcje dodania faktury',
        ])
      );
      yield call(window.scrollTo, 0, 0);
      return;
    }
  }

  if (addNotesToOrder) {
    const notesData: Maybe<NotesData> = yield select(selectOrderNotes);
    if (!notesData) {
      yield put(
        submitOrderErrorAction([
          'Proszę dodać notatki do zamówienia, bądź odznaczyć opcję dodania notatek',
        ])
      );
      yield call(window.scrollTo, 0, 0);
      return;
    }
  }

  const orderValue = calculateOrderValue(products, commission, deliveryMethod.price);

  try {
    const res: PaymentFinalizeResponse = yield call(submitOrder, orderState.orderId!, orderValue);
    if (res.paymentRedirectUrl) {
      window.location.replace(res.paymentRedirectUrl);
    }
    yield put(submitOrderSuccessAction());
  } catch (e) {
    if (e instanceof OrderValidationError) {
      yield put(submitOrderErrorAction(e.errors.map((error) => error.message)));
      window.scrollTo(0, 0);
    }
  }
}

export function* cancelOrderSaga(action: Action) {
  if (cancelOrderAction.match(action)) {
    yield call(cancelOrder, action.payload.orderId);
    yield put(cancelOrderSuccessAction({ orderId: action.payload.orderId }));
  }
}

export function* getOrderStatusSaga(action: Action) {
  if (getOrderStatusAction.match(action)) {
    yield race({
      task: call(orderStatusPollingWorker, action.payload.orderId),
      cancel: take(CANCEL_STATUS_POLLING),
    });
  }
}

const CANCEL_STATUS_POLLING = 'CANCEL_STATUS_POLLING';

export function* orderStatusPollingWorker(orderId: Order['id']) {
  const timeout = +getEnvs().POLLING_TIMEOUT;
  const pollingStartTime = Date.now();
  while (true) {
    if (Date.now() - pollingStartTime > timeout) {
      yield put(paymentPendingAction());
      yield put({ type: CANCEL_STATUS_POLLING });
      return;
    }

    const orderResponse: { status: OrderStatus } = yield call(getOrderStatus, orderId);
    const orderStatus = orderResponse.status;

    switch (orderStatus) {
      case OrderStatus.PAYMENT_SUCCESS: {
        yield put(push(`/order-summary/${orderId}`));
        yield put(getOrderStatusSuccessAction(orderStatus));
        yield delay(+getEnvs().POLLING_DELAY);
        yield put({ type: CANCEL_STATUS_POLLING });
        break;
      }
      case OrderStatus.PAYMENT_ERROR: {
        yield put(getOrderStatusSuccessAction(orderStatus));
        yield put({ type: CANCEL_STATUS_POLLING });
        break;
      }
      case OrderStatus.CREATED: {
        yield put(push(`/order/${orderId}`));
        yield put({ type: CANCEL_STATUS_POLLING });
        break;
      }
      default: {
        yield delay(+getEnvs().POLLING_DELAY);
      }
    }
  }
}

export function* changeOrderStatusSaga(action: Action) {
  if (renewOrderStatusAction.match(action)) {
    const orderId: Order['id'] = yield select(selectOrderId);
    yield call(renewOrderStatus, orderId);
    yield put(renewOrderStatusSuccessAction());
    yield put(push(`order/${orderId}`));
  }
}

export function* changeOrderProductQuantitySaga(action: Action) {
  if (changeProductAmountAction.match(action)) {
    const orderId: Order['id'] = yield select(selectOrderId);
    const { productId, amount } = action.payload;
    yield call(changeProductQuantity, orderId, productId, amount);
    yield put(changeProductAmountSuccessAction(productId, amount));
    yield put(getDeliveryMethodsAction());
  }
}

export function* deleteOrderProductSaga(action: Action) {
  if (deleteProductAction.match(action)) {
    const orderId: Order['id'] = yield select(selectOrderId);
    const { productId } = action.payload;
    yield call(deleteProduct, orderId, productId);
    yield put(deleteProductSuccessAction(productId));
  }
}

export function* defaultAddressSaga() {
  const defaultAddresses: Maybe<DefaultAddresses> = yield select(selectDefaultAddresses);
  const orderAddress: DeliveryAddress = yield select(selectBillingAddress);
  if (defaultAddresses && defaultAddresses[DeliveryMethodType.parcel] && !orderAddress) {
    yield put(addOrderBillingAddressAction(defaultAddresses[DeliveryMethodType.parcel]));
  }
}

export function* orderSaga() {
  yield takeLatest(getOrderAction.type, getOrderSaga);
  yield takeLatest(addOrderDeliveryMethodAction.type, addOrderDeliveryMethodSaga);
  yield takeLatest(addOrderPaymentMethodAction.type, addOrderPaymentMethodSaga);
  yield takeLatest(addOrderInvoiceDataAction.type, addOrderInvoiceDataSaga);
  yield takeLatest(deleteOrderInvoiceDataAction.type, deleteOrderInvoiceDataSaga);
  yield takeLatest(addOrderBillingAddressAction.type, addOrderBillingAddressSaga);
  yield takeLatest(addOrderDeliveryAddressAction.type, addOrderDeliveryAddressSaga);
  yield takeLatest(addOrderDeliveryAddressByIdAction.type, addOrderDeliveryAddressIdSaga);
  yield takeLatest(addOrderNotesAction.type, addOrderNotesSaga);
  yield takeLatest(submitOrderAction.type, submitOrderSaga);
  yield takeLatest(deleteOrderNotesAction.type, deleteOrderNotesSaga);
  yield takeLatest(getOrderStatusAction.type, getOrderStatusSaga);
  yield takeLatest(renewOrderStatusAction.type, changeOrderStatusSaga);
  yield takeLatest(changeProductAmountAction.type, changeOrderProductQuantitySaga);
  yield takeLatest(deleteProductAction.type, deleteOrderProductSaga);
  yield takeLatest(setAddDeliveryAddressToOrderAction.type, addOrderDeliveryAddressSaga);
  yield takeLatest(cancelOrderAction.type, cancelOrderSaga);
  yield takeLatest(addOrderPaymentMethodSuccessAction.type, defaultAddressSaga);
}

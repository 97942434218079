import React, { FC } from 'react';
import styled from '@emotion/styled';
import { DeliveryAddressForm } from './DeliveryAddressForm';
import { DeliveryAddress } from '../../../models';

const Title = styled.h2`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: 600;
`;

export interface AddUserDeliveryAddressSection {
  handleDeliveryAddressSubmit: (address: DeliveryAddress) => void;
  handleClose: () => void;
  initialAddressValues?: DeliveryAddress;
}

export const AddUserDeliveryAddress: FC<AddUserDeliveryAddressSection> = ({
  handleDeliveryAddressSubmit,
  handleClose,
  initialAddressValues,
}) => (
  <>
    <Title>Edytuj adres dostawy</Title>
    <DeliveryAddressForm
      handleSubmit={handleDeliveryAddressSubmit}
      inPopup
      handleClose={handleClose}
      initialAddressValues={initialAddressValues}
    />
  </>
);

import styled from '@emotion/styled';
import { FontSize } from '../../../../styles/models';

export const WithError = styled.div<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.error : 'inherit')};
`;

export const P = styled(WithError)<{ size?: FontSize }>`
  ${({ theme, size }) => size && `font-size: ${theme.font[size]}`};
`.withComponent('p');

import { Show } from '../atoms/Visibility';
import { InputValidationTooltip } from '../atoms/form/InputValidationTooltip';
import React, { FC } from 'react';
import { ReactComponent as Remark } from 'assets/remark.svg';
import { Flexbox } from '../atoms/FlexContainer';
import { ReactComponent as RemarkFull } from 'assets/remark-full.svg';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const SellerName = styled(Flexbox)`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.fontLight};
`;
const SellerNameLink = styled.a`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.linkColor};
  text-decoration: none;
`;
const SellerTooltip = styled(Flexbox)`
  height: 15px;
`;
const TooltipWithIcon = styled(Flexbox)`
  gap: 10px;
`;
const TooltipTextWithIcon = styled.div`
  margin-left: 25px;
`;
const SellerType = styled.div`
  margin-left: 12px;
  padding-left: 12px;
  border-left: solid 1px ${({ theme }) => theme.colors.fontLight};
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.fontMain};
  text-decoration: none;
`;

export interface SellerTitleProps {
  sellerName: string;
  urlMoreOffer: string;
  tooltip?: {
    title: string;
    text: string;
  };
}

export const SellerTitle: FC<SellerTitleProps> = ({ urlMoreOffer, tooltip, sellerName }) => {
  const theme = useTheme();
  return (
    <SellerName alignItems="center">
      <div>Sprzedawca:</div>
      <SellerNameLink href={urlMoreOffer}>{sellerName}</SellerNameLink>
      {tooltip && (
        <Show from={theme.breakpoint.tablet}>
          <SellerTooltip alignItems="center">
            <SellerType>{tooltip.title}</SellerType>
            <InputValidationTooltip
              errorMsg={
                <div>
                  <TooltipWithIcon>
                    <RemarkFull />
                    <strong>{tooltip.title}</strong>
                  </TooltipWithIcon>
                  <TooltipTextWithIcon>{tooltip.text}</TooltipTextWithIcon>
                </div>
              }
              render={() => (
                <Remark
                  height={17}
                  viewBox="0 0 26 26"
                  style={{ marginLeft: 1, cursor: 'pointer' }}
                />
              )}
              onHover
              textAlign="left"
            />
          </SellerTooltip>
        </Show>
      )}
    </SellerName>
  );
};

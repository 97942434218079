import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IconWrapper } from 'utils/IconWrapper';
import { ExpandCollapse } from 'components/UI/molecules/ExpandCollapse';
import { ReactComponent as Remark } from 'assets/remark.svg';
import { SectionHeadingWithToggle } from '../molecules/SectionHeadingWithToggle';
import { NotesForm } from './NotesForm';
import { NotesData } from '../../../models/data/NotesData';
import { FormState } from '../../../store/reducers/forms/formsReducer';
import { usePrevious } from '../../../utils/usePrevious';

const remarkSectionText = 'Uwagi do zamówienia';

const CollapsedTitleWrapper = styled.div`
  width: 100%;
`;

const NotesSummary = styled.p`
  margin-top: 70px;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-top: 25px;
  }
`;

export interface NotesSectionProps {
  notes?: string;
  handleRemoveNotesFromOrder: () => void;
  handleAddNotesToOrder: (remarks: string) => void;
  addNotesToOrder: boolean;
  handleSetAddNotesToOrder: (addNotesToOrder: boolean) => void;
  formState: FormState<NotesData>;
}

export const NotesSection: FC<NotesSectionProps> = ({
  notes,
  handleRemoveNotesFromOrder,
  handleAddNotesToOrder,
  addNotesToOrder,
  handleSetAddNotesToOrder,
  formState,
}) => {
  const [show, setShowForm] = useState(false);
  const prevAddNotesToOrder = usePrevious(addNotesToOrder);
  const handleToggle = () => {
    handleSetAddNotesToOrder(!addNotesToOrder);
  };

  const handleFormSubmission = (data: string) => {
    handleAddNotesToOrder(data);
    setShowForm(false);
  };

  useEffect(() => {
    if (!addNotesToOrder) {
      if (notes) {
        handleRemoveNotesFromOrder();
      }
      setShowForm(false);
    } else if (prevAddNotesToOrder !== undefined) {
      setShowForm(true);
    }
  }, [addNotesToOrder]);

  return (
    <ExpandCollapse
      expand={show}
      hideEditButton={!addNotesToOrder}
      hasToggle
      collapsedTitle={
        <CollapsedTitleWrapper>
          <SectionHeadingWithToggle
            text={remarkSectionText}
            handleToggle={handleToggle}
            toggleOn={addNotesToOrder}
            icon={<IconWrapper Icon={Remark} />}
          />
          {notes && addNotesToOrder && <NotesSummary>{notes}</NotesSummary>}
        </CollapsedTitleWrapper>
      }
      expandedTitle={
        <SectionHeadingWithToggle
          text={remarkSectionText}
          handleToggle={handleToggle}
          toggleOn={addNotesToOrder}
          icon={<IconWrapper Icon={Remark} />}
        />
      }
      handleExpand={() => setShowForm(true)}
    >
      <NotesForm
        handleFormSubmission={handleFormSubmission}
        handleClose={() => setShowForm(false)}
        formErrors={formState.errors}
        notes={notes}
      />
    </ExpandCollapse>
  );
};

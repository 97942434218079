import React, { FC, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Popup } from '../atoms/Popup';
import { SavedDeliveryAddressCard } from './SavedDeliveryAddressCard';
import { DeliveryAddress, UserAddress } from '../../../models';
import { Card } from '../atoms/Card';
import { ReactComponent as Plus } from 'assets/add-plus.svg';
import { ButtonClear } from '../atoms/buttons/ButtonClear';
import { AddUserDeliveryAddress } from './AddUserDeliveryAddress';
import { isMatch } from 'lodash';

const CardWrapper = styled.div`
  margin-top: 15px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 24px;

    &:nth-of-type(n + 3) {
      margin-top: 30px;
    }
  }
`;

const AddButton = styled(ButtonClear)`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddNewCard = styled(Card)`
  width: 100% !important;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 242px;
`;

const AddNewText = styled.p`
  color: ${({ theme }) => theme.colors.darkBlue2};
  font-size: ${({ theme }) => theme.font.m};
  margin-top: 10px;
`;

const AddressesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export interface SavedDeliveryAddressesProps {
  addresses: UserAddress[];
  savedAddress?: DeliveryAddress;
  show: boolean;
  handleAddressSelection: (addressId: UserAddress['id']) => void;
  handleAddressDeletion: (addressId: UserAddress['id']) => void;
  handleAddressEdition: (address: UserAddress) => void;
  handleAddressAddition: (address: DeliveryAddress) => void;
  handlePopupClose: () => void;
}

export const SavedDeliveryAddresses: FC<SavedDeliveryAddressesProps> = ({
  addresses,
  handleAddressSelection,
  handleAddressEdition,
  handleAddressDeletion,
  handleAddressAddition,
  savedAddress,
}) => {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressId, setAddressId] = useState<UserAddress['id'] | undefined>(undefined);

  const isSelectedAddress = (address: UserAddress): boolean =>
    !!savedAddress && isMatch(address, savedAddress);

  const handleEditAddressClick = (id: UserAddress['id']) => {
    setAddressId(id);
    setShowAddressForm(true);
  };

  const handleAddAddressClick = () => {
    setAddressId(undefined);
    setShowAddressForm(true);
  };

  const handleAddressChange = useCallback(
    (address: DeliveryAddress) => {
      addressId
        ? handleAddressEdition({ ...address, id: addressId, default: true })
        : handleAddressAddition(address);
      setShowAddressForm(false);
    },
    [addressId]
  );

  return (
    <>
      <Popup isOpen={showAddressForm} wide>
        <AddUserDeliveryAddress
          handleDeliveryAddressSubmit={handleAddressChange}
          handleClose={() => setShowAddressForm(false)}
          initialAddressValues={addresses.find((address) => address.id === addressId)}
        />
      </Popup>
      <AddressesGrid>
        {addresses.map((address) => (
          <SavedDeliveryAddressCard
            key={address.id}
            handleAddressSelection={() => handleAddressSelection(address.id)}
            handleAddressDeletion={() => handleAddressDeletion(address.id)}
            handleAddressEdition={() => handleEditAddressClick(address.id)}
            address={address}
            selected={isSelectedAddress(address)}
          />
        ))}
        <AddNewCard onClick={handleAddAddressClick}>
          <AddButton type="button">
            <Plus height={40} />
          </AddButton>
          <AddNewText>Dodaj nowy adres</AddNewText>
        </AddNewCard>
      </AddressesGrid>
    </>
  );
};

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Minus } from 'assets/minus.svg';
import { ReactComponent as PlusSmall } from 'assets/plus-small.svg';
import theme from 'styles/theme';
import { Flexbox } from '../FlexContainer';

const NumberButtonsContainer = styled(Flexbox)`
  font-family: Arial, serif;
`;
const ButtonWrp = styled.div<{ disabled: boolean; minus?: boolean }>`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 36px;
  border-radius: ${({ minus }) => (minus ? '5px 0 0 5px' : '0 5px 5px 0')};
  padding-left: 15px;
  padding-top: ${({ minus }) => (minus ? '3px' : '6px')};
  border: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.sectionBorderColor : theme.colors.darkGrey1};
  cursor: pointer;
`;

export const Input = styled.input`
  position: relative;
  width: 40px;
  height: 36px;
  box-sizing: border-box;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  border-left: none;
  border-bottom: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  border-right: none;
  font-size: ${({ theme }) => theme.font.s};
  text-align: center;
  outline: none;
`;

export interface NumberButtonsProps {
  handleChange: (num: number) => void;
  value: number;
  minValue: number;
  maxValue: number;
}
export const NumberButtons: FC<NumberButtonsProps> = ({
  handleChange,
  value,
  minValue,
  maxValue,
}) => {
  const disabledMinus = value <= minValue;
  const disabledPlus = value >= maxValue;
  return (
    <NumberButtonsContainer>
      <ButtonWrp
        {...(!disabledMinus && { onClick: () => handleChange(Number(value - 1)) })}
        minus
        disabled={disabledMinus}
      >
        <Minus fill={disabledMinus ? theme.colors.sectionBorderColor : theme.colors.fontMain} />
      </ButtonWrp>
      <Input onChange={(e) => handleChange(Number(e.target.value))} value={value} />
      <ButtonWrp
        {...(!disabledPlus && { onClick: () => handleChange(Number(value + 1)) })}
        disabled={disabledPlus}
      >
        <PlusSmall fill={disabledPlus ? theme.colors.sectionBorderColor : theme.colors.fontMain} />
      </ButtonWrp>
    </NumberButtonsContainer>
  );
};

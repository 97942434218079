import axios from 'axios';
import { getEnvs } from 'utils/env';
import { PaymentMethod } from '../../models/data/PaymentMethod';
import { PaymentMethodSubCategory } from '../../models/data/PaymentMethodSubCategory';

const PAYMENT_METHODS_URL = 'payment/payment-methods';
const PAYMENT_METHOD_URL = 'order/$orderId/payment-method';

export const addOrderPaymentMethod = (
  orderId: number,
  paymentMethodId: PaymentMethod['id'],
  subCategoryId?: PaymentMethodSubCategory['id']
) =>
  axios.put(
    `${getEnvs().ORDER_BASE_URL}/${PAYMENT_METHOD_URL.replace(
      '$orderId',
      orderId.toString()
    )}/${paymentMethodId}/${subCategoryId}`
  );

export async function getPaymentMethods(orderId: number): Promise<PaymentMethod[]> {
  const response = await axios.get<PaymentMethod[]>(
    `${getEnvs().PAYMENT_BASE_URL}/${PAYMENT_METHODS_URL.replace('$orderId', orderId.toString())}`
  );
  return response.data;
}

import { put } from '../api';
import { getEnvs } from '../../utils/env';
import axios from 'axios';

const PRODUCT_URL = '/order/$orderId/cart/$productId';

export const changeProductQuantity = async (
  orderId: string,
  productId: string,
  quantity: number
): Promise<void> => {
  await put<{}, void>(
    `${getEnvs().ORDER_BASE_URL}${PRODUCT_URL.replace('$orderId', orderId).replace(
      '$productId',
      productId
    )}`,
    {
      amount: quantity,
    }
  );
};

export const deleteProduct = async (orderId: string, productId: string): Promise<void> => {
  await axios.delete(
    `${getEnvs().ORDER_BASE_URL}${PRODUCT_URL.replace('$orderId', orderId).replace(
      '$productId',
      productId
    )}`
  );
};

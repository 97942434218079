import React, { FC, useEffect, useState, useTransition } from 'react';
import styled from '@emotion/styled';
import { IconWrapper } from 'utils/IconWrapper';
import { ExpandCollapse } from 'components/UI/molecules/ExpandCollapse';
import { ReactComponent as Receipt } from 'assets/receipt.svg';
import { InvoiceForm } from './InvoiceForm';
import { DeliveryAddress, InvoiceData } from '../../../models';
import { SectionHeadingWithToggle } from '../molecules/SectionHeadingWithToggle';
import { Maybe } from '../../../utils/maybe';
import { FormState } from '../../../store/reducers/forms/formsReducer';
import { usePrevious } from '../../../utils/usePrevious';
import { FormStatus } from '../../../models/data/Form';
import { InvoiceSummary } from '../molecules/InvoiceSummary';

const invoiceSectionText = 'Faktura VAT';

const CollapsedTitleWrapper = styled.div`
  width: 100%;
`;

export interface InvoiceSectionProps {
  invoiceData?: InvoiceData;
  handleInvoiceDataAddition: (invoiceData: InvoiceData) => void;
  clearInvoiceData: () => void;
  checkoutInitiated?: boolean;
  deliveryAddress?: DeliveryAddress;
  addInvoiceToOrder: boolean;
  handleSetAddInvoiceToOrder: (addInvoiceToOrder: boolean) => void;
  invoiceFormState: FormState<InvoiceData>;
}

export const InvoiceSection: FC<InvoiceSectionProps> = ({
  invoiceData,
  handleInvoiceDataAddition,
  clearInvoiceData,
  checkoutInitiated,
  deliveryAddress,
  invoiceFormState,
  addInvoiceToOrder,
  handleSetAddInvoiceToOrder,
}) => {
  const [show, setShowForm] = useState(false);
  const [address, setAddress] = useState<Maybe<DeliveryAddress>>(undefined);
  const [invoicePrefill, setInvoicePrefill] = useState<Maybe<InvoiceData>>(undefined);
  const prevAddInvoiceToOrder = usePrevious(addInvoiceToOrder);

  useEffect(() => {
    if (invoiceData) {
      setInvoicePrefill(invoiceData);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (!addInvoiceToOrder) {
      if (invoiceData) {
        clearInvoiceData();
      }
      setShowForm(false);
    } else if (prevAddInvoiceToOrder !== undefined) {
      setShowForm(true);
    }
  }, [addInvoiceToOrder]);

  useEffect(() => {
    if (invoiceFormState?.status === FormStatus.submitted) {
      setShowForm(false);
    }
  }, [invoiceFormState]);

  const handleToggle = () => {
    handleSetAddInvoiceToOrder(!addInvoiceToOrder);
  };

  const handleFormClose = () => {
    setShowForm(false);
    if (!invoiceData) {
      handleSetAddInvoiceToOrder(false);
    }
  };

  return (
    <ExpandCollapse
      expand={show}
      hideEditButton={!addInvoiceToOrder}
      hasToggle
      collapsedTitle={
        <CollapsedTitleWrapper>
          <SectionHeadingWithToggle
            text={invoiceSectionText}
            handleToggle={handleToggle}
            toggleOn={addInvoiceToOrder}
            icon={<IconWrapper Icon={Receipt} />}
          />
          {invoiceData && addInvoiceToOrder && <InvoiceSummary invoiceData={invoiceData} />}
        </CollapsedTitleWrapper>
      }
      expandedTitle={
        <SectionHeadingWithToggle
          text={invoiceSectionText}
          handleToggle={handleToggle}
          toggleOn={addInvoiceToOrder}
          icon={<IconWrapper Icon={Receipt} />}
        />
      }
      handleExpand={() => setShowForm(true)}
    >
      <InvoiceForm
        handleFormSubmission={handleInvoiceDataAddition}
        handleClose={handleFormClose}
        formErrors={invoiceFormState.errors}
        checkoutInitiated={checkoutInitiated}
        initialInvoiceDataValues={invoicePrefill}
        addressToCopyFrom={address}
      />
    </ExpandCollapse>
  );
};

import styled from '@emotion/styled';

export interface MediaProps {
  from: string;
  to?: string;
  display?: string;
}

export const Hide = styled.div<MediaProps>`
  ${({ from, to }) =>
    from &&
    to &&
    `@media (min-width: ${from}) and (max-width: ${to}){
                        display: none;
                    }`}
  ${({ from, to }) =>
    from &&
    !to &&
    `@media (min-width: ${from}){
                        display: none;
                    }`}
`;

export const Show = styled.div<MediaProps>`
  display: none;
  ${({ from, to, display = 'block' }) =>
    from &&
    to &&
    `@media (min-width: ${from}) and (max-width: ${to}){
                        display: ${display};
                    }`}
  ${({ from, to, display }) =>
    from &&
    !to &&
    `@media (min-width: ${from}){
                        display: ${display};
                    }`}
`;

import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { ReactComponent as Close } from '../../../assets/close-large.svg';
import { FCWithChildren } from '../../../utils/types/FC';

export const PopupContainer = styled.div<{ wide: boolean; width?: number }>`
  position: fixed;
  max-height: 100vh;
  padding: 20px;
  z-index: 10;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-height: 90%;
    padding: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 5%;
    width: ${({ wide, width }) => (wide ? 800 : width || 620)}px;
    min-height: 433px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: ${({ wide, width }) => (wide ? 978 : width || 620)}px;
  }
`;

export const Shadow = styled.div`
  display: block;
  position: fixed;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.2;
  z-index: 9;
`;

export const XButton = styled(Close)`
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    right: 40px;
    top: 40px;
  }
`;

export interface PopupProps {
  isOpen: boolean;
  handleClose?: () => void;
  wide?: boolean;
  hideWithHidden?: boolean;
  width?: number;
}

export const Popup: FCWithChildren<PopupProps> = ({
  children,
  isOpen,
  handleClose,
  wide,
  hideWithHidden,
  width,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isOpen]);

  const popupElement = document.getElementById('popup');
  if ((!isOpen || !popupElement) && !hideWithHidden) return null;

  return ReactDOM.createPortal(
    <>
      <Shadow />
      <PopupContainer wide={!!wide} width={width}>
        <div>
          {handleClose && <XButton onClick={handleClose} />}
          {children}
        </div>
      </PopupContainer>
    </>,
    popupElement!
  );
};

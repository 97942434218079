import { createReducer } from '@reduxjs/toolkit';
import {
  DeliveryAddress,
  DeliveryMethod,
  InvoiceData,
  OrderStatus,
  ParcelLockerAddress,
  UserAddress,
} from 'models/data';

import {
  addOrderBillingAddressSuccessAction,
  addOrderDeliveryAddressSuccessAction,
  addOrderDeliveryMethodSuccessAction,
  addOrderInvoiceDataSuccessAction,
  addOrderNotesSuccessAction,
  addOrderParcelLockerBillingAddressSuccessAction,
  addOrderPaymentMethodSuccessAction,
  changeProductAmountSuccessAction,
  deleteOrderInvoiceDataSuccessAction,
  deleteProductSuccessAction,
  getOrderStatusSuccessAction,
  getOrderSuccessAction,
  renewOrderStatusSuccessAction,
  setAddDeliveryAddressToOrderAction,
  setAddInvoiceToOrderAction,
  setAddNotesToOrderAction,
  submitOrderErrorAction,
  submitOrderSuccessAction,
} from '../../actions/OrderActions';
import { Product } from '../../../models/data/Product';
import { PaymentMethod } from '../../../models/data/PaymentMethod';
import { PaymentMethodSubCategory } from '../../../models/data/PaymentMethodSubCategory';
import { Order, Seller, User } from '../../../models/data/Order';

export interface OrderState {
  orderId: Order['id'];
  deliveryMethod?: { deliveryMethodId: DeliveryMethod['id']; parcelLockerCode?: string };
  paymentMethod: {
    id?: PaymentMethod['id'];
    subPaymentId?: PaymentMethodSubCategory['id'];
  };
  billingAddress?: DeliveryAddress;
  deliveryAddress?: DeliveryAddress | UserAddress;
  parcelLockerBillingAddress?: ParcelLockerAddress;
  parcelLockerAddress?: ParcelLockerAddress;
  invoiceData?: InvoiceData;
  errors: string[];
  status?: OrderStatus;
  products: Product[];
  notes?: string;
  addDeliveryAddressToOrder: boolean;
  addInvoiceToOrder: boolean;
  addNotesToOrder: boolean;
  buyer: User;
  seller: Seller;
  commission: number;
}

const initialState: OrderState = {
  orderId: '',
  buyer: {
    sprzedajemyUserId: 0,
    email: '',
  },
  seller: {
    sprzedajemyUserId: 0,
    email: '',
    name: '',
    surname: '',
    type: '',
  },
  addInvoiceToOrder: false,
  addNotesToOrder: false,
  addDeliveryAddressToOrder: false,
  deliveryMethod: undefined,
  paymentMethod: {},
  billingAddress: undefined,
  deliveryAddress: undefined,
  invoiceData: undefined,
  products: [],
  status: undefined,
  notes: undefined,
  errors: [],
  commission: 0,
};

export const orderReducer = createReducer<OrderState>(initialState, (builder) => {
  builder
    .addCase(addOrderDeliveryMethodSuccessAction, (state, action) => {
      state.deliveryMethod = {
        deliveryMethodId: action.payload.deliveryMethod.id,
        parcelLockerCode: action.payload.deliveryMethod.parcelLockerCode,
      };
      state.deliveryAddress = undefined;
      state.parcelLockerAddress = undefined;
    })
    .addCase(addOrderInvoiceDataSuccessAction, (state, action) => {
      state.invoiceData = action.payload.invoice;
    })
    .addCase(addOrderDeliveryAddressSuccessAction, (state, action) => {
      state.deliveryAddress = action.payload.address;
    })
    .addCase(addOrderBillingAddressSuccessAction, (state, action) => {
      state.billingAddress = action.payload.address;
    })
    .addCase(addOrderParcelLockerBillingAddressSuccessAction, (state, action) => {
      state.parcelLockerBillingAddress = action.payload.address;
    })
    .addCase(getOrderSuccessAction, (state, action) => {
      const { order } = action.payload;
      state.invoiceData = order.invoiceData;
      state.deliveryMethod = order.deliveryMethod && {
        deliveryMethodId: order.deliveryMethod.id,
        parcelLockerCode: order.deliveryMethod?.parcelLockerCode,
      };
      console.log(order.deliveryMethod);
      state.products = order.products;
      state.status = order.status;
      state.orderId = order.id;
      state.buyer = order.buyer;
      state.notes = order.notes;
      state.addDeliveryAddressToOrder = !!order.deliveryAddress;
      state.addInvoiceToOrder = !!order.invoiceData;
      state.addNotesToOrder = !!order.notes;
      state.paymentMethod = order.paymentMethod;
      state.commission = order.commission;
      state.seller = order.seller;

      if (order.deliveryAddress && 'parcelLockerCode' in order.deliveryAddress) {
        state.parcelLockerAddress = order.deliveryAddress;
      } else {
        state.deliveryAddress = order.deliveryAddress;
      }

      if (order.billingAddress && 'parcelLockerCode' in order?.billingAddress) {
        state.parcelLockerBillingAddress = order.billingAddress;
      } else {
        state.billingAddress = order.billingAddress;
      }
    })
    .addCase(addOrderPaymentMethodSuccessAction, (state, action) => {
      state.paymentMethod = {
        id: action.payload.paymentMethodId,
        subPaymentId: action.payload.subPaymentId,
      };
    })
    .addCase(addOrderNotesSuccessAction, (state, action) => {
      state.notes = action.payload.notes;
    })
    .addCase(deleteOrderInvoiceDataSuccessAction, (state, action) => {
      state.invoiceData = undefined;
    })
    .addCase(submitOrderErrorAction, (state, action) => {
      state.errors = action.payload.errors;
    })
    .addCase(setAddInvoiceToOrderAction, (state, action) => {
      state.addInvoiceToOrder = action.payload.addInvoiceToOrder;
    })
    .addCase(setAddDeliveryAddressToOrderAction, (state, action) => {
      state.addDeliveryAddressToOrder = action.payload.addDeliveryAddressToOrder;
    })
    .addCase(setAddNotesToOrderAction, (state, action) => {
      state.addNotesToOrder = action.payload.addNotesToOrder;
    })
    .addCase(getOrderStatusSuccessAction, (state, action) => {
      state.status = action.payload.status;
    })
    .addCase(renewOrderStatusSuccessAction, (state, action) => {
      state.status = OrderStatus.CREATED;
    })
    .addCase(submitOrderSuccessAction, (state) => {
      state.status = OrderStatus.FINALIZED;
    })
    .addCase(changeProductAmountSuccessAction, (state, action) => {
      const { productId, amount } = action.payload;
      const product = state.products.find((p) => p.offerId === productId);
      if (product) {
        product.amount = amount;
      }
    })
    .addCase(deleteProductSuccessAction, (state, action) => {
      const { productId } = action.payload;
      state.products = state.products.filter((p) => p.offerId !== productId);
    });
});

import { applyMiddleware, createStore, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { rootSaga } from './sagas/rootSaga';
import { createRootReducer } from './reducers/rootReducer';

export const history = createBrowserHistory();

const composeEnhancers =
  // eslint-disable-next-line
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createAppStore = <T extends object>(sagaContext: T): Store => {
  const sagaMiddleware = createSagaMiddleware({ context: sagaContext });
  const routeMiddleware = routerMiddleware(history);

  const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(sagaMiddleware, routeMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

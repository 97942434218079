import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Warning } from '../../../assets/warning.svg';
import { Strong } from '../atoms/Text';
import { Button as StyledButton } from '../atoms/buttons/Button';

const H1 = styled.h1`
  font-size: 30px;
  margin: 20px 0 29px 0;
  font-weight: bold;
  text-align: center;
`;

const Button = styled(StyledButton)`
  width: 250px;
`;

export const ErrorBanner: FC<{ onClick: () => void; message: string }> = ({ onClick, message }) => (
  <>
    <Warning />
    <H1>{message}</H1>
    <Button size="big">
      <Strong onClick={onClick}>Ponów</Strong>
    </Button>
  </>
);

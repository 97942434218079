import React, { FC, useEffect, useState } from 'react';
import { selectCartState } from '../../../store/selectors/cartSelector';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSections } from '../organisms/MultiSections';
import { Cart } from '../../../models/data/Cart';
import { cancelOrderAction, getCartAction } from '../../../store/slices/cartSlice';
import { Flexbox } from '../atoms/FlexContainer';
import { Chip } from '../atoms/Chip';
import styled from '@emotion/styled';
import { Grid, Row } from '../atoms/Grid';
import { CartSummary } from '../organisms/CartSummary';
import { push } from 'connected-react-router';
import { Hide, Show } from '../atoms/Visibility';
import { useTheme } from '@emotion/react';
import { Button, ButtonVariant } from '../atoms/buttons/Button';
import { printProductsNumber } from '../../../utils/printProductsNumber';

const H1 = styled.h1`
  font-size: 32px;
  font-weight: 700;
  padding-right: 15px;
`;

const HeadingWrapper = styled(Flexbox)`
  width: 100%;
  margin: 28px 15px 28px 0;
`;

const CartWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 65px;
  }
`;

export const MobileButtonContainer = styled(Hide)`
  position: fixed;
  bottom: 30px;
  width: 100%;
  left: 0;
`;

const BuyNowButton = styled(Button)`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: bold;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-top: 5px;
    width: 327px;
  }
`;

export const CartPage: FC = () => {
  const dispatch = useDispatch();
  const cartData = useSelector(selectCartState);
  const [activeCart, setActiveCart] = useState<string>('');
  const theme = useTheme();

  useEffect(() => {
    dispatch(getCartAction());
  }, []);

  if (!cartData) return null;

  const commisionPercentage = cartData?.[0]?.commission;

  const mapCartData: Cart[] = cartData.map((cart) => ({
    orderId: cart.id,
    urlMoreOffer: '',
    sellerName: '',
    products: cart.products,
  }));

  const numberOfProducts = cartData.reduce((acc, cart) => acc + cart.products.length, 0);

  const onChooseCart = () => {
    dispatch(push(`/order/${activeCart}`));
  };

  const handleDeleteCart = (orderId: string) => {
    dispatch(cancelOrderAction({ orderId }));
  };

  return (
    <Grid>
      <Row
        mobile={{ col: { from: 1, to: 5 } }}
        tablet={{ col: { from: 1, to: 9 } }}
        laptop={{ col: { from: 1, to: 9 } }}
      >
        <Flexbox>
          <HeadingWrapper justifyContent="flex-start" alignItems="center">
            <H1>Twoje koszyki</H1>
            <Chip>{printProductsNumber(numberOfProducts)}</Chip>
          </HeadingWrapper>
        </Flexbox>
      </Row>
      <Row
        mobile={{ col: { from: 1, to: 5 } }}
        tablet={{ col: { from: 1, to: 9 } }}
        laptop={{ col: { from: 1, to: 9 } }}
      >
        <MultiSections
          handleDeleteProduct={() => {}}
          handleSetAmount={() => {}}
          handleDeleteCart={handleDeleteCart}
          handleChooseCart={setActiveCart}
          commissionPercentage={commisionPercentage}
          activeCard={activeCart!}
          cards={mapCartData}
        />
      </Row>
      <MobileButtonContainer from={theme.breakpoint.laptop}>
        <Grid>
          <Row mobile={{ col: { from: 1, to: 5 } }} tablet={{ col: { from: 1, to: 9 } }}>
            <BuyNowButton
              onClick={onChooseCart}
              variant={ButtonVariant.primary}
              size="big"
              disabled={activeCart === ''}
            >
              PRZEJDŹ DO KOSZYKA
            </BuyNowButton>
          </Row>
        </Grid>
      </MobileButtonContainer>
      <Row mobile={{ col: { from: 1, to: 5 } }} laptop={{ col: { from: 9, to: 13 } }}>
        <Show from={theme.breakpoint.laptop}>
          <CartWrapper>
            <CartSummary disabled={activeCart === ''} handleClick={onChooseCart} />
          </CartWrapper>
        </Show>
      </Row>
    </Grid>
  );
};

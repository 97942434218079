import React, { FC } from 'react';
import styled from '@emotion/styled';

const InputRadioContainer = styled.div`
  font-family: Arial, serif;
  height: 18px;
`;
const Radio = styled.div<{ checked?: boolean }>`
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, checked }) => (checked ? theme.colors.darkGrey1 : theme.colors.sectionBorderColor)};
  box-sizing: border-box;
  background: white;
  cursor: pointer;
  ${({ theme, checked }) =>
    checked &&
    `&::after { content: ''; position: absolute; top: 50%; left: 50%; width: 8px; height: 8px; border-radius: 50%; background: ${theme.colors.darkGrey1}; 
            transform: translate(-50%, -50%); }`}
`;

export interface InputRadioProps {
  handleChecked: (checked: boolean) => void;
  checked: boolean;
}
export const InputRadio: FC<InputRadioProps> = ({ handleChecked, checked }) => {
  return (
    <InputRadioContainer>
      <Radio onClick={() => handleChecked(!checked)} checked={checked}></Radio>
    </InputRadioContainer>
  );
};

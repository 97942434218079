export function isNipValid(nip: string): boolean {
  const nipWithoutDashes = nip.replace(/-/gu, ``);
  const reg = /^[0-9]{10}$/u;
  if (!reg.test(nipWithoutDashes)) {
    return false;
  }

  const dig = String(nipWithoutDashes).split(``);
  const controlValues = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const partialSums = controlValues.map(
    (controlValue, index) => controlValue * parseInt(dig[index] as string, 10)
  );

  let sum = 0;
  partialSums.forEach((partialSum) => {
    sum += partialSum;
  });

  const control = sum % 11;

  return parseInt(dig[9] as string, 10) === control;
}

import { getCartOrders } from '../../../api/cart/CartClient';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Order } from '../../../models/data/Order';
import { getCartAction, getCartSuccessAction } from '../../slices/cartSlice';

const getCartsSaga = function* () {
  const orders: Order[] = yield call(getCartOrders);

  yield put(getCartSuccessAction({ orders }));
};
export function* cartSaga() {
  yield takeLatest(getCartAction, getCartsSaga);
}

import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Trash } from 'assets/trash-round.svg';
import { Flexbox } from '../atoms/FlexContainer';
import { ButtonClear } from '../atoms/buttons/ButtonClear';
import { Card } from '../atoms/Card';
import { FCWithChildren } from '../../../utils/types/FC';
import { EditButton } from '../atoms/buttons/EditButton';

const TopContainer = styled.div`
  padding: 15px 20px;
`;

const EditWrapper = styled(Flexbox)`
  width: 100%;
  padding: 15px 20px;
  border-top: 1px dashed var(--Gray-2, #c0ced6);
`;

const StyledCard = styled(Card)`
  border: 1px dashed var(--Gray-2, #c0ced6);
  width: 100% !important;
`;

export interface SavedItemCardProps {
  handleSelection: () => void;
  handleRemoval: () => void;
  handleEdit: () => void;
  selected?: boolean;
}

export const SavedItemCard: FCWithChildren<SavedItemCardProps> = ({
  children,
  handleSelection,
  handleRemoval,
  handleEdit,
  selected,
}) => (
  <StyledCard selected={selected}>
    <TopContainer onClick={handleSelection}>{children}</TopContainer>
    <EditWrapper justifyContent={selected ? 'flex-end' : 'space-between'}>
      {!selected && (
        <ButtonClear type="button" onClick={handleRemoval}>
          <Trash />
        </ButtonClear>
      )}
      <EditButton onClick={handleEdit} />
    </EditWrapper>
  </StyledCard>
);

import React, { PropsWithChildren, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { ButtonDotted } from 'components/UI/atoms/buttons/ButtonDotted';
import { FontSize } from 'styles/models';
import { Flexbox } from '../atoms/FlexContainer';
import { ExpandCollapse } from '../molecules/ExpandCollapse';
import { Entity } from '../../../models/data/Entity';
import { IconWrapper } from '../atoms/Icon';
import { P as StyledP } from '../atoms/typography/Typography';
import { Radio } from '../atoms/Radio';

const ButtonsContainer = styled(Flexbox)`
  flex-direction: row;
  gap: 10px;
`;

const P = styled(StyledP)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.fontMain};
  margin-block-end: 0;
  margin-block-start: 0;
`;

const SelectionButton = styled(ButtonDotted)`
  cursor: pointer;
  min-height: 77px;
`;

const Heading = styled(Flexbox)`
  flex: 1 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    flex-direction: row;
    align-items: center;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding-right: 20px;
  }
`;

const SelectedMethodTitle = styled.div`
  flex: 1 0 auto;
  width: inherit;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    width: auto;
  }
`;

const SelectedMethod = styled(Flexbox)`
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 100%;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    flex-direction: row;
    height: 30px;
    align-items: center;
    flex: 1 0 auto;
    width: auto;
    justify-content: flex-start;
  }
`;

const HeadingTitle = styled(Flexbox)`
  gap: 10px;
  flex: 0 1 auto;
`;

export interface MethodSelectionProps<T extends Entity> {
  methods: T[];
  selectMethod: (methodId: T['id']) => void;
  selectedMethodId?: T['id'];
  icon: ReactNode;
  renderMethod: (method: T, title?: boolean) => ReactNode;
  titleCollapsed: string;
  titleExpanded: string;
  hasError?: boolean;
  expanded?: boolean;
}

export const MethodSelection = <T extends Entity>({
  methods,
  selectedMethodId,
  icon,
  selectMethod,
  renderMethod,
  titleCollapsed,
  titleExpanded,
  hasError,
  expanded,
}: PropsWithChildren<MethodSelectionProps<T>>) => {
  const [isExpanded, setIsExpanded] = useState(!selectedMethodId);
  const selectedMethod = methods.find((method) => method.id === selectedMethodId);
  const selectMethodCb = (methodId: number) => {
    selectMethod(methodId);
    setIsExpanded(false);
  };

  const SelectionButtonInner = styled(Flexbox)`
    gap: 15px;
  `;

  return (
    <ExpandCollapse
      hasError={hasError}
      collapsedTitle={
        <Heading direction="column" alignItems="flex-start">
          <SelectedMethod>
            <HeadingTitle alignItems="center">
              <IconWrapper>{icon}</IconWrapper>
              <P size={FontSize.m}>{titleCollapsed}&nbsp;</P>
            </HeadingTitle>
            <SelectedMethodTitle>
              {selectedMethod && renderMethod(selectedMethod, true)}
            </SelectedMethodTitle>
          </SelectedMethod>
        </Heading>
      }
      expandedTitle={
        <SelectedMethod>
          <HeadingTitle alignItems="center">
            <IconWrapper>{icon}</IconWrapper>
            <P size={FontSize.m} hasError={hasError}>
              {titleExpanded}
            </P>
          </HeadingTitle>
        </SelectedMethod>
      }
      expand={expanded || isExpanded}
      handleExpand={() => setIsExpanded(true)}
    >
      <ButtonsContainer justifyContent="flex-start" direction="row" wrap="wrap">
        {methods.map((method) => (
          <SelectionButton
            key={method.id}
            selected={method.id === selectedMethodId}
            onClick={() => selectMethodCb(method.id)}
          >
            <SelectionButtonInner alignItems="center">
              <Radio checked={method.id === selectedMethodId} />
              {renderMethod(method)}
            </SelectionButtonInner>
          </SelectionButton>
        ))}
      </ButtonsContainer>
    </ExpandCollapse>
  );
};

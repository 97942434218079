import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Avatar } from 'assets/avatar.svg';
import { ReactComponent as Time } from 'assets/time.svg';
import { ReactComponent as List } from 'assets/list.svg';
import { ReactComponent as Box } from 'assets/box.svg';
import { ReactComponent as Home } from 'assets/home.svg';
import { Button, ButtonVariant } from '../atoms/buttons/Button';

const NoAccountContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  padding: 32px 30px 22px;
  border-radius: 10px;
  font-family: Arial, serif;
  background: ${({ theme }) => theme.colors.grayBackground};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
const Title = styled.div`
  margin-bottom: 13px;
  font-size: 21px;
  line-height: 28px;
  font-weight: bolder;
`;
const Email = styled.div`
  margin-bottom: 21px;
  font-size: ${({ theme }) => theme.font.s};

  strong {
    margin-left: 2px;
  }
`;
const AvatarButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 264px;
  text-decoration: none;
  color: white;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
  }
`;
const Ul = styled.ul`
  margin-top: 25px;
  padding-left: 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 10px;
  }
`;
const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  font-size: ${({ theme }) => theme.font.xs};

  strong {
    margin-bottom: 5px;
    font-size: ${({ theme }) => theme.font.s};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding-right: 66px;
  }
`;

const StyledButton = styled(Button)`
  width: 313px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 308px;
  }
`;

export interface NoAccountProps {
  orderEmail: string;
  redirectToRegisterPage: () => void;
}

export const NoAccount: FC<NoAccountProps> = ({ orderEmail, redirectToRegisterPage }) => {
  return (
    <NoAccountContainer>
      <div>
        <Title>
          Nie posiadasz jeszcze konta
          <br />
          na Sprzedajemy.pl?
        </Title>
        <Email>
          email: <strong>{orderEmail}</strong>
        </Email>
        <StyledButton size="middle" variant={ButtonVariant.secondary}>
          <AvatarButton onClick={redirectToRegisterPage}>
            <Avatar />
            <span>Załóż konto</span>
          </AvatarButton>
        </StyledButton>
      </div>
      <div>
        <Ul>
          <Li>
            <strong>Załóż konto aby uzyskać:</strong>
          </Li>
          <Li>
            <Time />
            uproszczony i szybszy proces zakupowy
          </Li>
          <Li>
            <List />
            dostęp do historii zamówień
          </Li>
          <Li>
            <Box />
            sprawdzanie statusu zamówienia
          </Li>
          <Li>
            <Home />
            łatwy dostęp do swoich danych, adresów wysyłkowych itp.
          </Li>
        </Ul>
      </div>
    </NoAccountContainer>
  );
};

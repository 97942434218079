import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '../atoms/buttons/Button';
import { Flexbox } from '../atoms/FlexContainer';

const ButtonsContainer = styled(Flexbox)`
  width: 100%;
  padding-top: 5px;
  gap: 11px;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    flex-direction: row-reverse;
    justify-content: center;
    gap: 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding-right: 11px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    width: 254px;
  }
`;

export interface YesNoButtonsProps {
  handleCancel: () => void;
  handleSave?: () => void;
}

export const SaveCancelButtons: FC<YesNoButtonsProps> = ({ handleCancel, handleSave }) => (
  <ButtonsContainer justifyContent="space-between" direction="column" alignItems="center">
    <ButtonWrapper>
      <Button size="big" onClick={handleSave} type={handleSave ? 'button' : 'submit'}>
        Zapisz
      </Button>
    </ButtonWrapper>
    <ButtonWrapper>
      <Button onClick={handleCancel} size="big" borderOnly type="button">
        Anuluj
      </Button>
    </ButtonWrapper>
  </ButtonsContainer>
);

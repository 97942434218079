import { Entity } from './Entity';
import { PaymentMethodSubCategory } from './PaymentMethodSubCategory';

export enum PaymentMethodType {
  blik = 'blik',
  scheme = 'scheme',
  dotpay = 'dotpay',
}

export enum PaymentMethodId {
  card = 1,
  blik = 2,
  dotpay = 3,
}

export interface PaymentMethodPartial extends Entity {
  id: PaymentMethodId;
  type: PaymentMethodType.blik | PaymentMethodType.scheme;
}

export interface DotpayPaymentMethod extends Entity {
  id: PaymentMethodId;
  type: PaymentMethodType.dotpay;
  subPayments: PaymentMethodSubCategory[];
}

export type PaymentMethod = PaymentMethodPartial | DotpayPaymentMethod;

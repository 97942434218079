import { createReducer } from '@reduxjs/toolkit';
import { DeliveryAddress, InvoiceData, ParcelLockerAddress } from '../../../models';
import {
  addBillingAddressFormErrorsAction,
  addDeliveryAddressFormErrorsAction,
  addInvoiceFormErrorsAction,
  addOrderNotesFormErrorsAction,
  addParcelLockerAddressFormErrorsAction,
  addParcelLockerBillingAddressFormErrorsAction,
  addUserAddressFormErrorsAction,
} from '../../actions/FormActions';
import { FormStatus, FormType } from '../../../models/data/Form';
import {
  addOrderBillingAddressAction,
  addOrderBillingAddressByIdAction,
  addOrderBillingAddressSuccessAction,
  addOrderDeliveryAddressAction,
  addOrderDeliveryAddressByIdAction,
  addOrderDeliveryAddressSuccessAction,
  addOrderInvoiceDataAction,
  addOrderInvoiceDataSuccessAction,
  addOrderNotesSuccessAction,
  addOrderParcelLockerBillingAddressAction,
  addOrderParcelLockerBillingAddressSuccessAction,
  submitOrderAction,
  submitOrderErrorAction,
} from '../../actions/OrderActions';
import {
  createUserAddressSuccessAction,
  updateUserAddressSuccessAction,
} from '../../actions/UserAddressesActions';

export interface FormState<T = {}> {
  errors?: Partial<Record<keyof T, string>>;
  status: FormStatus;
  generalError?: string;
}

export interface FormsState {
  [FormType.billingAddress]: FormState<DeliveryAddress>;
  [FormType.deliveryAddress]: FormState<DeliveryAddress>;
  [FormType.userAddress]: FormState<DeliveryAddress>;
  [FormType.invoiceData]: FormState<InvoiceData>;
  [FormType.orderNotes]: FormState<{ notes: string }>;
  [FormType.parcelLockerBillingAddress]: FormState<DeliveryAddress>;
  [FormType.parcelLockerAddress]: FormState<ParcelLockerAddress>;
  [FormType.order]: FormState;
}

const initialState: FormsState = {
  [FormType.deliveryAddress]: {
    status: FormStatus.clear,
  },
  [FormType.billingAddress]: {
    status: FormStatus.clear,
  },
  [FormType.userAddress]: {
    status: FormStatus.clear,
  },
  [FormType.invoiceData]: {
    status: FormStatus.clear,
  },
  [FormType.orderNotes]: {
    status: FormStatus.clear,
  },
  [FormType.parcelLockerAddress]: {
    status: FormStatus.clear,
  },
  [FormType.parcelLockerBillingAddress]: {
    status: FormStatus.clear,
  },
  [FormType.order]: {
    status: FormStatus.clear,
  },
};

export const formsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(addBillingAddressFormErrorsAction, (state, action) => {
      state.billingAddress.errors = action.payload.errors;
      state.billingAddress.status = FormStatus.invalid;
    })
    .addCase(addDeliveryAddressFormErrorsAction, (state, action) => {
      state.deliveryAddress.errors = action.payload.errors;
      state.deliveryAddress.status = FormStatus.invalid;
    })
    .addCase(addOrderDeliveryAddressAction, (state) => {
      state.deliveryAddress.status = FormStatus.loading;
      state.deliveryAddress.errors = undefined;
    })
    .addCase(addOrderDeliveryAddressSuccessAction, (state) => {
      state.deliveryAddress.status = FormStatus.submitted;
      state.deliveryAddress.errors = undefined;
    })
    .addCase(addOrderBillingAddressAction, (state) => {
      state.billingAddress.status = FormStatus.loading;
      state.billingAddress.errors = undefined;
    })
    .addCase(addOrderBillingAddressSuccessAction, (state) => {
      state.billingAddress.status = FormStatus.submitted;
      state.billingAddress.errors = undefined;
    })
    .addCase(addParcelLockerAddressFormErrorsAction, (state, action) => {
      state.parcelLockerAddress.errors = action.payload.errors;
      state.parcelLockerAddress.status = FormStatus.invalid;
    })
    .addCase(addOrderParcelLockerBillingAddressAction, (state, action) => {
      state.parcelLockerBillingAddress.status = FormStatus.loading;
      state.parcelLockerBillingAddress.errors = undefined;
    })
    .addCase(addOrderParcelLockerBillingAddressSuccessAction, (state) => {
      state.parcelLockerBillingAddress.status = FormStatus.submitted;
      state.parcelLockerBillingAddress.errors = undefined;
    })
    .addCase(addParcelLockerBillingAddressFormErrorsAction, (state, action) => {
      state.billingAddress.status = FormStatus.invalid;
      state.billingAddress.errors = action.payload.errors;
    })
    .addCase(addInvoiceFormErrorsAction, (state, action) => {
      state.invoiceData.status = FormStatus.invalid;
      state.invoiceData.errors = action.payload.errors;
    })
    .addCase(addOrderInvoiceDataAction, (state) => {
      state.invoiceData.status = FormStatus.loading;
      state.invoiceData.errors = undefined;
    })
    .addCase(addOrderInvoiceDataSuccessAction, (state, action) => {
      state.invoiceData.status = FormStatus.submitted;
      state.invoiceData.errors = undefined;
    })
    .addCase(addOrderNotesFormErrorsAction, (state, action) => {
      state.orderNotes.status = FormStatus.invalid;
      state.orderNotes.errors = action.payload.errors;
    })
    .addCase(addOrderNotesSuccessAction, (state, action) => {
      state.orderNotes.status = FormStatus.submitted;
      state.orderNotes.errors = undefined;
    })
    .addCase(addUserAddressFormErrorsAction, (state, action) => {
      state.userAddress.status = FormStatus.invalid;
      state.userAddress.errors = action.payload.errors;
    })
    .addCase(submitOrderAction, (state, action) => {
      state.order.status = FormStatus.loading;
    })
    .addCase(submitOrderErrorAction, (state, action) => {
      state.order.status = FormStatus.invalid;
    })
    .addMatcher(
      (action) =>
        addOrderDeliveryAddressAction.match(action) ||
        addOrderDeliveryAddressByIdAction.match(action),
      (state) => {
        state.deliveryAddress.status = FormStatus.loading;
        state.deliveryAddress.errors = undefined;
      }
    )
    .addMatcher(
      (action) =>
        updateUserAddressSuccessAction.match(action) ||
        createUserAddressSuccessAction.match(action),
      (state, action) => {
        state.userAddress.status = FormStatus.submitted;
        state.userAddress.errors = undefined;
      }
    )
);

import styled from '@emotion/styled';

export const Radio = styled.span<{ checked: boolean; size?: number }>(
  ({ size = 16 }) => ({
    position: 'relative',
    display: 'inline-block',
    height: `${size}px`,
    width: `${size}px`,
    borderRadius: '50%',
    border: '1px solid #d0dbe1',
    flex: `0 0 ${size}px`,
  }),
  ({ checked, size = 16 }) => {
    return checked
      ? {
          border: '1px solid #22354f',
          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: `${size / 2}px`,
            height: `${size / 2}px`,
            backgroundColor: '#22354f',
            borderRadius: '50%',
          },
        }
      : {};
  }
);

import React, { FC } from 'react';
import styled from '@emotion/styled';

export interface IconRoundWrapperProps {
  handleClick: () => void;
  Icon: FC<React.SVGProps<SVGSVGElement>>;
}

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #f3f6f7;
  cursor: pointer;
`;
export const IconRoundWrapper: FC<IconRoundWrapperProps> = ({ handleClick, Icon }) => (
  <IconWrapper onClick={handleClick}>
    <Icon
      style={{
        width: '100%',
        height: '100%',
        padding: '6px 6.5px',
      }}
    />
  </IconWrapper>
);

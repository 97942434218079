import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { KupTerazTemplate } from '../templates/KupTerazTemplate';
import {
  selectAddDeliveryAddressToOrder,
  selectAddInvoiceToOrder,
  selectAddNotesToOrder,
  selectBillingAddress,
  selectDeliveryAddress,
  selectInvoiceData,
  selectOrderComission,
  selectOrderErrors,
  selectOrderNotes,
  selectParcelLockerAddress,
  selectProducts,
  selectSelectedDeliveryMethodId,
  selectSelectedPaymentMethod,
  selectSeller,
  selectShowCardPaymentBox,
} from '../../../store/selectors';
import {
  addOrderBillingAddressAction,
  addOrderDeliveryAddressAction,
  addOrderDeliveryAddressByIdAction,
  addOrderDeliveryMethodAction,
  addOrderInvoiceDataAction,
  addOrderNotesAction,
  addOrderParcelLockerBillingAddressAction,
  addOrderPaymentMethodAction,
  changeProductAmountAction,
  deleteOrderInvoiceDataAction,
  deleteOrderNotesAction,
  deleteProductAction,
  getOrderAction,
  setAddDeliveryAddressToOrderAction,
  setAddInvoiceToOrderAction,
  setAddNotesToOrderAction,
  submitOrderAction,
} from '../../../store/actions/OrderActions';
import { getDeliveryMethodsAction } from '../../../store/actions/DeliveryMethodsActions';
import {
  createUserAddressAction,
  deleteUserAddressAction,
  getDefaultUserAddressesAction,
  getUserAddressesAction,
  updateUserAddressAction,
} from '../../../store/actions/UserAddressesActions';
import {
  selectBillingAddressFormState,
  selectDeliveryAddressFormState,
  selectInvoiceFormState,
  selectNotesFormState,
  selectOrderFormState,
  selectParcelLockerAddressFormState,
  selectParcelLockerBillingAddressFormState,
  selectUserAddressFormState,
} from '../../../store/selectors/formSelectors';
import { selectUserAddresses } from '../../../store/selectors/userAddressesSelectors';
import { DeliveryMethodType } from '../../../models';
import { selectDeliveryMethods } from '../../../store/selectors/deliveryMethodsSelectors';
import { selectShowInpostGeowidget } from '../../../store/selectors/utilsSelectors';
import { changeShowGeopupAction } from '../../../store/actions/UtilActions';
import { selectPaymentMethods } from '../../../store/selectors/paymentMethodsSelectors';
import {
  getPaymentMethodsAction,
  submitPaymentAction,
} from '../../../store/actions/PaymentActions';

export const KupTerazPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const deliveryMethods = useSelector(selectDeliveryMethods);
  const paymentMethods = useSelector(selectPaymentMethods);
  const selectedDeliveryMethodId = useSelector(selectSelectedDeliveryMethodId);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentMethod);
  const deliveryAddressFormState = useSelector(selectDeliveryAddressFormState);
  const billingAddressFormState = useSelector(selectBillingAddressFormState);
  const parcelLockerFormState = useSelector(selectParcelLockerAddressFormState);
  const parcelLockerBillingFormState = useSelector(selectParcelLockerBillingAddressFormState);
  const userAddressesFormState = useSelector(selectUserAddressFormState);
  const invoiceFormState = useSelector(selectInvoiceFormState);
  const notesFormState = useSelector(selectNotesFormState);
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const billingAddress = useSelector(selectBillingAddress);
  const parcelLockerAddress = useSelector(selectParcelLockerAddress);
  const products = useSelector(selectProducts);
  const userAddresses = useSelector(selectUserAddresses);
  const invoiceData = useSelector(selectInvoiceData);
  const notes = useSelector(selectOrderNotes);
  const addDeliveryAddressToOrder = useSelector(selectAddDeliveryAddressToOrder);
  const addInvoiceToOrder = useSelector(selectAddInvoiceToOrder);
  const addNotesToOrder = useSelector(selectAddNotesToOrder);
  const orderErrors = useSelector(selectOrderErrors);
  const showInpostGeowidget = useSelector(selectShowInpostGeowidget);
  const commission = useSelector(selectOrderComission);
  const orderFormStatus = useSelector(selectOrderFormState).status;
  const showCardPayment = useSelector(selectShowCardPaymentBox);
  const seller = useSelector(selectSeller);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderAction(id!));
    dispatch(getDeliveryMethodsAction());
    dispatch(getDefaultUserAddressesAction());
    dispatch(getPaymentMethodsAction());
    dispatch(getUserAddressesAction());
  }, [id]);

  const selectedDeliveryMethod = deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.id === selectedDeliveryMethodId
  );

  const payAtPickup = selectedDeliveryMethod?.type === DeliveryMethodType.pickup;

  return products.length ? (
    <KupTerazTemplate
      deliveryMethods={deliveryMethods}
      selectedDeliveryMethodId={selectedDeliveryMethodId}
      selectedPaymentMethod={selectedPaymentMethod}
      products={products}
      showDeliveryAddressSection={false}
      showPaymentMethodSection={false}
      payAtPickup={payAtPickup}
      seller={seller}
      paymentMethods={paymentMethods}
      billingAddressFormState={billingAddressFormState}
      deliveryAddressFormState={deliveryAddressFormState}
      userAddressFormState={userAddressesFormState}
      parcelLockerFormState={parcelLockerFormState}
      invoiceFormState={invoiceFormState}
      notesFormState={notesFormState}
      deliveryAddress={deliveryAddress}
      userAddresses={userAddresses}
      invoiceData={invoiceData}
      notes={notes}
      showInPostWidget={showInpostGeowidget}
      billingAddress={billingAddress}
      addDeliveryAddressToOrder={addDeliveryAddressToOrder}
      addInvoiceToOrder={addInvoiceToOrder}
      addNotesToOrder={addNotesToOrder}
      parcelLockerBillingAddressFormState={parcelLockerBillingFormState}
      parcelLockerBillingAddress={parcelLockerAddress}
      handleSetAddDeliveryAddressToOrder={(addDeliveryAddress: boolean) =>
        dispatch(setAddDeliveryAddressToOrderAction(addDeliveryAddress))
      }
      handleShowInPostWidget={(show) => dispatch(changeShowGeopupAction(show))}
      handlePaymentMethodSelection={(paymentMethodId, subCategoryId) =>
        dispatch(addOrderPaymentMethodAction(paymentMethodId, subCategoryId))
      }
      handleDeliveryAddressSubmit={(address) => dispatch(addOrderDeliveryAddressAction(address))}
      handleUserAddressSubmit={(address) => dispatch(createUserAddressAction(address))}
      handleDeliveryMethodSelection={(deliveryMethod) =>
        dispatch(addOrderDeliveryMethodAction(deliveryMethod))
      }
      handleAddOrderAddressId={(addressId) =>
        dispatch(addOrderDeliveryAddressByIdAction(addressId))
      }
      handleBillingAddressSubmit={(address) => dispatch(addOrderBillingAddressAction(address))}
      handleParcelLockerBillingAddressSubmit={(address) =>
        dispatch(addOrderParcelLockerBillingAddressAction(address))
      }
      handleAddUserAddress={(userAddress) => dispatch(createUserAddressAction(userAddress))}
      handleEditUserAddress={(userAddress) => dispatch(updateUserAddressAction(userAddress))}
      handleRemoveUserAddress={(addressId) => dispatch(deleteUserAddressAction(addressId))}
      parcelLockerAddress={parcelLockerAddress}
      handleInvoiceDataSubmit={(data) => dispatch(addOrderInvoiceDataAction(data))}
      handleInvoiceDataDeletion={() => dispatch(deleteOrderInvoiceDataAction())}
      handleAddNotesToOrder={(remarksData) => dispatch(addOrderNotesAction(remarksData))}
      handleDeleteNotesFromOrder={() => dispatch(deleteOrderNotesAction())}
      handleOrderSubmit={() => dispatch(submitOrderAction())}
      handleSetAddInvoiceToOrder={(addInvoice: boolean) =>
        dispatch(setAddInvoiceToOrderAction(addInvoice))
      }
      handleSetAddNotesToOrder={(addNotes: boolean) => dispatch(setAddNotesToOrderAction(addNotes))}
      orderErrors={orderErrors}
      commission={commission}
      orderFormStatus={orderFormStatus}
      handleCardPaymentSubmit={(cardPaymentDetails) =>
        dispatch(submitPaymentAction(cardPaymentDetails))
      }
      showCardPayment={showCardPayment}
      handleDeleteProduct={(productId) => dispatch(deleteProductAction(productId))}
      handleSetAmount={(productId, amount) =>
        dispatch(changeProductAmountAction(productId, amount))
      }
    />
  ) : null;
};

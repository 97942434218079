import React, { FC } from 'react';
import { ReactComponent as Lock } from 'assets/lock.svg';
import { ReactComponent as Alert } from 'assets/alert.svg';

import { Button, ButtonVariant } from '../atoms/buttons/Button';
import { Flexbox } from '../atoms/FlexContainer';
import styled from '@emotion/styled';
import { P } from '../atoms/typography/Typography';
import { FontSize } from '../../../styles/models';

const H2 = styled.h2`
  font-size: 21px;
  font-weight: 700;
  padding-bottom: 20px;
  line-height: normal;
`;

const StyledP = styled(P)`
  line-height: normal;
`;

const HeadingWrapper = styled(Flexbox)`
  padding: 0 30px;
`;

const TextWrapper = styled.div`
  width: 280px;
`;

const CartSummaryContainer = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 27px 0 0;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  border-bottom: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  font-family: Arial, serif;
  background-color: ${({ theme }) => theme.colors.white};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    border-radius: 5px;
    width: 389px;
    padding-top: 28px;
    border: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  }
`;

const SummaryWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.highlight};
  padding: 30px 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
    margin-top: 25px;
    border-radius: 0 0 5px 5px;
  }
`;

const SummaryEncryption = styled(Flexbox)`
  gap: 5px;
  font-size: ${({ theme }) => theme.font.xs};
`;

export const BuyNowButton = styled(Button)`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: bold;
  margin: 0 17px 25px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 5px;
    width: 327px;
  }
`;

export interface CartSummaryProps {
  handleClick: () => void;
  disabled: boolean;
}

export const CartSummary: FC<CartSummaryProps> = ({ handleClick, disabled }) => (
  <CartSummaryContainer>
    <HeadingWrapper justifyContent="space-between">
      <TextWrapper>
        <H2>Aby kontynuować zakupy wybierz jeden z koszyków.</H2>
        <StyledP size={FontSize.m}>
          W jednym zamówieniu możesz dokonać zakupu wielu produktów tylko od jednego sprzedającego.
        </StyledP>
      </TextWrapper>
      <Alert />
    </HeadingWrapper>
    <SummaryWrapper>
      <Flexbox justifyContent="center">
        <BuyNowButton
          onClick={handleClick}
          variant={ButtonVariant.primary}
          size="big"
          disabled={disabled}
        >
          PRZEJDŹ DO KOSZYKA
        </BuyNowButton>
      </Flexbox>
      <SummaryEncryption justifyContent="center" alignItems="center">
        <Lock />
        <span>Twoje dane są szyfrowane. Kupujesz bezpiecznie.</span>
      </SummaryEncryption>
    </SummaryWrapper>
  </CartSummaryContainer>
);

import { getEnvs } from '../../utils/env';
import memo from '../../utils/memo';

const getJWT = async (): Promise<{ jwt: string }> => {
  const jsonPromise = await fetch(`${getEnvs().JWT_URL}`, { credentials: 'include' });
  const data = await jsonPromise.json();
  return data;
};

export default memo(getJWT);

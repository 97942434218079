import React, { FC, KeyboardEventHandler } from 'react';
import styled from '@emotion/styled';
import { InputValidationTooltip } from './InputValidationTooltip';
import { Label as StyledLabel, TextInput } from './Input';

export const Label = styled(StyledLabel)`
  margin-bottom: 10px;
  line-height: 22px;
  font-size: ${({ theme }) => theme.font.s};
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletBig}) {
    white-space: nowrap;
    text-align: right;
  }
`;

export interface FormInputFieldProps {
  labelText: string;
  name: string;
  value?: string;
  handleChange: (e: React.ChangeEvent) => void;
  errorMsg?: string;
  inPopup?: boolean;
  type?: string;
  small?: boolean;
  onClick?: () => void;
  onKeyPress?: KeyboardEventHandler;
  disabled?: boolean;
  noAutocomplete?: boolean;
  placeholder?: string;
}

export const FormInputField: FC<FormInputFieldProps> = ({
  name,
  labelText,
  errorMsg,
  handleChange,
  value,
  type,
  small,
  inPopup,
  onClick,
  disabled,
  noAutocomplete,
  onKeyPress,
  placeholder,
}) => (
  <>
    <Label htmlFor={name} hasError={!!errorMsg}>
      {labelText}
    </Label>
    <InputValidationTooltip
      errorMsg={errorMsg}
      render={(onFocus, onBlur, hasError) => (
        <TextInput
          id={name}
          name={name}
          type={type || 'text'}
          onChange={handleChange}
          value={value}
          inPopup={inPopup}
          hasError={hasError}
          onBlur={onBlur}
          onFocus={onFocus}
          small={small}
          onClick={onClick}
          disabled={disabled}
          autoComplete={noAutocomplete ? 'off' : 'on'}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
        />
      )}
    />
  </>
);

import { createReducer } from '@reduxjs/toolkit';
import { PaymentMethod } from '../../../models/data/PaymentMethod';
import { getPaymentMethodsSuccessAction, paymentPendingAction } from '../../actions/PaymentActions';

export interface PaymentState {
  paymentMethods: PaymentMethod[];
  paymentPending: boolean;
}

const initialState: PaymentState = {
  paymentMethods: [],
  paymentPending: false,
};

export const paymentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPaymentMethodsSuccessAction, (state, action) => {
      state.paymentMethods = action.payload.paymentMethods;
    })
    .addCase(paymentPendingAction, (state) => {
      state.paymentPending = true;
    });
});

import React, { FC } from 'react';
import { InputValidationTooltip } from './form/InputValidationTooltip';
import { ReactComponent as Remark } from 'assets/remark.svg';

export const TooltipCommission: FC = () => {
  return (
    <InputValidationTooltip
      errorMsg={
        <span>
          <strong>Opłata transakcyjna</strong>Dzięki opłatom transakcyjnym możemy zaoferować Ci
          możliwość zakupu produktów oferowanych w naszym serwisie, szybką płatność i tanią
          przesyłkę. Z tej niewielkiej kwoty finansujemy również program “bezpieczne zakupy” (notka
          dla Piotra: nie możemy używać Pogram Ochrony Kupujących, to jest zbyt silnie powiązane z
          Allego) oraz rozwój serwisu, dzięki czemu zakupy będziesz robić coraz wygodniej i nadal
          bezpiecznie.
          <br />
          <br />
          <strong>Czy opłata transakcyjna jest obowiązkowa?</strong>Koszt opłaty transakcyjnej
          ponosisz wyłącznie korzystając z opcji “Kup Teraz”. Możesz skontaktować się ze sprzedawcą
          i zaproponować mu inną formę przekazania wpłaty oraz wybór innego sposobu dostawy.
          Pamiętaj jednak, że wówczas nie skorzystasz z programu “bezpieczne zakupy”, ani nie
          wystawisz komentarza po zakończonej transakcji. Nie będziemy też w stanie udostępnić Ci
          śledzenia Twojej przesyłki.
        </span>
      }
      render={() => (
        <Remark height={17} viewBox="0 0 24 24" style={{ marginLeft: 4, cursor: 'pointer' }} />
      )}
      onHover
    />
  );
};

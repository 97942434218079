import { createAction } from '@reduxjs/toolkit';
import { PaymentMethod } from '../../models/data/PaymentMethod';
import { CardPaymentDetails } from '../../models/data/CardPaymentDetails';

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';

export const SUBMIT_PAYMENT_ACTION = 'SUBMIT_PAYMENT_ACTION';
export const SUBMIT_PAYMENT_SUCCESS_ACTION = 'SUBMIT_PAYMENT_SUCCESS_ACTION';

export const SHOW_CARD_PAYMENT_ACTION = 'SHOW_CARD_PAYMENT_ACTION';

export const PAYMENT_PENDING_ACTION = 'PAYMENT_PENDING_ACTION';

export const getPaymentMethodsAction = createAction(GET_PAYMENT_METHODS);
export const getPaymentMethodsSuccessAction = createAction(
  GET_PAYMENT_METHODS_SUCCESS,
  (paymentMethods: PaymentMethod[]) => ({ payload: { paymentMethods } })
);

export const showCardPaymentAction = createAction(SHOW_CARD_PAYMENT_ACTION);

export const submitPaymentAction = createAction(
  SUBMIT_PAYMENT_ACTION,
  (cardPaymentDetails: CardPaymentDetails) => ({ payload: { cardPaymentDetails } })
);

export const paymentPendingAction = createAction(PAYMENT_PENDING_ACTION);

import { AppState } from '../reducers/rootReducer';
import { FormType } from '../../models/data/Form';
import { FormState } from '../reducers/forms/formsReducer';
import { DeliveryAddress, InvoiceData, ParcelLockerAddress } from '../../models';
import { NotesData } from '../../models/data/NotesData';

export const selectFormsState = (state: AppState) => state.forms;
export const selectBillingAddressFormState = (state: AppState): FormState<DeliveryAddress> =>
  selectFormsState(state)[FormType.billingAddress];
export const selectDeliveryAddressFormState = (state: AppState): FormState<DeliveryAddress> =>
  selectFormsState(state)[FormType.deliveryAddress];

export const selectUserAddressFormState = (state: AppState): FormState<DeliveryAddress> =>
  selectFormsState(state)[FormType.userAddress];

export const selectParcelLockerAddressFormState = (
  state: AppState
): FormState<ParcelLockerAddress> => selectFormsState(state)[FormType.parcelLockerAddress];

export const selectParcelLockerBillingAddressFormState = (
  state: AppState
): FormState<ParcelLockerAddress> => selectFormsState(state)[FormType.parcelLockerBillingAddress];

export const selectInvoiceFormState = (state: AppState): FormState<InvoiceData> =>
  selectFormsState(state)[FormType.invoiceData];

export const selectNotesFormState = (state: AppState): FormState<NotesData> =>
  selectFormsState(state)[FormType.orderNotes];

export const selectOrderFormState = (state: AppState): FormState =>
  selectFormsState(state)[FormType.order];

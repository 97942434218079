import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { submitPaymentAction } from '../../actions/PaymentActions';
import { selectOrderId, selectOrderValue } from '../../selectors';
import { submitPayment } from '../../../api/order/OrderPaymentClient';
import { Order } from '../../../models/data/Order';

function* submitPaymentSaga(action: Action) {
  if (submitPaymentAction.match(action)) {
    const orderId: Order['id'] = yield select(selectOrderId);
    const orderValue: string = yield select(selectOrderValue);

    yield call(submitPayment, action.payload.cardPaymentDetails, orderId, +orderValue);
    yield put(push(`/order/${orderId}/thank-you`));
  }
}
export function* paymentSaga() {
  yield takeLatest(submitPaymentAction.type, submitPaymentSaga);
}

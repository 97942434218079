import axios from 'axios';
import { getEnvs } from 'utils/env';
import { InvoiceData } from '../../models';
import { put } from '../api';

const ORDER_INVOICE_URL = '/order/$orderId/invoice-data';

export const addOrderInvoice = async (invoiceData: InvoiceData, orderId: string): Promise<void> => {
  await put(
    `${getEnvs().ORDER_BASE_URL}${ORDER_INVOICE_URL.replace('$orderId', orderId)}`,
    invoiceData
  );
};

export const deleteOrderInvoice = async (orderId: string): Promise<void> => {
  await axios.delete(
    `${getEnvs().ORDER_BASE_URL}${ORDER_INVOICE_URL.replace('$orderId', orderId)}`
  );
};

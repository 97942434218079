import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThankYouTemplate } from '../templates/ThankYouTemplate';
import { renewOrderStatusAction, getOrderStatusAction } from '../../../store/actions/OrderActions';
import { selectOrderStatus } from '../../../store/selectors';
import { selectPaymentPending } from '../../../store/selectors/paymentMethodsSelectors';

export const ThankYouPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const orderStatus = useSelector(selectOrderStatus);
  const paymentPending = useSelector(selectPaymentPending);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderStatusAction(id!));
  }, []);

  return (
    <ThankYouTemplate
      changeOrderStatus={() => dispatch(renewOrderStatusAction())}
      orderStatus={orderStatus}
      paymentPending={paymentPending}
    />
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '../../models/data/Order';

export interface CartState {
  orders: Order[];
}

const initialState: CartState = {
  orders: [],
};
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getCartSuccessAction: (state, action: PayloadAction<{ orders: Order[] }>) => {
      state.orders = action.payload.orders;
    },
    cancelOrderSuccessAction: (state, action: PayloadAction<{ orderId: string }>) => {
      state.orders = state.orders.filter((order) => order.id !== action.payload.orderId);
    },
    getCartAction: (state) => {},
    cancelOrderAction: (state, action: PayloadAction<{ orderId: string }>) => {},
  },
});

export const {
  getCartSuccessAction,
  getCartAction,
  cancelOrderSuccessAction,
  cancelOrderAction,
} = cartSlice.actions;

export default cartSlice.reducer;

import { createAction } from '@reduxjs/toolkit';
import { DeliveryAddress, UserAddress } from '../../models';
import { DefaultAddresses } from '../../models/data/DefaultAddresses';

const GET_USER_ADDRESSES_ACTION = 'GET_USER_ADDRESSES_ACTION';
const GET_USER_ADDRESSES_SUCCESS_ACTION = 'GET_USER_ADDRESSES_SUCCESS_ACTION';

const CREATE_USER_ADDRESS_ACTION = 'CREATE_USER_ADDRESS_ACTION';
const CREATE_USER_ADDRESS_SUCCESS_ACTION = 'CREATE_USER_ADDRESS_SUCCESS_ACTION';

const DELETE_USER_ADDRESS_ACTION = 'DELETE_USER_ADDRESS_ACTION';
const DELETE_USER_ADDRESS_SUCCESS_ACTION = 'DELETE_USER_ADDRESS_SUCCESS_ACTION';

const UPDATE_USER_ADDRESS_ACTION = 'UPDATE_USER_ADDRESS_ACTION';
const UPDATE_USER_ADDRESS_SUCCESS_ACTION = 'UPDATE_USER_ADDRESS_SUCCESS_ACTION';

const GET_DEFAULT_ADDRESSES_ACTION = 'GET_DEFAULT_ADDRESSES_ACTION';
const GET_DEFAULT_ADDRESSES_SUCCESS_ACTION = 'GET_DEFAULT_ADDRESSES_SUCCESS_ACTION';

export const getUserAddressesAction = createAction(GET_USER_ADDRESSES_ACTION);
export const getUserAddressesSuccessAction = createAction(
  GET_USER_ADDRESSES_SUCCESS_ACTION,
  (userAddresses: UserAddress[]) => ({ payload: { userAddresses } })
);

export const createUserAddressAction = createAction(
  CREATE_USER_ADDRESS_ACTION,
  (address: DeliveryAddress) => ({
    payload: { address },
  })
);

export const createUserAddressSuccessAction = createAction(
  CREATE_USER_ADDRESS_SUCCESS_ACTION,
  (address: UserAddress) => ({
    payload: { address },
  })
);

export const deleteUserAddressAction = createAction(
  DELETE_USER_ADDRESS_ACTION,
  (addressId: UserAddress['id']) => ({ payload: { addressId } })
);

export const deleteUserAddressSuccessAction = createAction(
  DELETE_USER_ADDRESS_SUCCESS_ACTION,
  (addressId: UserAddress['id']) => ({ payload: { addressId } })
);

export const updateUserAddressAction = createAction(
  UPDATE_USER_ADDRESS_ACTION,
  (userAddress: UserAddress) => ({ payload: { userAddress } })
);

export const updateUserAddressSuccessAction = createAction(
  UPDATE_USER_ADDRESS_SUCCESS_ACTION,
  (userAddress: UserAddress) => ({ payload: { userAddress } })
);

export const getDefaultUserAddressesAction = createAction(GET_DEFAULT_ADDRESSES_ACTION);

export const getDefaultUserAddressesSuccessAction = createAction(
  GET_DEFAULT_ADDRESSES_SUCCESS_ACTION,
  (defaultAddresses: DefaultAddresses) => ({
    payload: { defaultAddresses },
  })
);

import { getEnvs } from 'utils/env';
import { del, put } from '../api';
import { Order } from '../../models/data/Order';

export const ORDER_NOTES_URL = 'order/$orderId/notes';

export const addOrderNotes = async (notes: string, orderId: Order['id']): Promise<void> => {
  await put(`${getEnvs().ORDER_BASE_URL}/${ORDER_NOTES_URL.replace('$orderId', orderId)}`, {
    notes,
  });
};

export const deleteOrderNotes = async (orderId: Order['id']): Promise<void> => {
  await del(`${getEnvs().ORDER_BASE_URL}/${ORDER_NOTES_URL.replace('$orderId', orderId)}`);
};

import axios from 'axios';
import { getEnvs } from 'utils/env';
import { DeliveryMethod, DeliveryMethodType } from '../../models';

const DELIVERY_METHODS_URL = 'order/$orderId/delivery-methods';
const DELIVERY_METHOD_URL = 'order/$orderId/delivery-method';

export const addOrderDeliveryMethod = async (
  orderId: number,
  deliveryMethodId: DeliveryMethod['id'],
  parcelLockerCode?: string
) => {
  await axios.put(
    `${getEnvs().ORDER_BASE_URL}/${DELIVERY_METHOD_URL.replace('$orderId', orderId.toString())}`,
    {
      deliveryId: deliveryMethodId,
      parcelLockerCode,
    }
  );
};

interface DeliveryMethodResponseSize {
  id: number;
  packageSizeId: number;
  maxAmount: number;
  recommendedAmount: number;
  price: string;
  totalPrice: string;
}
export interface DeliveryMethodResponse {
  name: string;
  code: string;
  payAtPickup: boolean;
  type: string;
  sortingOrder: number;
  sizes: Record<string, DeliveryMethodResponseSize>;
}

export async function getDeliveryMethods(orderId: number): Promise<DeliveryMethod[]> {
  const response = await axios.get<DeliveryMethodResponse[]>(
    `${getEnvs().ORDER_BASE_URL}/${DELIVERY_METHODS_URL.replace('$orderId', orderId.toString())}`
  );
  return response.data.map((deliveryMethod) => ({
    id: deliveryMethod.sizes[Object.keys(deliveryMethod.sizes)[0]].id,
    code: deliveryMethod.code as 'inpost' | 'dhl' | 'poczta' | 'pickup' | 'orlen',
    name: deliveryMethod.name,
    type: deliveryMethod.type as DeliveryMethodType,
    price: Number(deliveryMethod.sizes[Object.keys(deliveryMethod.sizes)[0]].totalPrice),
  }));
}

import { createReducer } from '@reduxjs/toolkit';
import { UserAddress } from '../../../models';

import {
  createUserAddressSuccessAction,
  deleteUserAddressSuccessAction,
  getDefaultUserAddressesSuccessAction,
  getUserAddressesSuccessAction,
  updateUserAddressSuccessAction,
} from '../../actions/UserAddressesActions';
import { DefaultAddresses } from '../../../models/data/DefaultAddresses';

import { addOrderDeliveryAddressSuccessAction } from '../../actions/OrderActions';

export interface UserAddressesState {
  addresses: UserAddress[];
  defaultAddresses?: DefaultAddresses;
  sessionAddresses: Partial<DefaultAddresses>;
}

const initialState: UserAddressesState = {
  addresses: [],
  sessionAddresses: {},
  defaultAddresses: undefined,
};

export const userAddressesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createUserAddressSuccessAction, (state, action) => {
      state.addresses.push(action.payload.address);
    })
    .addCase(getUserAddressesSuccessAction, (state, action) => {
      state.addresses = action.payload.userAddresses;
    })
    .addCase(deleteUserAddressSuccessAction, (state, action) => {
      state.addresses = state.addresses.filter(
        (address) => address.id !== action.payload.addressId
      );
    })
    .addCase(updateUserAddressSuccessAction, (state, action) => {
      state.addresses = state.addresses.map((address) =>
        address.id === action.payload.userAddress.id ? action.payload.userAddress : address
      );
    })
    .addCase(getDefaultUserAddressesSuccessAction, (state, action) => {
      state.defaultAddresses = action.payload.defaultAddresses;
    })
    .addCase(addOrderDeliveryAddressSuccessAction, (state, action) => {
      state.sessionAddresses.parcel = action.payload.address;
    });
});

import styled from '@emotion/styled';

export const ButtonDotted = styled.button<{ selected?: boolean }>`
  padding: 15px 20px;
  border: solid 1px #dfe6ea;
  border-radius: 6px;
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  ${({ selected }) => selected && 'border: solid 1px #22354f;'}
  &:hover {
    border: solid 1px #88949e;
  }
`;

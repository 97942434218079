export class FormValidationError<T> extends Error {
  errors: Partial<Record<keyof T, string>>;

  constructor(errors: Partial<Record<keyof T, string>>) {
    super('Form validation error');
    this.errors = errors;
  }

  formErrors(): Partial<Record<keyof T, string>> {
    return this.errors;
  }
}

import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { IconRoundWrapper } from 'utils/IconRoundWrapper';
import { ReactComponent as Trash } from 'assets/trash.svg';
import { NumberButtons } from '../atoms/form/NumberButtons';
import { Hide, Show } from '../atoms/Visibility';
import { useTheme } from '@emotion/react';
import { priceFormat } from '../../../utils/orderValue/orderValue';
import { Flexbox } from '../atoms/FlexContainer';

const ProductCardContainer = styled.div`
  font-family: Arial, serif;
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.fontMain};
  text-decoration: none;
`;

const Img = styled.img<{ simple?: boolean }>`
  width: 120px;
  min-width: 120px;
  height: 86px;
  border-radius: 3px;
  object-fit: cover;
  user-select: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 140px;
    min-width: 140px;
    height: 99px;
    margin-left: 10px;
    ${({ simple }) => simple && `margin-left: 0px;`};
  }
`;

const ProductDescriptionContainer = styled.div`
  width: 350px;
  padding-left: 10px;
  padding-right: 31px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding-left: 18px;
  }
`;

const ProductTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 6px;
  font-size: ${({ theme }) => theme.font.s};
  font-weight: bold;
  line-height: 18px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    line-height: 21px;
  }
`;
const PriceWrapper = styled.div`
  width: 100%;
  min-width: 115px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: right;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: auto;
  }
`;
const Price = styled.div<{ simple?: boolean }>`
  font-size: ${({ theme }) => theme.font.l};
  font-weight: bold;
  white-space: nowrap;
  ${({ simple, theme }) =>
    simple &&
    `
      font-weight: normal;
      font-size: ${theme.font.m};
  `};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    ${({ simple, theme }) =>
      !simple &&
      `
        font-size: ${theme.font.xl};
    `};
  }
`;
const X = styled.span`
  margin: 0 14px;
  font-weight: bold;
`;
const PricePerPiece = styled.div`
  font-size: ${({ theme }) => theme.font.s};
`;

const ProductDetails = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const MobileFlexRow = styled(Flexbox)<{ extraMargin?: string }>`
  align-items: center;
  margin-bottom: ${({ extraMargin }) => (extraMargin ? extraMargin : '18px')};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-bottom: 0;
  }
`;
const DesktopFlexRow = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    justify-content: left;
    align-items: center;
    &:last-child {
      justify-content: right;
    }
  }
`;

export interface ProductCardProps {
  simple?: boolean;
  productId: string;
  productUrl: string;
  photoUrl: string;
  photoAlt: string;
  title: string;
  price: number;
  size?: string;
  brand?: string;
  amount: number;
  total?: number;
  handleDeleteProduct?: (productId: string) => void;
  handleSetAmount?: (productId: string, amount: number) => void;
}

export const ProductCard: FC<ProductCardProps> = ({
  simple = false,
  productId,
  productUrl,
  photoUrl,
  photoAlt,
  title,
  price,
  size,
  brand,
  amount = 1,
  handleDeleteProduct,
  handleSetAmount,
  total = 1,
}) => {
  const theme = useTheme();
  const [newAmount, setNewAmount] = useState(amount);
  const handleAmountChange = (amount: number) => {
    let amountToSet = amount;
    if (amount < 1) {
      amountToSet = 1;
    } else if (total && amount > total) {
      amountToSet = total;
    }
    handleSetAmount?.(productId, amountToSet);
    setNewAmount(amountToSet);
  };
  return (
    <ProductCardContainer>
      <DesktopFlexRow>
        {!simple && (
          <MobileFlexRow justifyContent={'space-between'}>
            <Hide from={theme.breakpoint.tablet}>
              <IconRoundWrapper Icon={Trash} handleClick={() => handleDeleteProduct?.(productId)} />
            </Hide>
          </MobileFlexRow>
        )}
        <MobileFlexRow justifyContent={'flex-start'} extraMargin={'26px'}>
          <Link href={productUrl}>
            <Img simple={simple} src={photoUrl} alt={photoAlt} />
          </Link>
          <ProductDescriptionContainer>
            <Link href={productUrl}>
              <ProductTitle>{title}</ProductTitle>
            </Link>
            {size && <ProductDetails>Rozmiar: {size}</ProductDetails>}
            {brand && <ProductDetails>Marka: {brand}</ProductDetails>}
          </ProductDescriptionContainer>
        </MobileFlexRow>
      </DesktopFlexRow>
      <DesktopFlexRow>
        <MobileFlexRow justifyContent={'space-between'} extraMargin={simple ? '0px' : '18px'}>
          {!simple ? (
            <>
              <NumberButtons
                handleChange={handleAmountChange}
                value={newAmount}
                minValue={1}
                maxValue={total}
              />
              <PriceWrapper>
                <Price>{priceFormat(price * newAmount)}</Price>
                {newAmount > 1 && <PricePerPiece>za szt {priceFormat(price)}</PricePerPiece>}
              </PriceWrapper>
            </>
          ) : (
            <PriceWrapper>
              <Price simple>
                {newAmount} <X>x</X> <strong>{priceFormat(price)}</strong>
              </Price>
            </PriceWrapper>
          )}
        </MobileFlexRow>
        {!simple && (
          <Show from={theme.breakpoint.tablet}>
            <IconRoundWrapper Icon={Trash} handleClick={() => handleDeleteProduct?.(productId)} />
          </Show>
        )}
      </DesktopFlexRow>
    </ProductCardContainer>
  );
};

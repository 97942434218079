import styled from '@emotion/styled';

export interface FlexContainerProps {
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  wrap?: 'wrap' | 'nowrap';
  fill?: boolean;
}

export const Flexbox = styled.div<FlexContainerProps>`
  display: flex;
  ${(props) => props.direction && `flex-direction:${props.direction};`}
  ${(props) => props.justifyContent && `justify-content:${props.justifyContent};`}
  ${(props) => props.alignContent && `align-content:${props.alignContent};`}
  ${(props) => props.alignItems && `align-items:${props.alignItems};`}
  ${(props) => props.wrap && `flex-wrap:${props.wrap};`}
  ${({ fill }) => fill && `width: 100%;`}
`;

import React, { FC, useEffect, useRef, useState } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import styled from '@emotion/styled';
import { getCardPaymentConfig } from '../../../utils/card/getCardPaymentConfig';
import { CardPaymentDetails } from '../../../models/data/CardPaymentDetails';
import { Popup } from '../atoms/Popup';

const PaymentEl = styled.div`
  padding-top: 20px;
`;

export interface CardPaymentProps {
  amount: number;
  handlePayment: (cardPaymentDetail: CardPaymentDetails) => void;
  show: boolean;
}

export const CardPaymentComponent: FC<CardPaymentProps> = ({ amount, handlePayment, show }) => {
  const paymentEl = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-unused-vars
  const [componentRef, setComponentRef] = useState(undefined);

  useEffect(() => {
    if (show) {
      const checkout = new AdyenCheckout({
        ...getCardPaymentConfig(amount),
        // @ts-ignore

        onSubmit: (dt, component) => {
          handlePayment(dt.data.paymentMethod);
          setComponentRef(component);
        },
      });

      checkout.create('card').mount(paymentEl.current as HTMLElement);
    }
  }, [show]);

  return (
    <Popup isOpen={show} wide={true}>
      <PaymentEl ref={paymentEl} />
    </Popup>
  );
};

import styled from '@emotion/styled';
import { Flexbox } from './FlexContainer';

export const Chip = styled(Flexbox)`
  height: 17px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue7};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  padding: 3px 8px;
`;

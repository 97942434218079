import { all, call, spawn, getContext, put } from 'redux-saga/effects';
import { Saga } from '@redux-saga/types';
import axios from 'axios';
import { push } from 'connected-react-router';
import { internalServerErrorAction } from '../../store/actions/ErrorsActions';

export function* runSagas(sagas: Saga[]) {
  yield all(
    sagas.map((saga) =>
      spawn(function* runSagas() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              if (e.response?.status === 401) {
                const handleForbidden: (href: string) => void = yield getContext('handleForbidden');
                handleForbidden(window.location.href);
                return;
              }
              if (e.response?.status === 403) {
                const handleUnauthorized: (href: string) => void = yield getContext(
                  'handleUnauthorized'
                );
                handleUnauthorized(window.location.href);
                return;
              }

              if (e.response?.status === 404) {
                yield put(push('/404'));
              }

              if (e.response?.status === 500) {
                yield put(internalServerErrorAction());
              }
            }
            throw e;
          }
        }
      })
    )
  );
}

import Big from 'big.js';
import { Product } from '../../models/data/Product';
import { roundUp } from '../roundUp/roundUp';

export const calulateProductSum = (product: Product): Big => Big(product.price).mul(product.amount);

export function calculateProductsSum(products: Product[]): Big {
  return products.reduce((sum, product) => sum.plus(calulateProductSum(product)), Big(0));
}

export function calculateCommission(products: Product[], commission: number): Big {
  return calculateProductsSum(products).mul(commission).div(100);
}

export function calculateOrderSumWithCommission(
  products: Product[],
  commission: number,
  deliveryPrice?: number
): Big {
  const productsValue = calculateProductsSum(products);

  return productsValue.plus(productsValue.mul(commission).div(100)).plus(deliveryPrice || 0);
}

export function calculateOrderValue(
  products: Product[],
  commission: number,
  deliveryPrice?: number
): string {
  const orderSum = calculateOrderSumWithCommission(products, commission, deliveryPrice);

  return roundUp(orderSum);
}

export function priceFormat(num: string | number) {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num.toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });
}

export const getParsedPrice = (price: number | string | null | undefined): string => {
  if (price === null || price === undefined) {
    return '';
  }
  const priceNumber = Number(typeof price === 'string' ? price.replace(',', '.') : price);

  if (isNaN(priceNumber)) {
    return '';
  }
  return priceNumber.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
};

import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { InvoiceSection } from 'components/UI/organisms/InvoiceSection';
import { DeliveryMethodSelectionSection } from 'components/UI/organisms/DeliveryMethodSelectionSection';
import {
  DeliveryAddress,
  DeliveryMethod,
  DeliveryMethodType,
  InvoiceData,
  ParcelLockerAddress,
  UserAddress,
} from 'models';
import { DeliveryAddressSection } from 'components/UI/organisms/DeliveryAddressSection';
import { NotesSection } from 'components/UI/organisms/NotesSection';
import { ProductsSection } from 'components/UI/organisms/ProductsSection';
import { Product } from '../../../models/data/Product';
import { PaymentMethodSelectionSection } from '../organisms/PaymentMethodSelectionSection';
import { PaymentSummary } from '../organisms/PaymentSummary';
import { Flexbox } from '../atoms/FlexContainer';
import { PaymentMethod } from '../../../models/data/PaymentMethod';
import { FormState } from '../../../store/reducers/forms/formsReducer';
import {
  ParcelLockerSelection,
  ParcelLockerSelectionProps,
} from '../organisms/ParcelLockerSelection';
import { ErrorMessage } from '../molecules/ErrorMessage';
import { NotesData } from '../../../models/data/NotesData';
import { PaymentMethodSubCategory } from '../../../models/data/PaymentMethodSubCategory';
import { OrderState } from '../../../store/reducers/order/orderReducer';
import { usePrevious } from '../../../utils/usePrevious';
import { FormStatus } from '../../../models/data/Form';
import { CardPaymentComponent } from '../organisms/CardPaymentComponent';
import { CardPaymentDetails } from '../../../models/data/CardPaymentDetails';
import { Seller } from '../../../models/data/Order';
import { userUrl } from '../../../utils/userUrl';
import { Grid, Row } from '../atoms/Grid';
import { BuyNowButton } from '../organisms/CartSummary';
import { Chip } from '../atoms/Chip';
import { printProductsNumber } from '../../../utils/printProductsNumber';
import { calculateProductsSum } from '../../../utils/orderValue/orderValue';

const Spacing = styled.div`
  margin-top: 19px;
`;

const PaymentSummaryWrapper = styled(Flexbox)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    justify-content: flex-start;
    margin-left: 30px;
  }
`;

const H1 = styled.h1`
  font-size: 32px;
  font-weight: 700;
  padding-right: 15px;
`;

const FormsWrapper = styled(Flexbox)`
  width: 100%;
`;

const HeadingWrapper = styled(Flexbox)`
  width: 100%;
  margin: 28px 15px 28px 0;
`;

const BuyNowBox = styled(Row)`
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    justify-self: end;
  }
`;

export interface KupTerazTemplateProps {
  products: Product[];
  selectedDeliveryMethodId?: DeliveryMethod['id'];
  deliveryMethods: DeliveryMethod[];
  handleDeliveryMethodSelection: (deliveryMethod: DeliveryMethod) => void;
  showPaymentMethodSection: boolean;
  selectedPaymentMethod: OrderState['paymentMethod'];
  paymentMethods?: PaymentMethod[];
  handlePaymentMethodSelection: (
    paymentMethodId: PaymentMethod['id'],
    subCategoryId?: PaymentMethodSubCategory['id']
  ) => void;
  showDeliveryAddressSection: boolean;
  payAtPickup?: boolean;
  handleBillingAddressSubmit: (address: DeliveryAddress) => void;
  handleParcelLockerBillingAddressSubmit: (address: ParcelLockerAddress) => void;
  handleDeliveryAddressSubmit: (address: DeliveryAddress) => void;
  handleUserAddressSubmit: (address: DeliveryAddress) => void;
  billingAddressFormState: FormState<DeliveryAddress>;
  parcelLockerBillingAddressFormState: FormState<ParcelLockerAddress>;
  deliveryAddressFormState: FormState<DeliveryAddress>;
  parcelLockerFormState: FormState<ParcelLockerAddress>;
  invoiceFormState: FormState<InvoiceData>;
  userAddressFormState: FormState<UserAddress>;
  notesFormState: FormState<NotesData>;
  billingAddress?: DeliveryAddress;
  deliveryAddress?: DeliveryAddress;
  parcelLockerBillingAddress?: ParcelLockerAddress;
  parcelLockerAddress?: ParcelLockerAddress;
  userAddresses?: UserAddress[];
  invoiceData?: InvoiceData;
  notes?: string;
  addNotesToOrder: boolean;
  addDeliveryAddressToOrder: boolean;
  addInvoiceToOrder: boolean;
  handleAddOrderAddressId: (addressId: UserAddress['id']) => void;
  handleAddUserAddress: (address: DeliveryAddress) => void;
  handleRemoveUserAddress: (addressId: UserAddress['id']) => void;
  handleEditUserAddress: (address: UserAddress) => void;
  handleInvoiceDataSubmit: (invoiceData: InvoiceData) => void;
  handleInvoiceDataDeletion: () => void;
  handleAddNotesToOrder: (notes: string) => void;
  handleDeleteNotesFromOrder: () => void;
  handleOrderSubmit: () => void;
  handleSetAddDeliveryAddressToOrder: (addDeliveryAddressToOrder: boolean) => void;
  handleSetAddInvoiceToOrder: (addInvoiceToOrder: boolean) => void;
  handleSetAddNotesToOrder: (addNotesToOrder: boolean) => void;
  orderErrors: string[];
  showInPostWidget: boolean;
  seller: Seller;
  handleShowInPostWidget: (show: boolean) => {};
  commission: number;
  orderFormStatus: FormStatus;
  handleCardPaymentSubmit: (cardPaymentDetails: CardPaymentDetails) => void;
  showCardPayment: boolean;
  handleDeleteProduct: (productId: string) => void;
  handleSetAmount: (productId: string, amount: number) => void;
  handleDeleteAll?: () => void;
}

export const KupTerazTemplate: FC<KupTerazTemplateProps> = ({
  products,
  selectedDeliveryMethodId,
  deliveryMethods,
  handleDeliveryMethodSelection,
  showDeliveryAddressSection,
  payAtPickup,
  selectedPaymentMethod,
  handlePaymentMethodSelection,
  paymentMethods,
  handleDeliveryAddressSubmit,
  deliveryAddressFormState,
  handleBillingAddressSubmit,
  handleParcelLockerBillingAddressSubmit,
  billingAddressFormState,
  billingAddress,
  notesFormState,
  invoiceFormState,
  deliveryAddress,
  parcelLockerBillingAddress,
  parcelLockerAddress,
  userAddresses,
  handleAddOrderAddressId,
  handleAddUserAddress,
  handleEditUserAddress,
  handleRemoveUserAddress,
  parcelLockerFormState,
  handleInvoiceDataSubmit,
  handleInvoiceDataDeletion,
  invoiceData,
  addDeliveryAddressToOrder,
  handleSetAddDeliveryAddressToOrder,
  handleAddNotesToOrder,
  handleDeleteNotesFromOrder,
  notes,
  handleOrderSubmit,
  addNotesToOrder,
  addInvoiceToOrder,
  handleSetAddInvoiceToOrder,
  handleSetAddNotesToOrder,
  orderErrors,
  showInPostWidget,
  handleShowInPostWidget,
  commission,
  orderFormStatus,
  handleCardPaymentSubmit,
  showCardPayment,
  handleDeleteProduct,
  handleSetAmount,
  handleDeleteAll,
  seller,
}) => {
  const [checkout, setCheckout] = useState(false);
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [parcelLockerCode, setParcelLockerCode] = useState('');

  const prevBillingAddress = usePrevious(billingAddress);
  const prevDeliveryAddress = usePrevious(deliveryAddress);

  const selectedDeliveryMethod = deliveryMethods.find(
    (deliveryMethod) => deliveryMethod.id === selectedDeliveryMethodId
  );
  const handleKupTeraz = () => {
    setCheckout(true);
    handleOrderSubmit();
  };
  return (
    <>
      <CardPaymentComponent
        show={showCardPayment}
        amount={122.53}
        handlePayment={(arg) => handleCardPaymentSubmit(arg)}
      />
      <ErrorMessage messages={orderErrors} />
      <Grid>
        <Row mobile={{ col: { from: 1, to: 5 } }} tablet={{ col: { from: 1, to: 9 } }}>
          <Flexbox>
            <HeadingWrapper justifyContent="flex-start" alignItems="center">
              <H1>Kupujesz</H1>
              <Chip>
                {printProductsNumber(products.reduce((sum, product) => sum + product.amount, 0))}
              </Chip>
            </HeadingWrapper>
          </Flexbox>
        </Row>
        <Row mobile={{ col: { from: 1, to: 5 } }} tablet={{ col: { from: 1, to: 9 } }}>
          <FormsWrapper direction="column" alignItems="center">
            <ProductsSection
              handleDeleteProduct={handleDeleteProduct}
              handleSetAmount={handleSetAmount}
              handleDeleteAll={handleDeleteAll}
              sellerName={seller.name}
              urlMoreOffer={userUrl(seller.sprzedajemyUserId)}
              products={products}
            />
            <Spacing />
            <DeliveryMethodSelectionSection
              deliveryMethods={deliveryMethods}
              selectDeliveryMethod={handleDeliveryMethodSelection}
              selectedDeliveryMethodId={selectedDeliveryMethodId}
              checkOutInitiated={checkout}
              handleShowInPostWidget={handleShowInPostWidget}
            />
            <Spacing />
            {!!selectedDeliveryMethodId && !!paymentMethods?.length && (
              <>
                <PaymentMethodSelectionSection
                  selectedPaymentMethod={selectedPaymentMethod}
                  paymentMethods={paymentMethods}
                  selectPaymentMethod={handlePaymentMethodSelection}
                  payAtPickup={payAtPickup}
                  checkOutInitiated={checkout}
                />
                <Spacing />
              </>
            )}
            {(!!selectedPaymentMethod?.id || payAtPickup) && (
              <>
                <DeliveryAddressSection
                  handleSubmit={handleBillingAddressSubmit}
                  checkoutInitiated={checkout}
                  deliveryAddressFormState={billingAddressFormState}
                  selectedAddress={billingAddress}
                  userAddresses={userAddresses || []}
                  handleAddOrderAddressId={handleAddOrderAddressId}
                  handleAddUserAddress={handleAddUserAddress}
                  handleEditUserAddress={handleEditUserAddress}
                  handleRemoveUserAddress={handleRemoveUserAddress}
                  initialAddress={prevBillingAddress === billingAddress}
                />
                <Spacing />
                <DeliveryAddressSection
                  handleSubmit={handleDeliveryAddressSubmit}
                  checkoutInitiated={checkout}
                  deliveryAddressFormState={deliveryAddressFormState}
                  selectedAddress={deliveryAddress}
                  userAddresses={userAddresses || []}
                  handleAddOrderAddressId={handleAddOrderAddressId}
                  handleAddUserAddress={handleAddUserAddress}
                  handleEditUserAddress={handleEditUserAddress}
                  handleRemoveUserAddress={handleRemoveUserAddress}
                  toggleStatus={addDeliveryAddressToOrder}
                  initialAddress={prevDeliveryAddress === deliveryAddress}
                  onToggle={handleSetAddDeliveryAddressToOrder}
                />
                <Spacing />
              </>
            )}
            {(!!selectedPaymentMethod || !!payAtPickup) &&
              (billingAddress || !!parcelLockerAddress) && (
                <>
                  <InvoiceSection
                    clearInvoiceData={handleInvoiceDataDeletion}
                    invoiceData={invoiceData}
                    checkoutInitiated={checkout}
                    handleInvoiceDataAddition={handleInvoiceDataSubmit}
                    deliveryAddress={deliveryAddress}
                    invoiceFormState={invoiceFormState}
                    addInvoiceToOrder={addInvoiceToOrder}
                    handleSetAddInvoiceToOrder={handleSetAddInvoiceToOrder}
                  />
                  <Spacing />
                  <NotesSection
                    notes={notes}
                    formState={notesFormState}
                    handleAddNotesToOrder={handleAddNotesToOrder}
                    handleRemoveNotesFromOrder={handleDeleteNotesFromOrder}
                    addNotesToOrder={addNotesToOrder}
                    handleSetAddNotesToOrder={handleSetAddNotesToOrder}
                  />
                </>
              )}
          </FormsWrapper>
        </Row>
        <Spacing />
        <BuyNowBox
          mobile={{ col: { from: 1, to: 5 } }}
          tablet={{ col: { from: 3, to: 7 } }}
          laptop={{ col: { from: 9, to: 13 }, row: { from: 2, to: 2 } }}
        >
          <PaymentSummaryWrapper justifyContent="center">
            <PaymentSummary
              products={products}
              deliveryPrice={selectedDeliveryMethod?.price}
              deliveryName={selectedDeliveryMethod?.type}
              commissionPercentage={commission}
              paymentMethodName="blik"
              orderFormStatus={orderFormStatus}
              handleSubmit={handleKupTeraz}
            />
          </PaymentSummaryWrapper>
        </BuyNowBox>
      </Grid>
    </>
  );
};

import styled from '@emotion/styled';
import { ReactComponent as SpinnerIcon } from 'assets/spinner.svg';
import React, { FC } from 'react';
import { P } from '../atoms/typography/Typography';
import { FontSize } from '../../../styles/models';
import { OrderStatus } from '../../../models';
import { SectionContainer } from '../organisms/SectionContainer';
import { ErrorBanner } from '../organisms/ErrorMessage';

const H1 = styled.h1`
  font-size: 30px;
  margin: 20px 0 29px 0;
  font-weight: bold;
  text-align: center;
`;

const Spinner = styled(SpinnerIcon)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface ThankYouTemplateProps {
  orderStatus?: OrderStatus;
  changeOrderStatus: () => {};
  paymentPending: boolean;
}

const Loading = () => (
  <>
    <Spinner />
    <H1>Trwa przetwarzanie płatności</H1>
    <P size={FontSize.l}>Prosimy o chwilę cierpliwości...</P>
  </>
);

const TransactionPendingMessage: FC = () => (
  <>
    <H1>Oczekujemy na odpowiedź z Twojego banku.</H1>
    <P size={FontSize.l}>
      Możesz zamknąć tę stronę. Powiadomimy Cię kiedy transakcja zostanie zakończona
    </P>
  </>
);

export const ThankYouTemplate: FC<ThankYouTemplateProps> = ({
  orderStatus,
  changeOrderStatus,
  paymentPending,
}) => {
  let componentToRender = <Loading />;

  if (paymentPending) {
    componentToRender = <TransactionPendingMessage />;
  }

  if (orderStatus === OrderStatus.PAYMENT_ERROR) {
    componentToRender = (
      <ErrorBanner onClick={changeOrderStatus} message="Płatność nie powiodła się" />
    );
  }

  return <SectionContainer>{componentToRender}</SectionContainer>;
};

import { AppState } from '../reducers/rootReducer';
import { DeliveryMethodsState } from '../reducers/delivery-methods/deliveryMethodsReducer';
import { DeliveryMethod } from '../../models';

const selectDeliveryMethodsState = (state: AppState): DeliveryMethodsState => state.deliveryMethods;
export const selectDeliveryMethods = (state: AppState): DeliveryMethod[] =>
  selectDeliveryMethodsState(state).deliveryMethods;

export const selectSelectedDeliveryMethod = (state: AppState): DeliveryMethod | undefined =>
  selectDeliveryMethodsState(state).deliveryMethods.find(
    (method) => method.id === state.order.deliveryMethod?.deliveryMethodId
  );

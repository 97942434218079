import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flexbox } from '../atoms/FlexContainer';
import { Button } from '../atoms/buttons/Button';
import { IconWrapper } from '../../../utils/IconWrapper';
import { ReactComponent as Edit } from '../../../assets/edit.svg';

const ButtonMethodViewInner = styled(Flexbox)`
  flex: 1 0 auto;
  gap: 10px;
  color: ${({ theme }) => theme.colors.fontMain};
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const MethodDetails = styled(Flexbox)`
  gap: 20px;
  font-size: 14px;
  line-height: 1.5;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    gap: 30px;
  }
`;

const MethodImageWithName = styled(MethodDetails)`
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    min-width: 253px;
  }
`;

const AdditionalInfoSlot = styled(Flexbox)`
  flex: 1;
`;

const EditButton = styled(Button)`
  position: absolute;
  width: auto;
  bottom: 8px;
  right: 0;
  display: inline-flex;
  align-items: center;
  flex: 0;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    position: static;
  }
`;

type ButtonMethodViewProps = {
  name: string;
  editable?: boolean;
  isTitle?: boolean;
  onEdit?: () => void;
  iconSlot?: React.ReactNode;
  additionalSlot?: React.ReactNode;
};
export const ButtonMethodView: FC<ButtonMethodViewProps> = ({
  iconSlot,
  isTitle = false,
  name,
  additionalSlot,
  editable = false,
  onEdit,
}) => (
  <ButtonMethodViewInner direction="column">
    <MethodDetails alignItems="center">
      <MethodImageWithName alignItems="center">
        {iconSlot}
        <span>{name}</span>
      </MethodImageWithName>
    </MethodDetails>
    {additionalSlot && (
      <AdditionalInfoSlot justifyContent="space-between">{additionalSlot}</AdditionalInfoSlot>
    )}
    {editable && !isTitle && (
      <EditButton size="small" borderOnly onClick={onEdit}>
        <IconWrapper Icon={Edit} />
        Edytuj
      </EditButton>
    )}
  </ButtonMethodViewInner>
);

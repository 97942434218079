import React, { useState } from 'react';
import { ReactComponent as IconArrow } from 'assets/arrow.svg';
import styled from '@emotion/styled';

const Container = styled.div<{ isHigherElement: boolean }>`
  width: 100%;
  position: relative;
  user-select: none;

  &.c-spr-form-select--higher {
    .selectWrp {
      .label {
        padding: 15px 16px;
      }
      .arrow {
        width: 47px;
        height: 46px;
        padding: 18px 16px;
      }
    }
    .dropdown {
      top: 48px;
    }
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const Label = styled.div<{ isDropdownShown: boolean; isImportant: boolean; cPlaceholder: boolean }>`
  padding: 13px 12px 13px 16px;
  border: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
  height: 48px;
  background: white;
  line-height: 20px;
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${(props) => (props.isImportant ? 'bold' : 'normal')};

  border-radius: ${(props) => (props.isDropdownShown ? '5px 5px 0 0' : '5px')};
  color: ${(props) => (props.cPlaceholder ? '#999' : 'inherit')};
`;

const Arrow = styled.div<{ isDropdownShown: boolean }>`
  position: absolute;
  top: 1px;
  right: 1px;
  width: 48px;
  height: 46px;
  padding: 13px;
  box-sizing: border-box;
  border-left: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
  border-radius: 0 5px 5px 0;
  background: var(--Gradient-Light-Gray, linear-gradient(180deg, #fff 6.37%, #f6f9fa 87.82%));
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: ${(props) => (props.isDropdownShown ? 'rotate(180deg)' : 'none')};
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 10;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;

  li {
    padding: 11px 17px 9px;
    border-left: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
    border-right: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
    border-bottom: 1px solid ${({ theme }) => theme.colors.sectionBorderColor};
    cursor: pointer;

    &:last-child {
      padding-bottom: 11px;
      border-radius: 0 0 5px 5px;
    }

    &:hover {
      background: #007bff;
      color: white;
    }
  }
`;

interface SelectedItem {
  label: string;
  value: any;
}

export interface SelectProps {
  name?: string;
  selectLabel?: string;
  isImportant?: boolean;
  selectedItems: SelectedItem[];
  isHigherElement?: boolean;
  onSelectOption?: (option: SelectedItem & { name?: string }) => void;
}

export const Select: React.FC<SelectProps> = ({
  name = '',
  selectLabel = '',
  isImportant = false,
  selectedItems = [],
  isHigherElement = false,
  onSelectOption,
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [cPlaceholder, setCPlaceholder] = useState(false);

  const handleShowDropdown = () => {
    setIsDropdownShown(!isDropdownShown);
  };

  const handleClickOption = (element: SelectedItem) => {
    setCPlaceholder(false);
    setIsDropdownShown(false);
    if (name && onSelectOption) {
      onSelectOption({ name, ...element });
    } else if (onSelectOption) {
      onSelectOption(element);
    }
  };

  const handleFocusOut = () => {
    setIsDropdownShown(false);
  };

  return (
    <Container
      className="c-spr-form-select"
      isHigherElement={isHigherElement}
      tabIndex={0}
      onBlur={handleFocusOut}
    >
      <SelectWrapper className="selectWrp" onClick={handleShowDropdown}>
        <Label
          className="label"
          isDropdownShown={isDropdownShown}
          isImportant={isImportant}
          cPlaceholder={cPlaceholder}
        >
          {selectLabel}
        </Label>
        <Arrow className="arrow" isDropdownShown={isDropdownShown}>
          <IconArrow />
        </Arrow>
      </SelectWrapper>
      {isDropdownShown && (
        <Dropdown className="dropdown">
          {selectedItems.map((element) => (
            <li key={element.label} onClick={() => handleClickOption(element)}>
              {element.label}
            </li>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default Select;

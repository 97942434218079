// eslint-disable-next-line
export enum OrderStatus {
  OPEN = 'open',
  CREATED = 'created',
  FINALIZED = 'finalized',
  PAYMENT_STARTED = 'payment_started',
  PAYMENT_RENEW = 'payment_renew',
  PAYMENT_NOT_STARTED = 'payment_not_started',
  PAYMENT_SUCCESS = 'payment_success',
  PAYMENT_PENDING = 'payment_pending',
  PAYMENT_ERROR = 'payment_error',
  TRACKING_NUMBER_CREATED = 'tracking_number_created',
  WAITING_FOR_DELIVERY = 'waiting_for_delivery',
}

import { call, takeLatest, put, select, take } from 'redux-saga/effects';
import {
  getPaymentMethodsAction,
  getPaymentMethodsSuccessAction,
} from 'store/actions/PaymentActions';
import { selectOrderId } from '../../selectors';
import { PaymentMethod } from '../../../models/data/PaymentMethod';
import { getPaymentMethods } from '../../../api/order/OrderPaymentMethodClient';
import { getOrderSuccessAction } from '../../actions/OrderActions';

function* getPaymentMethodsSaga() {
  yield take(getOrderSuccessAction);
  const orderId: number = yield select(selectOrderId);
  const paymentMethods: PaymentMethod[] = yield call(getPaymentMethods, orderId);
  yield put(getPaymentMethodsSuccessAction(paymentMethods));
}

export function* paymentMethodsSaga() {
  yield takeLatest(getPaymentMethodsAction.type, getPaymentMethodsSaga);
}

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flexbox } from '../atoms/FlexContainer';

const ErrorMessageContainer = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};
  width: 80%;
  padding: 40px;
  margin-top: 50px;
`;

const UnorderedList = styled.ul`
  list-style: circle;
`;

export interface ErrorMessageProps {
  messages: string[];
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ messages }) =>
  messages.length ? (
    <Flexbox justifyContent="center">
      <ErrorMessageContainer>
        <UnorderedList>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </UnorderedList>
      </ErrorMessageContainer>
    </Flexbox>
  ) : null;

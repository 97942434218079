import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Flexbox } from '../atoms/FlexContainer';
import { FCWithChildren } from '../../../utils/types/FC';

const Section = styled(Flexbox)`
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    height: 255px;
    width: 80%;
    border: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const Container = styled(Flexbox)`
  margin-top: 100px;
`;

export const SectionContainer: FCWithChildren = ({ children }) => (
  <Container justifyContent="center">
    <Section alignItems="center" justifyContent="center">
      <Flexbox direction="column" alignItems="center">
        {children}
      </Flexbox>
    </Section>
  </Container>
);

import { Saga } from '@redux-saga/types';
import { deliveryMethodsSaga } from './delivery-methods/deliveryMethodsSaga';
import { userAddressesSaga } from './user-addresses/userAddressesSaga';
import { orderSaga as orderSagaFn } from './order/orderSaga';
import { paymentMethodsSaga } from './payment-methods/paymentMethods';
import { paymentSaga } from './payment/paymentSaga';
import { runSagas } from '../../utils/saga/runSagas';
import { cartSaga } from './cart/cartSaga';

export function* rootSaga() {
  const sagas: Saga[] = [
    orderSagaFn,
    deliveryMethodsSaga,
    userAddressesSaga,
    paymentMethodsSaga,
    paymentSaga,
    cartSaga,
  ];

  yield runSagas(sagas);
}

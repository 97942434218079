import { Order } from '../../models/data/Order';
import { getEnvs } from '../../utils/env';
import { get, put } from '../api';
import { OrderStatus } from '../../models';

export const ORDER_STATUS_URL = 'order/$orderId/status';
export const RENEW_ORDER_STATUS_URL = 'order/$orderId/payment-renew';

export const getOrderStatus = async (orderId: Order['id']): Promise<{ status: OrderStatus }> => {
  const response = await get<{ status: OrderStatus }>(
    `${getEnvs().ORDER_BASE_URL}/${ORDER_STATUS_URL.replace('$orderId', orderId)}`
  );
  return response.data;
};

export const renewOrderStatus = async (orderId: Order['id']): Promise<void> => {
  await put(`${getEnvs().ORDER_BASE_URL}/${RENEW_ORDER_STATUS_URL.replace('$orderId', orderId)}`);
};

import { memoize, partialRight } from 'lodash';

/**
 * Custom memoize that uses a 1 minute TTL
 * @see https://lodash.com/docs/4.17.15#memoize
 */
const memo = partialRight(memoize, (...args) => {
  // or for one hour: (new Date()).getHours();
  const time = new Date().getMinutes();

  args.push({ time });

  const cacheKey = JSON.stringify(args);

  return cacheKey;
});

// because we make a new memoize, lets just pretend it’s the original
export default memo as typeof memoize;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/formatCurrency';
import { useTheme } from '@emotion/react';
import { Flexbox } from '../atoms/FlexContainer';
import { Section } from '../atoms/Section';
import { PaymentStatus } from '../../../models/data/PaymentStatus';
import { DeliveryMethod } from '../../../models';
import { Hide, Show as StyledShow } from '../atoms/Visibility';
import { P as StyledP } from '../atoms/typography/Typography';
import { assertUnreachable } from '../../../utils/assertUnreachable';
import { Product } from '../../../models/data/Product';
import { calculateOrderValue } from '../../../utils/orderValue/orderValue';
import { SellerTitle } from '../molecules/SellerTitle';
import { Seller } from '../../../models/data/Order';
import { userUrl } from '../../../utils/userUrl';
import { PostPaymentSummary } from './PostPaymentSummary';
import { ProductCard } from '../molecules/ProductCard';

const ProductSection = styled(Section)`
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    width: 923px;
  }
`;

const SellerWrapper = styled.div`
  margin-bottom: 20px;
`;

const ProductCardWrapper = styled.div``;

export interface ProductSummaryCardProps {
  products: Product[];
  seller: Seller;
  paymentStatus: PaymentStatus;
  deliveryMethod: DeliveryMethod;
  commission: number;
}

export const ProductsSummaryTile: FC<ProductSummaryCardProps> = ({
  products,
  deliveryMethod,
  commission,
  seller,
}) => {
  return (
    <ProductSection>
      <SellerWrapper>
        <SellerTitle sellerName={seller.name} urlMoreOffer={userUrl(seller.sprzedajemyUserId)} />
      </SellerWrapper>
      <ProductCardWrapper>
        {products.map((product) => (
          <ProductCard
            simple
            productId={product.id}
            productUrl={product.url}
            photoUrl={product.mainImg}
            photoAlt={product.photoAlt}
            title={product.name}
            price={product.price}
            amount={product.amount}
          />
        ))}
        <PostPaymentSummary
          deliveryPrice={deliveryMethod.price}
          products={products}
          commissionPercentage={commission}
        />
      </ProductCardWrapper>
    </ProductSection>
  );
};

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/formatCurrency';
import { Flexbox } from '../atoms/FlexContainer';
import { Product } from '../../../models/data/Product';
import { Strong } from '../atoms/Text';
import { Button, ButtonVariant } from '../atoms/buttons/Button';
import {
  calculateCommission,
  calculateOrderValue,
  calculateProductsSum,
  priceFormat,
} from 'utils/orderValue/orderValue';
import { FormStatus } from '../../../models/data/Form';
import { ReactComponent as Lock } from 'assets/lock.svg';
import { TooltipCommission } from '../atoms/TooltipCommision';

const PaymentSummaryContainer = styled.div`
  width: 100%;
  min-height: 50px;
  padding: 27px 0 0;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  border-bottom: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  font-family: Arial, serif;
  background-color: ${({ theme }) => theme.colors.white};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    border-radius: 5px;
    padding-top: 28px;
    border: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 389px;
  }
`;

const SummaryWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.highlight};
  padding-bottom: 38px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
    margin-top: 29px;
    border-radius: 0 0 5px 5px;
  }
`;

const Row = styled(Flexbox)`
  margin-bottom: 14px;
  padding: 0 17px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    padding: 0 29px;
  }
  p {
    margin: 0;
  }
  > strong {
    margin-left: 4px;
  }
`;

const ToPay = styled.h2`
  margin-top: 0;
  margin-bottom: 7px;
  font-size: ${({ theme }) => theme.font.xl};
`;

const PriceSummary = styled.div`
  height: 88px;
  width: 100%;
  margin-top: 29px;
  padding-top: 37px;
  box-sizing: border-box;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    height: 83px;
    margin-top: 0;
    border: none;
  }
`;

export const PriceStrong = styled(Strong)`
  font-size: 28px;
  line-height: 20px;
`;

const BuyNowButton = styled(Button)`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: bold;
  margin: 0 17px 22px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 5px;
    width: 327px;
  }
`;

const SummaryEncryption = styled(Flexbox)`
  gap: 5px;
  font-size: ${({ theme }) => theme.font.xs};
`;

export interface PaymentSummaryProps {
  products: Product[];
  deliveryName?: string;
  deliveryPrice?: number;
  handleSubmit: () => void;
  commissionPercentage: number;
  paymentMethodName?: string;
  orderFormStatus: FormStatus;
}

export const PaymentSummary: FC<PaymentSummaryProps> = ({
  products,
  deliveryName,
  deliveryPrice,
  handleSubmit,
  commissionPercentage,
  paymentMethodName,
  orderFormStatus,
}) => {
  const calculatedCommission = calculateCommission(products, commissionPercentage);
  const productsPrice = calculateProductsSum(products).toNumber();
  return (
    <PaymentSummaryContainer>
      <Row>
        <ToPay>
          <Strong>Podsumowanie</Strong>
        </ToPay>
      </Row>
      <Row justifyContent="space-between">
        <p>Wartość produktów</p>
        <p>
          <Strong>{priceFormat(productsPrice)}</Strong>
        </p>
      </Row>
      <Row justifyContent="space-between" alignItems="center">
        <Flexbox alignItems="center">
          <p>Opłata transakcyjna</p>
          <TooltipCommission />
        </Flexbox>
        <p>
          <Strong>{priceFormat(calculatedCommission.toNumber())}</Strong>
        </p>
      </Row>
      <Row justifyContent="space-between">
        <p>Dostawa: {deliveryName && <strong>{deliveryName}</strong>}</p>
        <p>
          <Strong>{deliveryPrice ? priceFormat(deliveryPrice) : '-'}</Strong>
        </p>
      </Row>

      <Row justifyContent="space-between">
        <p>Forma płatności: {paymentMethodName && <strong>{paymentMethodName}</strong>}</p>
      </Row>
      <SummaryWrapper>
        <PriceSummary>
          <Row justifyContent="space-between" alignItems="flex-end">
            <p>Do zapłaty</p>
            <p>
              <PriceStrong>
                {formatCurrency(calculateOrderValue(products, commissionPercentage, deliveryPrice))}
              </PriceStrong>
            </p>
          </Row>
        </PriceSummary>
        <Flexbox justifyContent="center">
          <BuyNowButton
            onClick={handleSubmit}
            variant={ButtonVariant.primary}
            size="big"
            disabled={orderFormStatus === FormStatus.loading}
          >
            KUPUJĘ I PŁACĘ
          </BuyNowButton>
        </Flexbox>
        <SummaryEncryption justifyContent="center" alignItems="flex-end">
          <Lock />
          <span>Twoje dane są szyfrowane. Kupujesz bezpiecznie.</span>
        </SummaryEncryption>
      </SummaryWrapper>
    </PaymentSummaryContainer>
  );
};

import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Flexbox } from '../atoms/FlexContainer';
import { Toggle } from '../atoms/Toggle';
import { Switch } from '../atoms/Switch';

const HeadingText = styled.p`
  font-size: ${({ theme }) => theme.font.m};
  min-width: 235px;
  font-weight: 600;
`;

const ToggleWrapper = styled(Flexbox)<{ toggle?: boolean; smallerMargin: boolean }>`
  height: 32px;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    align-items: center;
    margin-top: 0;
  }
`;

const HeadingSectionWrapper = styled(Flexbox)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;

const CopyAddress = styled.span`
  position: relative;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.linkColor};
  margin-top: 20px;
  cursor: pointer;
  user-select: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    align-items: center;
    margin-left: 100px;
    margin-top: 0;
    width: 220px;
  }
`;

export interface SectionHeadingWithToggleProps {
  toggleOn: boolean;
  handleToggle: (toggled: boolean) => void;
  text: string;
  icon: ReactNode;
  handleCopyAddress?: () => void;
}

export const SectionHeadingWithToggle: FC<SectionHeadingWithToggleProps> = ({
  toggleOn,
  handleToggle,
  text,
  icon,
  handleCopyAddress,
}) => (
  <HeadingSectionWrapper justifyContent="space-between" alignItems="center">
    <Flexbox alignItems="center">
      {icon}
      <HeadingText>{text}</HeadingText>
    </Flexbox>
    <ToggleWrapper justifyContent="center" smallerMargin={!!handleCopyAddress}>
      <Switch checked={toggleOn} onChange={(e) => handleToggle(e.target.checked)} />
    </ToggleWrapper>
    {handleCopyAddress && (
      <Flexbox justifyContent="center">
        <CopyAddress onClick={handleCopyAddress}> Skopiuj z adresu dostawy</CopyAddress>
      </Flexbox>
    )}
  </HeadingSectionWrapper>
);

import React, { FC, useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Provider, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';
import { createAppStore, history } from './store';
import GlobalStyles from './styles/Global';
import theme from './styles/theme';
import { KupTerazPage } from './components/UI/pages/KupTerazPage';
import { ThankYouPage } from './components/UI/pages/ThankYouPage';
import { OrderSummaryPage } from './components/UI/pages/OrderSummaryPage';
import { Maybe } from './utils/maybe';
import { InternalServerErrorTemplate } from './components/UI/templates/InternalServerErrorTemplate';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { AppState } from './store/reducers/rootReducer';
import { CartPage } from './components/UI/pages/CartPage';
import { selectPathname } from './store/selectors/reduxRouterSelectors';

export interface AppProps {
  handleUnauthorized: (href: string) => void;
  handleForbidden: (href: string) => void;
}

const Router = () => (
  <Switch>
    <Route path="/404">
      <div>404</div>
    </Route>
    <Route path="/order/:id/thank-you">
      <ThankYouPage />
    </Route>
    <Route path="/order/:id">
      <KupTerazPage />
    </Route>
    <Route path="/order-summary/:id">
      <OrderSummaryPage />
    </Route>
    <Route path="/cart">
      <CartPage />
    </Route>
    <Route path="/">
      <Redirect to="/404" />
    </Route>
  </Switch>
);

const muiTheme = createTheme();

export const App: FC<AppProps> = ({ handleForbidden, handleUnauthorized }) => {
  const [store, setStore] = useState<Maybe<Store<AppState>>>();
  useEffect(() => {
    setStore(
      createAppStore<AppProps>({ handleForbidden, handleUnauthorized })
    );
  }, []);

  if (!store) {
    return null;
  }
  // TODO: po updacie geo-widgetu dodac react strict mode
  return (
    <>
      <div id="popup" />
      <div id="geo-popup" />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              {!store?.getState().errors.showInternalServerError ? (
                <Router />
              ) : (
                <InternalServerErrorTemplate />
              )}
            </ThemeProvider>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    </>
  );
};

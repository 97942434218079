import { createAction } from '@reduxjs/toolkit';
import { DeliveryMethod } from '../../models';

export const GET_DELIVERY_METHODS = 'GET_DELIVERY_METHODS';
export const GET_DELIVERY_METHODS_SUCCESS = 'GET_DELIVERY_METHODS_SUCCESS';

export const getDeliveryMethodsAction = createAction(GET_DELIVERY_METHODS);
export const getDeliveryMethodsSuccessAction = createAction(
  GET_DELIVERY_METHODS_SUCCESS,
  (deliveryMethods: DeliveryMethod[]) => ({ payload: { deliveryMethods } })
);

import { FormikProps } from 'formik/dist/types';
import { ChangeEvent } from 'react';

export const createHandlePostalCode = <T extends { postcode: string }>(formik: FormikProps<T>) => {
  // eslint-disable-next-line
  const handlePostal = (e: ChangeEvent<any>) => {
    const { value } = e.target;

    const normalizedValue = value.replace(/\D/g, '');
    let formattedValue = normalizedValue;
    if (normalizedValue.length > 2) {
      formattedValue = `${normalizedValue.slice(0, 2)}-${normalizedValue.slice(2, 5)}`;
    }

    formik.setFieldValue('postcode', formattedValue);
  };
  return handlePostal;
};

import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { SchemaOf, object, string } from 'yup';
import styled from '@emotion/styled';
import { postCodeRegExp } from 'utils/regex';
import { DeliveryAddress, InvoiceData } from 'models';
import { Flexbox } from 'components/UI/atoms/FlexContainer';
import { SaveCancelButtons } from '../molecules/SaveCancelButtons';
import { FormContainer } from '../atoms/form/Form';
import { FormInputField, Label } from '../atoms/form/FormInputField';
import { isNipValid } from '../../../utils/isNipValid';
import { FormState } from '../../../store/reducers/forms/formsReducer';
import { createHandlePostalCode } from '../../../utils/form/handlePostalCode';
import Select from '../atoms/Select';

const initialValues: InvoiceData = {
  country: 'PL',
  houseNumber: '',
  street: '',
  city: '',
  postcode: '',
  companyName: '',
  nip: '',
};

const validationSchema: SchemaOf<InvoiceData> = object().shape({
  street: string().required('Musisz podać ulicę'),
  city: string().required('Musisz podać miasto'),
  postcode: string()
    .matches(postCodeRegExp, 'Niepoprawny format kodu pocztowego')
    .required('Musisz podać kod pocztowy'),
  companyName: string().required('Musisz podać nazwę firmy'),
  nip: string()
    .required('Musisz podać NIP')
    .test('correct-nip', 'Niepoprawny numer NIP', (nip) => !!nip && isNipValid(nip)),
  houseNumber: string().required('Numer domu jest wymagany'),
  apartmentNumber: string().nullable(),
  country: string().required('Kraj jest wymagany'),
});

const InputContainer = styled(Flexbox)`
  margin-bottom: 16px;
`;

export interface InvoiceFormProps {
  handleClose: () => void;
  checkoutInitiated?: boolean;
  handleFormSubmission: (invoiceData: InvoiceData) => void;
  initialInvoiceDataValues?: InvoiceData;
  addressToCopyFrom?: DeliveryAddress;
  formErrors?: FormState<InvoiceData>['errors'];
}

export const InvoiceForm: FC<InvoiceFormProps> = ({
  handleClose,
  checkoutInitiated,
  handleFormSubmission,
  initialInvoiceDataValues,
  addressToCopyFrom,
  formErrors,
}) => {
  const formik = useFormik<InvoiceData>({
    initialValues: initialInvoiceDataValues || initialValues,
    validationSchema,
    onSubmit(invoiceData: InvoiceData) {
      handleFormSubmission(invoiceData);
    },
  });

  useEffect(() => {
    if (checkoutInitiated) formik.validateForm();
  }, [checkoutInitiated]);

  useEffect(() => {
    if (addressToCopyFrom) {
      formik.setValues({
        city: addressToCopyFrom.city,
        companyName: addressToCopyFrom.companyName || '',
        nip: '',
        postcode: addressToCopyFrom.postcode,
        street: addressToCopyFrom.street,
        houseNumber: addressToCopyFrom.houseNumber,
        apartmentNumber: addressToCopyFrom.apartmentNumber,
        country: 'PL',
      });
    }
  }, [addressToCopyFrom]);

  const handlePostal = createHandlePostalCode(formik);

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <InputContainer direction="column" alignItems="flex-start" id="companyName">
        <FormInputField
          labelText="Nazwa firmy"
          name="companyName"
          placeholder="Podaj nazwę firmy"
          value={formik.values.companyName}
          handleChange={formik.handleChange}
          errorMsg={
            (formik.touched.companyName && formik.errors.companyName) || formErrors?.companyName
          }
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="nip">
        <FormInputField
          labelText="NIP"
          name="nip"
          placeholder="Podaj NIP"
          value={formik.values.nip}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.nip && formik.errors.nip) || formErrors?.nip}
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="street">
        <FormInputField
          labelText="Ulica"
          name="street"
          placeholder="Podaj ulicę"
          value={formik.values.street}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.street && formik.errors.street) || formErrors?.street}
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="houseNumber">
        <FormInputField
          labelText="Nr domu"
          name="houseNumber"
          placeholder="Nr domu"
          value={formik.values.houseNumber}
          handleChange={formik.handleChange}
          errorMsg={
            (formik.touched.houseNumber && formik.errors.houseNumber) || formErrors?.houseNumber
          }
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="apartmentNumber">
        <FormInputField
          labelText="Nr lokalu (opcjonalnie)"
          name="apartmentNumber"
          placeholder="Nr lokalu"
          value={formik.values.apartmentNumber ?? undefined}
          handleChange={formik.handleChange}
          errorMsg={
            (formik.touched.apartmentNumber && formik.errors.apartmentNumber) ||
            formErrors?.apartmentNumber
          }
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="postcode">
        <FormInputField
          labelText="Kod pocztowy"
          name="postcode"
          placeholder="xx-xxx"
          value={formik.values.postcode}
          handleChange={handlePostal}
          errorMsg={(formik.touched.postcode && formik.errors.postcode) || formErrors?.postcode}
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="city">
        <FormInputField
          labelText="Miejscowość"
          name="city"
          placeholder="Podaj miejscowość"
          value={formik.values.city}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.city && formik.errors.city) || formErrors?.city}
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="country">
        <Label htmlFor={'country'}>{'Kraj'}</Label>
        <Select
          selectedItems={[{ label: 'Polska', value: 'PL' }]}
          onSelectOption={({ value }) => formik.setFieldValue('country', value)}
          selectLabel="Polska"
        />
      </InputContainer>
      <SaveCancelButtons handleCancel={handleClose} />
    </FormContainer>
  );
};

import React, { FC } from 'react';
import { Global as GlobalStyles, css, useTheme } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { useSelector } from 'react-redux';
import { selectShowInpostGeowidget } from '../store/selectors/utilsSelectors';

export const Global: FC = () => {
  const theme = useTheme();
  const showGeoPopup = useSelector(selectShowInpostGeowidget);

  return (
    <GlobalStyles
      styles={css`
        ${emotionReset}
        *, *::after, *::before {
          box-sizing: border-box;
        }

        html {
          scroll-behavior: smooth;
        }

        body {
          min-height: 100vh;
          font-family: Arial, serif;
          color: ${theme.colors.fontMain};
          background-color: ${theme.colors.highlight};
          min-height: 1200px;
          ${showGeoPopup && 'overflow: hidden !important;'}
        }

        #geo-popup * {
          visibility: ${showGeoPopup ? 'visible' : 'hidden'} !important;
        }

        p {
          font-size: 0.875rem;
        }
      `}
    />
  );
};

export default Global;

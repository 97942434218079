export interface Gradient {
  primary: string;
  secondary: string;
}

export enum FontSize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
}

import React, { FC } from 'react';
import { useFormik } from 'formik';
import { SchemaOf, object, string } from 'yup';
import styled from '@emotion/styled';
import { InputContainer as StyledInputContainer, TextArea } from 'components/UI/atoms/form/Input';
import { Flexbox } from 'components/UI/atoms/FlexContainer';
import { SaveCancelButtons } from '../molecules/SaveCancelButtons';
import { NotesData } from '../../../models/data/NotesData';
import { FormContainer as StyledFormContainer } from '../atoms/form/Form';
import { InputValidationTooltip } from '../atoms/form/InputValidationTooltip';
import { FormState } from '../../../store/reducers/forms/formsReducer';

const validationSchema: SchemaOf<NotesData> = object().shape({
  notes: string()
    .required('Musisz wpisać treść notatki')
    .min(7, 'Minimum 7 znaków')
    .max(1200, 'Maksimum 1200 znaków'),
});

const InputContainer = styled(StyledInputContainer)`
  margin-bottom: 16px;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletBig}) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

export interface NotesFormProps {
  handleClose: () => void;
  handleFormSubmission: (remarks: string) => void;
  formErrors: FormState<NotesData>['errors'];
  notes?: string;
}

export const NotesForm: FC<NotesFormProps> = ({
  handleClose,
  handleFormSubmission,
  formErrors,
  notes,
}) => {
  const formik = useFormik<NotesData>({
    initialValues: { notes: notes || '' },
    validationSchema,
    onSubmit(values: NotesData) {
      handleFormSubmission(values.notes);
    },
  });
  return (
    <StyledFormContainer onSubmit={formik.handleSubmit}>
      <InputContainer direction="column" hasError={!!formik.errors.notes && formik.touched.notes}>
        <InputValidationTooltip
          textarea
          errorMsg={(formik.touched.notes && formik.errors.notes) || formErrors?.notes}
          render={(onFocus, onBlur, hasError) => (
            <TextArea
              id="notes"
              name="notes"
              onChange={formik.handleChange}
              value={formik.values.notes}
              placeholder="Wpisz swoje uwagi do tego zamówienia..."
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        />
      </InputContainer>
      <SaveCancelButtons handleCancel={handleClose} />
    </StyledFormContainer>
  );
};

import axios from 'axios';
import { getEnvs } from 'utils/env';
import { Order } from '../../models/data/Order';
import { put } from '../api';
import { OrderValidationError } from '../../models/errors/OrderValidationError';
import { PaymentFinalizeResponse } from '../../models/data/PaymentFinalizeResponse';

const ORDER_URL = '/order/$orderId';
const ORDER_SUBMIT_URL = '/order/$orderId/finalize';
const ORDER_CANCEL_URL = '/order/$id/cancel';

export const getOrder = async (orderId: string): Promise<Order> => {
  const { data } = await axios.get<Order>(
    `${getEnvs().ORDER_BASE_URL}${ORDER_URL.replace('$orderId', orderId)}`
  );
  return data;
};

export const submitOrder = async (
  orderId: string,
  orderValue: string
): Promise<PaymentFinalizeResponse> => {
  try {
    const response = await put<{}, PaymentFinalizeResponse>(
      `${getEnvs().ORDER_BASE_URL}${ORDER_SUBMIT_URL.replace('$orderId', orderId)}`,
      {
        orderValue: parseFloat(orderValue),
      }
    );
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // TODO: INSERT CORRECT CODE HERE
      if (e?.response?.status === 422) {
        throw new OrderValidationError(e.response!.data.errors);
      }
    }
    throw e;
  }
};

export const cancelOrder = async (orderId: string): Promise<void> => {
  await axios.put(`${getEnvs().MERCHANT_BASE_URL}${ORDER_CANCEL_URL.replace('$id', orderId)}`);
};

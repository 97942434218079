import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import { DeliveryAddress, DeliveryMethodType, UserAddress } from '../../../models';
import {
  createUserAddressAction,
  createUserAddressSuccessAction,
  deleteUserAddressAction,
  deleteUserAddressSuccessAction,
  getDefaultUserAddressesAction,
  getDefaultUserAddressesSuccessAction,
  getUserAddressesAction,
  getUserAddressesSuccessAction,
  updateUserAddressAction,
  updateUserAddressSuccessAction,
} from '../../actions/UserAddressesActions';
import {
  createUserAddress,
  deleteUserAddress,
  getUserAddresses,
  updateUserAddress,
} from '../../../api';
import { selectBillingAddress, selectDeliveryAddress, selectUserId } from '../../selectors';
import { DefaultAddresses } from '../../../models/data/DefaultAddresses';
import { getDefaultAddresses } from '../../../api/user-addresses/DefaultAddressesClient';
import { User } from '../../../models/data/Order';
import {
  addOrderBillingAddressAction,
  addOrderDeliveryAddressAction,
  getOrderSuccessAction,
} from '../../actions/OrderActions';
import { getDeliveryMethodsSuccessAction } from '../../actions/DeliveryMethodsActions';
import { isMatch } from 'lodash';
import { selectUserAddresses } from '../../selectors/userAddressesSelectors';

function* getUserAddressesSaga() {
  yield take(getOrderSuccessAction);
  const userId: User['sprzedajemyUserId'] = yield select(selectUserId);
  if (!userId) {
    return;
  }
  const addresses: UserAddress[] = yield call(getUserAddresses, userId);
  yield put(getUserAddressesSuccessAction(addresses));
}

function* deleteUserAddressSaga(action: Action) {
  if (deleteUserAddressAction.match(action)) {
    const userId: User['sprzedajemyUserId'] = yield select(selectUserId);
    yield call(deleteUserAddress, action.payload.addressId, userId);
    yield put(deleteUserAddressSuccessAction(action.payload.addressId));
  }
}

function* updateUserAddressSaga(action: Action) {
  if (updateUserAddressAction.match(action)) {
    const userId: User['sprzedajemyUserId'] = yield select(selectUserId);
    if (!userId) {
      return;
    }
    const userAddresses: UserAddress[] = yield select(selectUserAddresses);
    yield call(
      updateUserAddress,
      action.payload.userAddress,
      action.payload.userAddress.id,
      userId
    );
    yield put(updateUserAddressSuccessAction(action.payload.userAddress));
    const userAddressBeforeEdition = userAddresses.find(
      (address) => address.id === action.payload.userAddress.id
    );
    const deliveryAddress: UserAddress | DeliveryAddress | undefined = yield select(
      selectDeliveryAddress
    );
    if (
      deliveryAddress &&
      userAddressBeforeEdition &&
      isMatch(userAddressBeforeEdition, deliveryAddress)
    ) {
      yield put(addOrderDeliveryAddressAction(action.payload.userAddress));
    }
  }
}

function* createUserAddressSaga(action: Action) {
  if (createUserAddressAction.match(action)) {
    const userId: User['sprzedajemyUserId'] = yield select(selectUserId);
    const address: UserAddress = yield call(createUserAddress, action.payload.address, userId);
    yield put(createUserAddressSuccessAction(address));
  }
}

function* getDefaultAddressesSaga() {
  let deliveryMethodsReceived = false;
  let orderSuccessReceived = false;
  while (true) {
    const action: Action = yield take([getDeliveryMethodsSuccessAction, getOrderSuccessAction]);
    if (getDeliveryMethodsSuccessAction.match(action)) {
      deliveryMethodsReceived = true;
    } else if (getOrderSuccessAction.match(action)) {
      orderSuccessReceived = true;
    }

    const userId: User['sprzedajemyUserId'] = yield select(selectUserId);

    if (deliveryMethodsReceived && orderSuccessReceived && userId) {
      const defaultAddresses: DefaultAddresses = yield getDefaultAddresses(userId);
      yield put(
        getDefaultUserAddressesSuccessAction({
          parcel: defaultAddresses[DeliveryMethodType.parcel],
          parcelLocker: defaultAddresses[DeliveryMethodType.parcelLocker],
        })
      );
      const orderBillingAddress: DeliveryAddress = yield select(selectBillingAddress);
      if (defaultAddresses[DeliveryMethodType.parcel] && !orderBillingAddress) {
        yield put(addOrderBillingAddressAction(defaultAddresses[DeliveryMethodType.parcel]));
      }
      return;
    }
  }
}

export function* userAddressesSaga() {
  yield takeLatest(getUserAddressesAction.type, getUserAddressesSaga);
  yield takeLatest(deleteUserAddressAction.type, deleteUserAddressSaga);
  yield takeLatest(updateUserAddressAction.type, updateUserAddressSaga);
  yield takeLatest(createUserAddressAction.type, createUserAddressSaga);
  yield takeLatest(getDefaultUserAddressesAction.type, getDefaultAddressesSaga);
}

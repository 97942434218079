import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PaymentStatus } from 'models/data/PaymentStatus';
import { Flexbox } from '../atoms/FlexContainer';
import { ProductsSummaryTile } from '../organisms/ProductsSummaryTile';
import { DeliveryMethod } from '../../../models';
import { Button as StyledButton, ButtonVariant } from '../atoms/buttons/Button';
import { OrderState } from '../../../store/reducers/order/orderReducer';
import { OrderSummaryHeader } from '../molecules/OrderSummaryHeader';
import { NoAccount } from '../molecules/NoAccount';
import { ReactComponent as Back } from 'assets/back.svg';
import { AddressSummary } from '../molecules/AddressSummary';
import { SummaryCard as Card } from '../atoms/SummaryCard';
import { PaymentMethodIcons } from '../atoms/PaymentMethodIcons';
import { PaymentMethod } from '../../../models/data/PaymentMethod';
import { DeliveryMethodIcon } from '../atoms/DeliveryMethodIcon';
import { InvoiceSummary } from '../molecules/InvoiceSummary';
import { Seller } from '../../../models/data/Order';

const OrderSummaryContainer = styled(Flexbox)`
  font-family: Arial, serif;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 924px;
  }
`;
const Button = styled(StyledButton)`
  width: calc(100% - 2 * 16px);
  margin: 50px auto;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 454px;
  }
`;

const SummaryTitle = styled.h3`
  font-size: ${({ theme }) => theme.font.m};
  margin-bottom: 15px;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 17px;
    grid-row-gap: 30px;
    margin-bottom: 30px;
  }
`;

const SummaryCard = styled(Card)`
  * {
    font-size: ${({ theme }) => theme.font.m};
    line-height: 21px;
  }
  }
`;

const SummaryCardContent = styled.div`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: 700;
  p {
    padding-bottom: 20px;
  }
`;

const AvatarButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 264px;
  text-decoration: none;
  color: white;
`;

export interface OrderSummaryTemplateProps {
  order: OrderState;
  paymentStatus: PaymentStatus;
  seller: Seller;
  redirectToHomepage: () => void;
  redirectToRegisterPage: () => void;
  deliveryMethod?: DeliveryMethod;
  paymentMethod?: PaymentMethod;
}

export const OrderSummaryTemplate: FC<OrderSummaryTemplateProps> = ({
  order,
  paymentStatus,
  deliveryMethod,
  paymentMethod,
  redirectToHomepage,
  redirectToRegisterPage,
  seller,
}) => {
  return (
    <Flexbox justifyContent="center">
      <OrderSummaryContainer direction="column" alignItems="center">
        <OrderSummaryHeader orderNumber={order.orderId} orderEmail={order?.buyer?.email} />
        <NoAccount
          redirectToRegisterPage={redirectToRegisterPage}
          orderEmail={order.buyer?.email || order?.billingAddress?.email || ''}
        />
        <SummaryGrid>
          <div>
            <SummaryTitle>Dane do rozliczeniowe</SummaryTitle>
            <SummaryCard>
              <AddressSummary address={order.billingAddress!} noMarginTop />
            </SummaryCard>
          </div>
          <div>
            <SummaryTitle>Dane do wysyłki</SummaryTitle>
            <SummaryCard>
              <AddressSummary address={order.deliveryAddress!} noMarginTop />
            </SummaryCard>
          </div>
          {order.invoiceData && (
            <div>
              <SummaryTitle>Faktura VAT</SummaryTitle>
              <SummaryCard>
                <InvoiceSummary invoiceData={order.invoiceData} hideMarginTop />
              </SummaryCard>
            </div>
          )}
          {order.notes && (
            <div>
              <SummaryTitle>Uwagi do zamówienia</SummaryTitle>
              <SummaryCard>
                <p>
                  <p>{order.notes}</p>
                </p>
              </SummaryCard>
            </div>
          )}
          {deliveryMethod && (
            <div>
              <SummaryTitle>Sposób dostawy</SummaryTitle>
              <SummaryCard>
                <SummaryCardContent>
                  <p>{deliveryMethod?.name}</p>
                  <DeliveryMethodIcon
                    deliveryMethod={`${deliveryMethod?.code}_${deliveryMethod?.type}`}
                  />
                </SummaryCardContent>
              </SummaryCard>
            </div>
          )}
          {paymentMethod && (
            <div>
              <SummaryTitle>Forma płatności</SummaryTitle>
              <SummaryCard>
                <SummaryCardContent>
                  <p>{paymentMethod?.name}</p>
                  <PaymentMethodIcons paymentMethod={order!.paymentMethod!.id!} />
                </SummaryCardContent>
              </SummaryCard>
            </div>
          )}
        </SummaryGrid>

        {deliveryMethod && (
          <ProductsSummaryTile
            seller={seller}
            products={order.products}
            paymentStatus={paymentStatus}
            deliveryMethod={deliveryMethod}
            commission={order.commission}
          />
        )}
        <Button size="middle" variant={ButtonVariant.secondary}>
          <AvatarButton onClick={redirectToHomepage}>
            <Back />
            <span>Wróć na stronę główną</span>
          </AvatarButton>
        </Button>
      </OrderSummaryContainer>
    </Flexbox>
  );
};

import { getEnvs } from '../env';

export const getCardPaymentConfig = (amount: number) => ({
  paymentMethodsConfiguration: {
    ideal: {
      showImage: true,
    },
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      name: 'Credit or debit card',
      amount: {
        value: amount * 100,
        currency: 'PLN',
      },
    },
  },
  locale: 'pl_PL',
  showPayButton: true,
  clientKey: getEnvs().PAYMENT_CLIENT_ID,
  environment: 'test',
});

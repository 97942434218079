import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IconWrapper } from 'utils/IconWrapper';
import { ExpandCollapse } from 'components/UI/molecules/ExpandCollapse';
import { DeliveryAddress, UserAddress } from 'models';
import { Flexbox } from 'components/UI/atoms/FlexContainer';
import { ReactComponent as AddressIcon } from 'assets/address.svg';
import { ReactComponent as DeliveryAddressIcon } from 'assets/delivery-address.svg';

import { DeliveryAddressForm } from './DeliveryAddressForm';
import { SavedDeliveryAddresses } from './SavedDeliveryAddresses';
import { FormState } from '../../../store/reducers/forms/formsReducer';
import { FormStatus } from '../../../models/data/Form';
import { SectionHeadingWithToggle } from '../molecules/SectionHeadingWithToggle';
import { usePrevious } from '../../../utils/usePrevious';
import { P } from '../atoms/typography/Typography';
import { AddressSummary } from '../molecules/AddressSummary';

const AddressH3 = styled.h3`
  padding-top: 4px;
  font-weight: 600;
`;

const CollapsedTitleWrapper = styled.div`
  width: 100%;
`;

const HeadingWrapper = styled(Flexbox)`
  gap: 20px;
  width: 313px;
`;

const billingAddress = (
  <HeadingWrapper direction="column">
    <Flexbox justifyContent="flex-start" alignItems="center">
      <IconWrapper Icon={AddressIcon} />
      <AddressH3>Dane zamawiającego</AddressH3>
    </Flexbox>
    <P>Domyślne dane do wysyłki / dane rozliczeniowe</P>
  </HeadingWrapper>
);

const Summary: FC<{ summaryText: string }> = ({ summaryText }) => (
  <Flexbox alignItems="center">
    <IconWrapper Icon={AddressIcon} />
    <AddressH3>{summaryText}</AddressH3>
  </Flexbox>
);

export interface DeliveryAddressSectionProps {
  selectedAddress?: DeliveryAddress;
  checkoutInitiated?: boolean;
  handleSubmit: (address: DeliveryAddress) => void;
  userAddresses: UserAddress[];
  deliveryAddressFormState?: FormState<DeliveryAddress>;
  handleAddOrderAddressId: (addressId: UserAddress['id']) => void;
  handleRemoveUserAddress: (addressId: UserAddress['id']) => void;
  handleEditUserAddress: (address: UserAddress) => void;
  handleAddUserAddress: (address: DeliveryAddress) => void;
  initialAddress: boolean;
  toggleStatus?: boolean;
  onToggle?: (status: boolean) => void;
}

export const DeliveryAddressSection: FC<DeliveryAddressSectionProps> = ({
  selectedAddress,
  checkoutInitiated,
  handleSubmit,
  userAddresses,
  deliveryAddressFormState,
  handleAddOrderAddressId,
  handleAddUserAddress,
  handleEditUserAddress,
  handleRemoveUserAddress,
  initialAddress,
  toggleStatus,
  onToggle,
}) => {
  const hasSavedAddresses = !!userAddresses?.length;
  const previousToggleStatus = usePrevious(toggleStatus);

  const [showForm, setShowForm] = useState(!selectedAddress && !hasSavedAddresses && !onToggle);
  const [showAddressPopup, setShowAddressPopup] = useState(
    initialAddress && !selectedAddress && !!userAddresses && !!userAddresses.length
  );

  useEffect(() => {
    if (deliveryAddressFormState?.status === FormStatus.submitted) {
      setShowForm(false);
    }
  }, [deliveryAddressFormState]);

  useEffect(() => {
    if (previousToggleStatus === toggleStatus) {
      return;
    }

    if (toggleStatus !== undefined && previousToggleStatus !== undefined) {
      setShowForm(toggleStatus);
    }
  }, [toggleStatus]);

  const handleAddressIdSelection = (id: UserAddress['id']) => {
    handleAddOrderAddressId(id);
    setShowAddressPopup(false);
  };

  let collapsedSummary = null;

  if (onToggle && !selectedAddress) {
    collapsedSummary = (
      <SectionHeadingWithToggle
        text={'Inny adres do wysyłki'}
        handleToggle={onToggle}
        toggleOn={!!toggleStatus}
        icon={<IconWrapper Icon={DeliveryAddressIcon} />}
      />
    );
  } else if (onToggle && selectedAddress) {
    collapsedSummary = (
      <CollapsedTitleWrapper>
        <SectionHeadingWithToggle
          text={'Inny adres do wysyłki'}
          handleToggle={onToggle}
          toggleOn={!!toggleStatus}
          icon={<IconWrapper Icon={DeliveryAddressIcon} />}
        />
        {toggleStatus && <AddressSummary address={selectedAddress} hasToggle />}
      </CollapsedTitleWrapper>
    );
  } else if (selectedAddress) {
    collapsedSummary = (
      <AddressSummary
        summaryText={'Dane zamawiającego'}
        description="Domyślne dane do wysyłki / dane rozliczeniowe"
        address={selectedAddress}
      />
    );
  } else {
    collapsedSummary = billingAddress;
  }

  let expandedSummary = null;

  if (onToggle) {
    expandedSummary = (
      <SectionHeadingWithToggle
        text={'Inny adres do wysyłki'}
        handleToggle={onToggle}
        toggleOn={!!toggleStatus}
        icon={<IconWrapper Icon={DeliveryAddressIcon} />}
      />
    );
  } else {
    expandedSummary = <Summary summaryText={'Dane zamawiającego'} />;
  }

  return (
    <>
      <ExpandCollapse
        expand={showForm}
        collapsedTitle={collapsedSummary}
        expandedTitle={expandedSummary}
        hideEditButton={toggleStatus === false}
        hasToggle={onToggle !== undefined}
        handleExpand={() => setShowForm(true)}
      >
        {hasSavedAddresses && onToggle ? (
          <SavedDeliveryAddresses
            handleAddressDeletion={handleRemoveUserAddress}
            handleAddressAddition={handleAddUserAddress}
            handleAddressEdition={handleEditUserAddress}
            handleAddressSelection={handleAddressIdSelection}
            handlePopupClose={() => {
              setShowAddressPopup(false);
            }}
            addresses={userAddresses}
            show={showAddressPopup}
            savedAddress={selectedAddress}
          />
        ) : (
          <DeliveryAddressForm
            handleClose={() => setShowForm(false)}
            checkoutInitiated={checkoutInitiated}
            handleSubmit={handleSubmit}
            initialAddressValues={selectedAddress}
            formErrors={deliveryAddressFormState?.errors}
          />
        )}
      </ExpandCollapse>
    </>
  );
};

import styled from '@emotion/styled';
import React, { ReactNode, useEffect } from 'react';
import { SectionRedesigned as Section } from '../atoms/SectionRedesigned';
import { Button } from '../atoms/buttons/Button';
import { Flexbox } from '../atoms/FlexContainer';
import { ReactComponent as Edit } from '../../../assets/edit.svg';
import { FCWithChildren } from '../../../utils/types/FC';
import { IconWrapper } from '../../../utils/IconWrapper';

const ButtonContainer = styled.div<{ hasToggle: boolean }>`
  position: absolute;
  top: ${({ hasToggle }) => (hasToggle ? 70 : 25)}px;
  right: 15px;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    top: 25px;
    position: static;
  }
`;

const ExpandedTitleContainer = styled(Flexbox)`
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletBig}) {
    align-items: flex-start;
  }
`;

const EditButton = styled(Button)`
  padding: 0;
  width: 97px;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-left: 30px;
  }
`;

const ChildrenContainer = styled.div`
  margin-top: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-top: 30px;
  }
`;

export interface ExpandCollapseProps {
  expand: boolean;
  collapsedTitle: ReactNode;
  expandedTitle: ReactNode;
  handleExpand: () => void;
  hasError?: boolean;
  hideEditButton?: boolean;
  hasToggle?: boolean;
}

export const ExpandCollapse: FCWithChildren<ExpandCollapseProps> = ({
  expandedTitle,
  expand,
  hasError,
  children,
  collapsedTitle,
  handleExpand,
  hideEditButton,
  hasToggle = false,
}) => {
  const [show, setShow] = React.useState(expand);
  const [editButtonHidden, setEditButtonHidden] = React.useState(hideEditButton);
  useEffect(() => {
    setTimeout(
      () => {
        setShow(expand);
        setEditButtonHidden(!!hideEditButton);
      },
      hasToggle ? 300 : 0
    );
  }, [expand, editButtonHidden]);

  return show ? (
    <Section hasError={hasError}>
      <ExpandedTitleContainer alignItems="flex-start" direction="column">
        {expandedTitle}
      </ExpandedTitleContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Section>
  ) : (
    <Section hasError={hasError}>
      <Flexbox justifyContent="space-between" alignItems="flex-start">
        {collapsedTitle}
        {!editButtonHidden && (
          <ButtonContainer hasToggle={hasToggle}>
            <EditButton size="small" onClick={handleExpand} borderOnly>
              <Flexbox alignItems="center" justifyContent="center">
                <IconWrapper Icon={Edit} />
                Edytuj
              </Flexbox>
            </EditButton>
          </ButtonContainer>
        )}
      </Flexbox>
    </Section>
  );
};

import styled from '@emotion/styled';

export const Section = styled.section<{ hasError?: boolean; borderRadius?: string }>`
  border: solid 1px
    ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.sectionBorderColor)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '5px')};
  background-color: ${({ theme }) => theme.colors.white};

  min-height: 80px;
  padding: 22px 19px 20px 19px;
  width: 100%;
`;

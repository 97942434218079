export enum Env {
  ORDER_BASE_URL = 'ORDER_BASE_URL',
  USER_BASE_URL = 'USER_BASE_URL',
  PAYMENT_BASE_URL = 'PAYMENT_BASE_URL',
  CART_BASE_URL = 'CART_BASE_URL',
  POLLING_DELAY = 'POLLING_DELAY',
  PAYMENT_CLIENT_ID = 'PAYMENT_CLIENT_ID',
  POLLING_TIMEOUT = 'POLLING_TIMEOUT',
  JWT_URL = 'JWT_URL',
  BACK_TO_SHOP_URL = 'BACK_TO_SHOP_URL',
  GO_TO_REGISTER_PAGE = 'GO_TO_REGISTER_PAGE',
  BLPACZKA_URL = 'BLPACZKA_URL',
  MERCHANT_BASE_URL = 'MERCHANT_BASE_URL',
}

export function getEnvs(): Record<Env, string> {
  const {
    ORDER_BASE_URL,
    USER_BASE_URL,
    PAYMENT_BASE_URL,
    POLLING_DELAY,
    PAYMENT_CLIENT_ID,
    POLLING_TIMEOUT,
    JWT_URL,
    BACK_TO_SHOP_URL,
    BLPACZKA_URL,
    GO_TO_REGISTER_PAGE,
    MERCHANT_BASE_URL,
    CART_BASE_URL,
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
  } = window._env_;
  return {
    ORDER_BASE_URL,
    USER_BASE_URL,
    PAYMENT_BASE_URL,
    POLLING_DELAY,
    PAYMENT_CLIENT_ID,
    JWT_URL,
    POLLING_TIMEOUT,
    BACK_TO_SHOP_URL,
    BLPACZKA_URL,
    GO_TO_REGISTER_PAGE,
    MERCHANT_BASE_URL,
    CART_BASE_URL,
  };
}

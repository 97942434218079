import { PaymentMethodId } from '../../../models/data/PaymentMethod';
import React, { FC, ReactElement } from 'react';

import { ReactComponent as Blik } from 'assets/blik.svg';
import { ReactComponent as Dotpay } from 'assets/przelewy24.svg';
import { ReactComponent as Card } from 'assets/card.svg';

const icons: Record<PaymentMethodId, ReactElement> = {
  [PaymentMethodId.blik]: <Blik />,
  [PaymentMethodId.card]: <Card />,
  [PaymentMethodId.dotpay]: <Dotpay />,
};
export const PaymentMethodIcons: FC<{ paymentMethod: PaymentMethodId }> = ({ paymentMethod }) => {
  return icons[paymentMethod];
};

import { createAction } from '@reduxjs/toolkit';
import { DeliveryAddress, InvoiceData, ParcelLockerAddress } from '../../models';
import { FormType } from '../../models/data/Form';
import { NotesData } from '../../models/data/NotesData';

const ADD_FORM_ERRORS_ACTION = 'ADD_FORM_ERRORS_ACTION';

const createFormErrorAction = <T>(formType: FormType) =>
  createAction(
    `${ADD_FORM_ERRORS_ACTION}_${formType.toUpperCase()}`,
    (errors: Partial<Record<keyof T, string>>) => ({
      payload: {
        formType,
        errors,
      },
    })
  );

export const addBillingAddressFormErrorsAction = createFormErrorAction<DeliveryAddress>(
  FormType.billingAddress
);
export const addDeliveryAddressFormErrorsAction = createFormErrorAction<DeliveryAddress>(
  FormType.deliveryAddress
);

export const addParcelLockerBillingAddressFormErrorsAction = createFormErrorAction<ParcelLockerAddress>(
  FormType.parcelLockerBillingAddress
);
export const addParcelLockerAddressFormErrorsAction = createFormErrorAction<ParcelLockerAddress>(
  FormType.parcelLockerAddress
);
export const addInvoiceFormErrorsAction = createFormErrorAction<InvoiceData>(FormType.invoiceData);
export const addOrderNotesFormErrorsAction = createFormErrorAction<NotesData>(FormType.orderNotes);
export const addUserAddressFormErrorsAction = createFormErrorAction<DeliveryAddress>(
  FormType.userAddress
);

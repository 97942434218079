import { CardPaymentDetails } from '../../models/data/CardPaymentDetails';
import { post } from '../api';
import { getEnvs } from '../../utils/env';
import { Order } from '../../models/data/Order';

const SUBMIT_PAYMENT_URL = 'order/$orderId/pay';

export const submitPayment = async (
  cardPaymentDetails: CardPaymentDetails,
  orderId: Order['id'],
  total: number
) => {
  const res = await post(
    `${getEnvs().ORDER_BASE_URL}/${SUBMIT_PAYMENT_URL.replace('$orderId', orderId)}`,
    {
      amount: total,
      encryptedCard: cardPaymentDetails,
    }
  );
  return res.data;
};

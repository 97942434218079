// TODO: return data from store when implemented

import {
  DeliveryAddress,
  DeliveryMethod,
  InvoiceData,
  OrderStatus,
  ParcelLockerAddress,
  UserAddress,
} from 'models';
import { AppState } from '../reducers/rootReducer';
import { OrderState } from '../reducers/order/orderReducer';
import { Maybe, Nullable } from '../../utils/maybe';
import { Product } from '../../models/data/Product';
import { selectDeliveryMethods } from './deliveryMethodsSelectors';
import { Order, Seller } from '../../models/data/Order';
import { calculateOrderValue } from '../../utils/orderValue/orderValue';
import { PaymentMethod, PaymentMethodId } from '../../models/data/PaymentMethod';
import { selectPaymentMethods } from './paymentMethodsSelectors';

export const selectOrderState = (state: AppState): OrderState => state.order;
export const selectOrderStatus = (state: AppState): Maybe<OrderStatus> =>
  selectOrderState(state).status;
export const selectUserId = (state: AppState) => selectOrderState(state).buyer?.sprzedajemyUserId;
export const selectOrderId = (state: AppState) => selectOrderState(state).orderId;
export const selectSelectedDeliveryMethodId = (state: AppState): Maybe<DeliveryMethod['id']> =>
  selectOrderState(state).deliveryMethod?.deliveryMethodId;

export const selectSelectedDeliveryMethod = (state: AppState): Maybe<DeliveryMethod> => {
  const selectedDeliveryMethodId = selectSelectedDeliveryMethodId(state);
  return selectDeliveryMethods(state).find(
    (deliveryMethod) => deliveryMethod.id === selectedDeliveryMethodId
  );
};

export const selectSelectedPaymentMethod = (state: AppState): Order['paymentMethod'] =>
  selectOrderState(state).paymentMethod;

export const selectSelectedPaymentMtd = (state: AppState): PaymentMethod | undefined => {
  const paymentMethods = selectPaymentMethods(state);
  return paymentMethods.find((pm) => pm?.id === selectOrderState(state).paymentMethod.id);
};

export const selectSeller = (state: AppState): Seller => selectOrderState(state).seller;
export const selectOrderDeliveryMethod = (state: AppState) => state.order.deliveryMethod;
export const selectProducts = (state: AppState): Product[] => selectOrderState(state).products;

export const selectBillingAddress = (state: AppState): Maybe<DeliveryAddress> =>
  selectOrderState(state).billingAddress;

export const selectDeliveryAddress = (state: AppState): Maybe<DeliveryAddress | UserAddress> =>
  selectOrderState(state).deliveryAddress;

export const selectParcelLockerAddress = (state: AppState): Maybe<ParcelLockerAddress> =>
  selectOrderState(state).parcelLockerAddress;

export const selectParcelLockerBillingAddress = (state: AppState): Maybe<ParcelLockerAddress> =>
  selectOrderState(state).parcelLockerBillingAddress;

export const selectInvoiceData = (state: AppState): Maybe<InvoiceData> =>
  selectOrderState(state).invoiceData;

export const selectOrderNotes = (state: AppState): Maybe<string> => selectOrderState(state).notes;

export const selectAddInvoiceToOrder = (state: AppState): boolean =>
  selectOrderState(state).addInvoiceToOrder;

export const selectAddNotesToOrder = (state: AppState): boolean =>
  selectOrderState(state).addNotesToOrder;

export const selectAddDeliveryAddressToOrder = (state: AppState): boolean =>
  selectOrderState(state).addDeliveryAddressToOrder;

export const selectOrderErrors = (state: AppState): string[] => selectOrderState(state).errors;

export const selectOrderComission = (state: AppState): number => selectOrderState(state).commission;

export const selectOrderValue = (state: AppState): string => {
  const products = selectProducts(state);
  const commission = selectOrderComission(state);
  const deliveryMethodPrice = selectSelectedDeliveryMethod(state)?.price;
  return calculateOrderValue(products, commission, deliveryMethodPrice);
};

export const selectShowCardPaymentBox = (state: AppState): boolean => {
  const selectedPaymentMethod = selectSelectedPaymentMethod(state);
  const orderStatus = selectOrderStatus(state);

  return orderStatus === OrderStatus.FINALIZED && selectedPaymentMethod.id === PaymentMethodId.card;
};

export const selectShowInternalServerError = (state: AppState): boolean =>
  state.errors.showInternalServerError;

import { DeliveryAddress, UserAddress, ParcelLockerAddress } from 'models';
import { getEnvs } from 'utils/env';
import { put } from '../api';
import axios from 'axios';

const ORDER_BILLING_ADDRESS_URL = '/order/$orderId/billing-address/parcel';
const ORDER_PARCEL_ADDRESS_URL = '/order/$orderId/delivery-address/parcel';
const ORDER_PARCEL_LOCKER_ADDRESS_URL = '/order/$orderId/delivery-address/parcelLocker';

export const updateOrderBillingAddress = async (
  address: DeliveryAddress,
  orderId: string
): Promise<void> => {
  await put(
    `${getEnvs().ORDER_BASE_URL}${ORDER_BILLING_ADDRESS_URL.replace('$orderId', orderId)}`,
    address
  );
};

export const updateOrderDeliveryAddress = async (
  address: DeliveryAddress | null,
  orderId: string
): Promise<void> => {
  await put(
    `${getEnvs().ORDER_BASE_URL}${ORDER_PARCEL_ADDRESS_URL.replace('$orderId', orderId)}`,
    address
  );
};

export const deleteOrderDeliveryAddress = async (orderId: string): Promise<void> => {
  await axios.delete(
    `${getEnvs().ORDER_BASE_URL}${ORDER_PARCEL_ADDRESS_URL.replace('$orderId', orderId)}`
  );
};

export const updateOrderParcelLockerAddress = async (
  address: ParcelLockerAddress,
  orderId: string
): Promise<void> => {
  await put(
    `${getEnvs().ORDER_BASE_URL}${ORDER_PARCEL_LOCKER_ADDRESS_URL.replace('$orderId', orderId)}`,
    address
  );
};

export const deleteOrderParcelLockerAddress = async (orderId: string): Promise<void> => {
  await axios.delete(
    `${getEnvs().ORDER_BASE_URL}${ORDER_PARCEL_LOCKER_ADDRESS_URL.replace('$orderId', orderId)}`
  );
};

export const updateOrderDeliveryAddressId = async (
  address: UserAddress,
  orderId: string
): Promise<void> => {
  await put(
    `${getEnvs().ORDER_BASE_URL}${ORDER_PARCEL_ADDRESS_URL.replace('$orderId', orderId)}`,
    address
  );
};

import styled from '@emotion/styled';
import { Flexbox } from '../FlexContainer';

export const Input = styled.input<{ hasError?: boolean }>`
  position: relative;
  height: 42px;
  padding: 13px 12px 13px 16px;
  border: solid 1px
    ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.sectionBorderColor)};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.font.s};

  color: ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.darkGrey1)};
  background-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.errorBackground : 'initial'};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.sectionBorderColor};
    border-width: 2px;
  }
`;

export const TextInput = styled(Input)<{ small?: boolean; inPopup?: boolean }>`
  width: 100%;
  height: 48px ${({ small }) => small && `margin-right: 110px`};

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
    font-size: ${({ theme }) => theme.font.s};
  }
`;

export const PhoneInput = styled(TextInput)`
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletBig}) {
    margin-right: 110px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-right: 0;
    width: 305px;
  }
`;

export const TextArea = styled.textarea`
  border: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  border-radius: 4px;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.s};
  width: 100%;
  height: 160px;
  padding: 14px 16px;
  resize: vertical;
  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`;

export const Label = styled.label<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.fontMain)};
  margin-bottom: 7px;
  font-size: ${({ theme }) => theme.font.s};
`;

export const InputContainer = styled(Flexbox)<{ hasError?: boolean }>`
  & textarea {
    ${({ hasError, theme }) =>
      hasError &&
      `border-color: ${theme.colors.error};
         color: ${theme.colors.error};`}
  }
`;

import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import {
  PopupContainer as StyledPopupContainer,
  Shadow as StyledShadow,
  XButton,
} from '../atoms/Popup';
import { ParcelLockerAddress } from '../../../models';
import { getEnvs } from '../../../utils/env';

const Spacing = styled.div`
  margin-top: 30px;
`;

const PopupContainer = styled(StyledPopupContainer)<{ hidden: boolean }>`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

const Shadow = styled(StyledShadow)<{ hidden: boolean }>`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;
export interface ParcelLockerSelectionProps {
  handleParcelLockerSelection: (parcelLockerCode: ParcelLockerAddress['parcelLockerCode']) => void;
  handleParcelLockerClose: () => void;
  type: 'inpost' | 'orlen';
}

export const ParcelLockerSelection: FC<ParcelLockerSelectionProps> = ({
  handleParcelLockerClose,
  handleParcelLockerSelection,
  type,
}) => {
  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.data.type === 'SELECT_CHANGE') {
        const selectedPointCode: { name: string; pointData: string } = event.data.value;
        handleParcelLockerSelection(selectedPointCode.name);
      }
    });
  }, [type]);

  const popupElement = document.getElementById('geo-popup');

  if (!type) {
    return null;
  }

  return (
    popupElement &&
    ReactDOM.createPortal(
      <>
        <Shadow hidden={false} />
        <PopupContainer wide hidden={false}>
          <XButton onClick={handleParcelLockerClose} />
          <Spacing />
          <iframe
            id={`pudoMap`}
            allow="geolocation"
            src={`${getEnvs().BLPACZKA_URL}/pudo-map?api_type=${type}`}
            width="100%"
            height="600"
            frameBorder="0"
          ></iframe>
        </PopupContainer>
      </>,
      popupElement
    )
  );
};

//todo: Fix geowidget / replace with new one

import { Product } from '../../../models/data/Product';
import React, { FC } from 'react';
import { Strong } from '../atoms/Text';
import {
  calculateCommission,
  calculateOrderSumWithCommission,
  calculateProductsSum,
  priceFormat,
} from '../../../utils/orderValue/orderValue';
import { Flexbox } from '../atoms/FlexContainer';
import { InputValidationTooltip } from '../atoms/form/InputValidationTooltip';
import styled from '@emotion/styled';
import { ReactComponent as Remark } from 'assets/remark.svg';

const Row = styled(Flexbox)`
  margin-bottom: 14px;
  p {
    margin: 0;
  }
  > strong {
    margin-left: 4px;
  }
`;

const Payed = styled.p`
  font-size: ${({ theme }) => theme.font.xl};
`;

const Price = styled.p`
  font-size: 28px;
`;

const PriceContainer = styled(Flexbox)`
  margin-top: 20px;
`;

const PostSummaryWrapper = styled.div`
  padding: 0 10px 10px 10px;
`;

const Border = styled.div`
  margin-bottom: 30px;
  box-sizing: border-box;
  position: relative;
  width: calc(100% + 40px);
  left: -20px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.sectionBorderColor};
`;
export interface PostPaymentSummaryProps {
  products: Product[];
  deliveryPrice?: number;
  commissionPercentage: number;
}

export const PostPaymentSummary: FC<PostPaymentSummaryProps> = ({
  products,
  commissionPercentage,
  deliveryPrice,
}) => {
  const calculatedCommission = calculateCommission(products, commissionPercentage);
  const productsPrice = calculateProductsSum(products).toNumber();
  const orderSum = calculateOrderSumWithCommission(
    products,
    commissionPercentage,
    deliveryPrice
  ).toNumber();
  return (
    <PostSummaryWrapper>
      <Border />
      <Row justifyContent="space-between">
        <p>Wartość produktów</p>
        <p>
          <Strong>{priceFormat(productsPrice)}</Strong>
        </p>
      </Row>
      <Row justifyContent="space-between">
        <Flexbox>
          <p>Opłata transakcyjna</p>
          <InputValidationTooltip
            errorMsg={[
              <strong>Opłata transakcyjna</strong>,
              'Dzięki opłatom transakcyjnym możemy zaoferować Ci możliwość zakupu produktów oferowanych w naszym serwisie, szybką płatność i tanią przesyłkę. Z tej niewielkiej kwoty finansujemy również program “bezpieczne zakupy” (notka dla Piotra: nie możemy używać Pogram Ochrony Kupujących, to jest zbyt silnie powiązane z Allego) oraz rozwój serwisu, dzięki czemu zakupy będziesz robić coraz wygodniej i nadal bezpiecznie.',
              <strong>Czy opłata transakcyjna jest obowiązkowa?</strong>,
              'Koszt opłaty transakcyjnej ponosisz wyłącznie korzystając z opcji “Kup Teraz”. Możesz skontaktować się ze sprzedawcą i zaproponować mu inną formę przekazania wpłaty oraz wybór innego sposobu dostawy. Pamiętaj jednak, że wówczas nie skorzystasz z programu “bezpieczne zakupy”, ani nie wystawisz komentarza po zakończonej transakcji. Nie będziemy też w stanie udostępnić Ci śledzenia Twojej przesyłki.',
            ]}
            render={() => (
              <Remark
                height={17}
                viewBox="0 0 24 24"
                style={{ marginLeft: 4, cursor: 'pointer' }}
              />
            )}
            onHover
          />
        </Flexbox>
        <p>
          <Strong>{priceFormat(calculatedCommission.toNumber())}</Strong>
        </p>
      </Row>
      <Row justifyContent="space-between">
        <p>Koszt dostawy</p>
        <p>
          <Strong>{deliveryPrice ? priceFormat(deliveryPrice) : '-'}</Strong>
        </p>
      </Row>
      <PriceContainer justifyContent="space-between" alignItems="center">
        <Payed>
          <Strong>Zapłacono</Strong>
        </Payed>
        <Price>
          <Strong>{priceFormat(orderSum)}</Strong>
        </Price>
      </PriceContainer>
    </PostSummaryWrapper>
  );
};

import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../../styles/theme';
import { RequiredFields } from '../../../utils/types/RequiredFields';

export const Grid = styled.div`
  margin: 0 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;

  @media (min-width: ${theme.breakpoint.tablet}) {
    grid-column-gap: 16px;
    margin: 0 32px;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: ${theme.breakpoint.laptop}) {
    grid-template-columns: repeat(12, 1fr);
  }

  @media (min-width: ${theme.breakpoint.desktop}) {
    margin: 0 auto;
    width: 1388px;
    grid-template-columns: repeat(12, 101px);
  }
`;

export type RowProps = RequiredFields<
  Partial<
    Record<
      keyof Theme['breakpoint'],
      { col: { from: number; to: number }; row?: { from: number; to: number } }
    >
  >,
  'mobile'
>;

export const Row = styled.div<RowProps>`
  ${({ mobile, tablet, tabletBig, laptop, desktop }) => `
      ${mobile.col && `grid-column: ${mobile.col.from} / ${mobile.col.to}`}; 
      ${
        tablet &&
        `@media (min-width: ${theme.breakpoint.tablet}) { grid-column: ${tablet.col.from} / ${tablet.col.to} }`
      };
      ${
        tabletBig &&
        `@media (min-width: ${theme.breakpoint.tabletBig}) { grid-column: ${tabletBig.col.from} / ${tabletBig.col.to} }`
      };
      ${
        laptop &&
        `@media (min-width: ${theme.breakpoint.laptop}) { grid-column: ${laptop.col.from} / ${laptop.col.to} }`
      };
      ${
        desktop &&
        `@media (min-width: ${theme.breakpoint.desktop}) { grid-column: ${desktop.col.from} / ${desktop.col.to} }`
      };
      
      ${mobile.row && `grid-row: ${mobile.row.from} / ${mobile.row.to}`}; 
      ${
        tablet?.row &&
        `@media (min-width: ${theme.breakpoint.tablet}) { grid-row: ${tablet.row.from} / ${tablet.row.to} }`
      };
      ${
        tabletBig?.row &&
        `@media (min-width: ${theme.breakpoint.tabletBig}) { grid-row: ${tabletBig.row.from} / ${tabletBig.row.to} }`
      };
      ${
        laptop?.row &&
        `@media (min-width: ${theme.breakpoint.laptop}) { grid-row: ${laptop.row.from} / ${laptop.row.to} }`
      };
      ${
        desktop?.row &&
        `@media (min-width: ${theme.breakpoint.desktop}) { grid-row: ${desktop.row.from} / ${desktop.row.to} }`
      };
  `}
`;

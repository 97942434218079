import React, { FC } from 'react';
import styled from '@emotion/styled';
import { DeliveryAddress } from 'models';
import { SavedItemCard } from '../molecules/SavedItemCard';
import { Radio } from '../atoms/Radio';
import { Flexbox } from '../atoms/FlexContainer';
import { AddressSummary } from '../molecules/AddressSummary';

const StyledRadio = styled(Radio)`
  margin-right: 20px;
  margin-top: 3px;
`;

export interface SavedDeliveryAddressCardProps {
  address: DeliveryAddress;
  handleAddressSelection: () => void;
  handleAddressDeletion: () => void;
  handleAddressEdition: () => void;
  selected?: boolean;
}

export const SavedDeliveryAddressCard: FC<SavedDeliveryAddressCardProps> = ({
  address,
  handleAddressSelection,
  handleAddressDeletion,
  handleAddressEdition,
  selected = false,
}) => (
  <SavedItemCard
    handleSelection={handleAddressSelection}
    handleEdit={handleAddressEdition}
    handleRemoval={handleAddressDeletion}
  >
    <Flexbox>
      <StyledRadio checked={selected} size={18}></StyledRadio>
      <AddressSummary address={address} noMarginTop />
    </Flexbox>
  </SavedItemCard>
);

import { createAction } from '@reduxjs/toolkit';
import {
  DeliveryAddress,
  DeliveryMethod,
  InvoiceData,
  OrderStatus,
  ParcelLockerAddress,
  UserAddress,
} from 'models';
import { Order } from '../../models/data/Order';
import { PaymentMethod } from '../../models/data/PaymentMethod';
import { PaymentMethodSubCategory } from '../../models/data/PaymentMethodSubCategory';

const ADD_ORDER_DELIVERY_METHOD_ACTION = 'ADD_ORDER_DELIVERY_METHOD_ACTION';
const ADD_ORDER_DELIVERY_METHOD_SUCCESS_ACTION = 'ADD_ORDER_DELIVERY_METHOD_SUCCESS_ACTION';

const ADD_ORDER_PAYMENT_METHOD_ACTION = 'ADD_ORDER_PAYMENT_METHOD_ACTION';
const ADD_ORDER_PAYMENT_METHOD_SUCCESS_ACTION = 'ADD_ORDER_PAYMENT_METHOD_SUCCESS_ACTION';

const ADD_ORDER_BILLING_ADDRESS_ACTION = 'ADD_ORDER_BILLING_ADDRESS_ACTION';
const ADD_ORDER_BILLING_ADDRESS_SUCCESS_ACTION = 'ADD_ORDER_BILLING_ADDRESS_SUCCESS_ACTION';

const ADD_ORDER_PARCEL_LOCKER_BILLING_ADDRESS_ACTION =
  'ADD_ORDER_PARCEL_LOCKER_BILLING_ADDRESS_ACTION';
const ADD_ORDER_PARCEL_LOCKER_BILLING_ADDRESS_SUCCESS_ACTION =
  'ADD_ORDER_PARCEL_LOCKER_BILLING_ADDRESS_SUCCESS_ACTION';

const ADD_ORDER_BILLING_ADDRESS_ID_ACTION = 'ADD_ORDER_BILLING_ADDRESS_ID_ACTION';

const ADD_ORDER_DELIVERY_ADDRESS_ACTION = 'ADD_ORDER_DELIVERY_ADDRESS_ACTION';
const ADD_ORDER_DELIVERY_ADDRESS_SUCCESS_ACTION = 'ADD_ORDER_DELIVERY_ADDRESS_SUCCESS_ACTION';
const ADD_ORDER_DELIVERY_ADDRESS_ERROR_ACTION = 'ADD_ORDER_DELIVERY_ADDRESS_ERROR_ACTION';

const ADD_ORDER_DELIVERY_ADDRESS_ID_ACTION = 'ADD_ORDER_DELIVERY_ADDRESS_ID_ACTION';

const DELETE_ORDER_DELIVERY_ADDRESS_ACTION = 'DELETE_ORDER_DELIVERY_ADDRESS_ACTION';
const DELETE_ORDER_DELIVERY_ADDRESS_SUCCESS_ACTION = 'DELETE_ORDER_DELIVERY_ADDRESS_ACTION';
const DELETE_ORDER_DELIVERY_ADDRESS_ERROR_ACTION = 'DELETE_ORDER_DELIVERY_ERROR_ACTION';

const SET_ADD_DELIVERY_ADDRESS_TO_ORDER_ACTION = 'SET_ADD_DELIVERY_ADDRESS_TO_ORDER_ACTION';

const ADD_ORDER_INVOICE_DATA_ACTION = 'ADD_ORDER_INVOICE_DATA_ACTION';
const ADD_ORDER_INVOICE_DATA_SUCCESS_ACTION = 'ADD_ORDER_INVOICE_DATA_SUCCESS_ACTION';

const DELETE_ORDER_INVOICE_DATA_ACTION = 'DELETE_ORDER_INVOICE_DATA_ACTION';
const DELETE_ORDER_INVOICE_DATA_SUCCESS_ACTION = 'DELETE_ORDER_INVOICE_DATA_SUCCESS_ACTION';

const ADD_ORDER_NOTES_ACTION = 'ADD_ORDER_NOTES_ACTION';
const ADD_ORDER_NOTES_SUCCESS_ACTION = 'ADD_ORDER_NOTES_ACTION_SUCCESS';

const DELETE_ORDER_NOTES_ACTION = 'DELETE_ORDER_NOTES_ACTION';
const DELETE_ORDER_NOTES_SUCCESS_ACTION = 'DELETE_ORDER_NOTES_SUCCESS_ACTION';

const SUBMIT_ORDER_ACTION = 'SUBMIT_ORDER_ACTION';
const SUBMIT_ORDER_SUCCESS_ACTION = 'SUBMIT_ORDER_SUCCESS_ACTION';
const SUBMIT_ORDER_ERROR_ACTION = 'SUBMIT_ORDER_ERROR_ACTION';

const CANCEL_ORDER_ERROR_ACTION = 'CANCEL_ORDER_ERROR_ACTION';

const GET_ORDER_ACTION = 'GET_ORDER_ACTION';
const GET_ORDER_SUCCESS_ACTION = 'GET_ORDER_SUCCESS_ACTION';

const SET_ADD_INVOICE_TO_ORDER_ACTION = 'SET_ADD_INVOICE_TO_ORDER_ACTION';
const SET_ADD_NOTES_TO_ORDER_ACTION = 'SET_ADD_NOTES_TO_ORDER_ACTION';

const GET_ORDER_STATUS_ACTION = 'GET_ORDER_STATUS_ACTION';
const GET_ORDER_STATUS_SUCCESS_ACTION = 'GET_ORDER_STATUS_SUCCESS_ACTION';

const RENEW_ORDER_STATUS_ACTION = 'CHANGE_ORDER_STATUS_ACTION';
const RENEW_ORDER_STATUS_SUCCESS_ACTION = 'CHANGE_ORDER_STATUS_SUCCESS_ACTION';

const CHANGE_PRODUCT_AMOUNT_ACTION = 'CHANGE_PRODUCT_AMOUNT_ACTION';
const CHANGE_PRODUCT_AMOUNT_SUCCESS_ACTION = 'CHANGE_PRODUCT_AMOUNT_SUCCESS_ACTION';

const DELETE_PRODUCT_ACTION = 'DELETE_PRODUCT_ACTION';
const DELETE_PRODUCT_SUCCESS_ACTION = 'DELETE_PRODUCT_SUCCESS_ACTION';

export const addOrderDeliveryMethodAction = createAction(
  ADD_ORDER_DELIVERY_METHOD_ACTION,
  (deliveryMethod: DeliveryMethod) => ({
    payload: { deliveryMethod },
  })
);

export const addOrderDeliveryMethodSuccessAction = createAction(
  ADD_ORDER_DELIVERY_METHOD_SUCCESS_ACTION,
  (deliveryMethod: DeliveryMethod) => ({
    payload: { deliveryMethod },
  })
);

export const addOrderPaymentMethodAction = createAction(
  ADD_ORDER_PAYMENT_METHOD_ACTION,
  (paymentMethodId: PaymentMethod['id'], subCategoryId?: PaymentMethodSubCategory['id']) => ({
    payload: { paymentMethodId, subPaymentId: subCategoryId },
  })
);

export const addOrderPaymentMethodSuccessAction = createAction(
  ADD_ORDER_PAYMENT_METHOD_SUCCESS_ACTION,
  (paymentMethodId: PaymentMethod['id'], subPaymentId?: PaymentMethodSubCategory['id']) => ({
    payload: { paymentMethodId, subPaymentId },
  })
);

export const addOrderInvoiceDataAction = createAction(
  ADD_ORDER_INVOICE_DATA_ACTION,
  (invoice: InvoiceData) => ({
    payload: { invoice },
  })
);

export const addOrderInvoiceDataSuccessAction = createAction(
  ADD_ORDER_INVOICE_DATA_SUCCESS_ACTION,
  (invoice: InvoiceData) => ({
    payload: { invoice },
  })
);

export const addOrderParcelLockerBillingAddressAction = createAction(
  ADD_ORDER_PARCEL_LOCKER_BILLING_ADDRESS_ACTION,
  (address: ParcelLockerAddress) => ({ payload: { address } })
);

export const addOrderParcelLockerBillingAddressSuccessAction = createAction(
  ADD_ORDER_PARCEL_LOCKER_BILLING_ADDRESS_SUCCESS_ACTION,
  (address: ParcelLockerAddress) => ({ payload: { address } })
);

export const deleteOrderInvoiceDataAction = createAction(DELETE_ORDER_INVOICE_DATA_ACTION);

export const deleteOrderInvoiceDataSuccessAction = createAction(
  DELETE_ORDER_INVOICE_DATA_SUCCESS_ACTION
);

export const addOrderBillingAddressAction = createAction(
  ADD_ORDER_BILLING_ADDRESS_ACTION,
  (address: DeliveryAddress) => ({ payload: { address } })
);

export const addOrderBillingAddressByIdAction = createAction(
  ADD_ORDER_BILLING_ADDRESS_ID_ACTION,
  (addressId: UserAddress['id']) => ({ payload: { addressId } })
);

export const addOrderBillingAddressSuccessAction = createAction(
  ADD_ORDER_BILLING_ADDRESS_SUCCESS_ACTION,
  (address: DeliveryAddress) => ({ payload: { address } })
);

export const addOrderDeliveryAddressAction = createAction(
  ADD_ORDER_DELIVERY_ADDRESS_ACTION,
  (address: DeliveryAddress) => ({ payload: { address } })
);

export const addOrderDeliveryAddressByIdAction = createAction(
  ADD_ORDER_DELIVERY_ADDRESS_ID_ACTION,
  (addressId: UserAddress['id']) => ({ payload: { addressId } })
);

export const addOrderDeliveryAddressSuccessAction = createAction(
  ADD_ORDER_DELIVERY_ADDRESS_SUCCESS_ACTION,
  (address: DeliveryAddress) => ({ payload: { address } })
);

export const addOrderNotesAction = createAction(ADD_ORDER_NOTES_ACTION, (notes: string) => ({
  payload: { notes },
}));

export const addOrderNotesSuccessAction = createAction(
  ADD_ORDER_NOTES_SUCCESS_ACTION,
  (notes: string) => ({ payload: { notes } })
);

export const deleteOrderNotesAction = createAction(DELETE_ORDER_NOTES_ACTION);
export const deleteOrderNotesSuccessAction = createAction(DELETE_ORDER_NOTES_SUCCESS_ACTION);

export const getOrderAction = createAction(GET_ORDER_ACTION, (id: string) => ({ payload: { id } }));

export const getOrderSuccessAction = createAction(GET_ORDER_SUCCESS_ACTION, (order: Order) => ({
  payload: { order },
}));

export const submitOrderAction = createAction(SUBMIT_ORDER_ACTION);

export const submitOrderSuccessAction = createAction(SUBMIT_ORDER_SUCCESS_ACTION);

export const submitOrderErrorAction = createAction(
  SUBMIT_ORDER_ERROR_ACTION,
  (errors: string[]) => ({ payload: { errors } })
);

export const cancelOrderErrorAction = createAction(CANCEL_ORDER_ERROR_ACTION, (error: string) => ({
  payload: { error },
}));
export const setAddDeliveryAddressToOrderAction = createAction(
  SET_ADD_DELIVERY_ADDRESS_TO_ORDER_ACTION,
  (addDeliveryAddressToOrder: boolean) => ({ payload: { addDeliveryAddressToOrder } })
);

export const setAddInvoiceToOrderAction = createAction(
  SET_ADD_INVOICE_TO_ORDER_ACTION,
  (addInvoiceToOrder: boolean) => ({ payload: { addInvoiceToOrder } })
);

export const setAddNotesToOrderAction = createAction(
  SET_ADD_NOTES_TO_ORDER_ACTION,
  (addNotesToOrder: boolean) => ({ payload: { addNotesToOrder } })
);

export const getOrderStatusAction = createAction(
  GET_ORDER_STATUS_ACTION,
  (orderId: Order['id']) => ({ payload: { orderId } })
);

export const getOrderStatusSuccessAction = createAction(
  GET_ORDER_STATUS_SUCCESS_ACTION,
  (status: OrderStatus) => ({ payload: { status } })
);

export const renewOrderStatusAction = createAction(RENEW_ORDER_STATUS_ACTION);

export const renewOrderStatusSuccessAction = createAction(RENEW_ORDER_STATUS_SUCCESS_ACTION);

export const changeProductAmountAction = createAction(
  CHANGE_PRODUCT_AMOUNT_ACTION,
  (productId: string, amount: number) => ({ payload: { productId, amount } })
);

export const changeProductAmountSuccessAction = createAction(
  CHANGE_PRODUCT_AMOUNT_SUCCESS_ACTION,
  (productId: string, amount: number) => ({ payload: { productId, amount } })
);

export const deleteProductAction = createAction(DELETE_PRODUCT_ACTION, (productId: string) => ({
  payload: { productId },
}));

export const deleteProductSuccessAction = createAction(
  DELETE_PRODUCT_SUCCESS_ACTION,
  (productId: string) => ({ payload: { productId } })
);

import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PaymentMethodSubCategory } from '../../../models/data/PaymentMethodSubCategory';
import { Popup } from '../atoms/Popup';
import { Flexbox } from '../atoms/FlexContainer';
import { Card } from '../atoms/Card';
import { Icon } from '../atoms/Icon';
import { Maybe } from '../../../utils/maybe';
const Title = styled.h2`
  margin-bottom: 23px;
  font-size: ${({ theme }) => theme.font.xl};
  font-weight: 600;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.font.xl};
  }
`;

const PaymentIcon = styled(Icon)`
  min-height: 48px;
  max-width: 80px;
  max-height: 100%;
  padding: 0 5px 0 5px;
`;

export const PaymentContainer = styled(Flexbox)`
  min-height: 350px;
`;

export const CardsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

const PaymentOption = styled(Flexbox)`
  gap: 10px;
`;

const PaymentCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100px;
  min-height: 60px;
`;

const PaymentTitle = styled.span`
  max-width: 100px;
  word-break: keep-all;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.darkBlue1};
`;

export interface FastPaymentProps {
  fastPaymentMethods: PaymentMethodSubCategory[];
  handleSelection: (id: PaymentMethodSubCategory['id']) => void;
  isOpen: boolean;
  handleClose: () => void;
}

export const FastPayment: FC<FastPaymentProps> = ({
  fastPaymentMethods,
  handleSelection,
  isOpen,
  handleClose,
}) => {
  const [selectedPayment, setSelectedPayment] = useState<Maybe<PaymentMethodSubCategory['id']>>(
    undefined
  );

  useEffect(() => {
    if (selectedPayment) {
      handleSelection(selectedPayment);
      handleClose();
    }
  }, [selectedPayment]);

  return (
    <Popup isOpen={isOpen} handleClose={handleClose} width={700}>
      <PaymentContainer direction="column" justifyContent="space-between">
        <Flexbox direction="column" alignItems="center">
          <Flexbox justifyContent="flex-start" fill>
            <Title>Wybierz szybki przelew</Title>
          </Flexbox>
          <CardsContainer>
            {fastPaymentMethods.map((paymentMethod) => (
              <PaymentOption direction="column" alignItems="center" justifyContent="center">
                <PaymentCard
                  key={paymentMethod.id}
                  onClick={() => setSelectedPayment(paymentMethod.id)}
                  selected={selectedPayment === paymentMethod.id}
                >
                  <Flexbox alignItems="center" justifyContent="center">
                    <PaymentIcon
                      src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/dotpay/${paymentMethod.id}.svg`}
                      alt={paymentMethod.name}
                    />
                  </Flexbox>
                </PaymentCard>
                <PaymentTitle>{paymentMethod.name}</PaymentTitle>
              </PaymentOption>
            ))}
          </CardsContainer>
        </Flexbox>
      </PaymentContainer>
    </Popup>
  );
};

import { createReducer } from '@reduxjs/toolkit';
import { internalServerErrorAction } from '../../actions/ErrorsActions';

export interface ErrorsState {
  showInternalServerError: boolean;
}

const initialState: ErrorsState = {
  showInternalServerError: false,
};
export const errorsReducer = createReducer(initialState, (builder) => {
  builder.addCase(internalServerErrorAction, (state) => {
    state.showInternalServerError = true;
  });
});

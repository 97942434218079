import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderSummaryTemplate } from '../templates/OrderSummaryTemplate';
import { PaymentStatus } from '../../../models/data/PaymentStatus';
import { getOrderAction } from '../../../store/actions/OrderActions';
import { getDeliveryMethodsAction } from '../../../store/actions/DeliveryMethodsActions';
import { selectOrderState, selectSelectedPaymentMtd, selectSeller } from '../../../store/selectors';
import { selectSelectedDeliveryMethod } from '../../../store/selectors/deliveryMethodsSelectors';
import { getEnvs } from '../../../utils/env';
import { getPaymentMethodsAction } from '../../../store/actions/PaymentActions';

export const OrderSummaryPage = () => {
  const { id } = useParams<{ id: string }>();
  const order = useSelector(selectOrderState);
  const selectedDeliveryMethod = useSelector(selectSelectedDeliveryMethod);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentMtd);
  const seller = useSelector(selectSeller);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderAction(id!));
    dispatch(getDeliveryMethodsAction());
    dispatch(getPaymentMethodsAction());
  }, []);

  const redirectToHomepage = () => {
    window.location.href = getEnvs().BACK_TO_SHOP_URL;
  };

  const redirectToRegisterPage = () => {
    window.location.href = getEnvs().GO_TO_REGISTER_PAGE;
  };

  return !!order?.orderId ? (
    <OrderSummaryTemplate
      order={order}
      paymentStatus={PaymentStatus.success}
      deliveryMethod={selectedDeliveryMethod}
      paymentMethod={selectedPaymentMethod!}
      redirectToHomepage={redirectToHomepage}
      redirectToRegisterPage={redirectToRegisterPage}
      seller={seller}
    />
  ) : null;
};

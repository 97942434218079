export enum FormStatus {
  clear = 'clear',
  opened = 'opened',
  invalid = 'invalid',
  submitted = 'submitted',
  loading = 'loading',
}

export enum FormType {
  billingAddress = 'billingAddress',
  deliveryAddress = 'deliveryAddress',
  userAddress = 'userAddress',
  invoiceData = 'invoiceData',
  orderNotes = 'orderNotes',
  parcelLockerAddress = 'parcelLockerAddress',
  parcelLockerBillingAddress = 'parcelLockerBillingAddress',
  order = 'order',
}

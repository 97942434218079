import React, { FC } from 'react';
import { MultiSection } from './MultiSection';
import { Cart } from '../../../models/data/Cart';

export interface MultiSectionsProps {
  handleDeleteProduct: (productId: string) => void;
  handleSetAmount: (productId: string, amount: number) => void;
  handleDeleteCart: (orderId: string) => void;
  handleChooseCart: (orderId: string) => void;
  commissionPercentage: number;
  activeCard: string;
  cards: Cart[];
}

export const MultiSections: FC<MultiSectionsProps> = ({
  handleDeleteProduct,
  handleSetAmount,
  handleDeleteCart,
  handleChooseCart,
  commissionPercentage,
  activeCard,
  cards,
}) => {
  const multiSectionElement = cards.map((card) => (
    <MultiSection
      key={card.orderId}
      handleDeleteProduct={handleDeleteProduct}
      handleSetAmount={handleSetAmount}
      handleDeleteCart={handleDeleteCart}
      handleChooseCart={handleChooseCart}
      cartId={card.orderId}
      isActive={card.orderId === activeCard}
      commissionPercentage={commissionPercentage}
      urlMoreOffer={card.urlMoreOffer}
      sellerName={card.sellerName}
      products={card.products}
    />
  ));
  return <div>{multiSectionElement}</div>;
};

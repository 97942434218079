import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Section } from '../atoms/Section';
import { ProductCard } from '../molecules/ProductCard';
import { ReactComponent as PlusRound } from 'assets/plus-round.svg';
import { IconRoundWrapper } from 'utils/IconRoundWrapper';
import { ReactComponent as Trash } from 'assets/trash.svg';
import { useTheme } from '@emotion/react';
import { Hide, Show } from '../atoms/Visibility';
import { Flexbox } from '../atoms/FlexContainer';
import { Product } from 'models/data/Product';
import { SellerTitle } from '../molecules/SellerTitle';

const ProductSectionContainer = styled(Section)`
  font-family: Arial, serif;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.mobileBig}) {
    border: none;
    background: none;
    padding: 0;
  }
`;

const ProductSectionHeader = styled(Flexbox)`
  padding: 0 0 20px;
`;

const DeleteAll = styled(Flexbox)`
  gap: 3px;
  margin-top: -3px;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.fontMain};
`;
const DeleteAllLabel = styled.span`
  cursor: pointer;
`;
const AddMoreProductWrapper = styled.div`
  padding-top: 20px;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
  line-height: 16px;
`;
const AddMoreProduct = styled.a`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 7px;
  width: 325px;
  height: 45px;
  padding: 20px;
  box-sizing: border-box;
  border: solid 1px ${({ theme }) => theme.colors.darkGrey1};
  border-radius: 6px;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.fontMain};
  text-decoration: none;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 356px;
  }
`;

export interface ProductSectionProps {
  products: Product[];
  handleDeleteProduct: (productId: string) => void;
  handleSetAmount: (orderId: string, amount: number) => void;
  urlMoreOffer: string;
  sellerName: string;
  handleDeleteAll?: () => void;
  isCompany?: boolean;
}

export const ProductsSection: FC<ProductSectionProps> = ({
  handleDeleteProduct,
  handleSetAmount,
  handleDeleteAll,
  urlMoreOffer,
  sellerName,
  isCompany = false,
  products,
}) => {
  const theme = useTheme();
  let tooltipTitle;
  let tooltipText;
  if (isCompany) {
    tooltipTitle = 'Przedsiębiorca';
    tooltipText =
      'Ten sprzedawca jest firmą, dlatego zakupy, które u niego robisz są chronione na mocy przepisów konsumenckich.';
  } else {
    tooltipTitle = 'Osoba prywatna';
    tooltipText =
      'Ten sprzedawca jest osobą prywatną, dlatego zakupy, które u niego robisz nie są chronione na mocy przepisów konsumenckich.';
  }

  const productsElement = products.map((product) => (
    <ProductCard
      key={product.id}
      handleDeleteProduct={handleDeleteProduct}
      handleSetAmount={handleSetAmount}
      productId={product.offerId}
      photoUrl={product.mainImg}
      photoAlt={product.photoAlt}
      title={product.name}
      price={product.price}
      size={product.size}
      brand={product.brand}
      amount={product.amount}
      total={product.total}
      productUrl={product.url}
    />
  ));
  return (
    <ProductSectionContainer>
      <ProductSectionHeader alignItems="center" justifyContent="space-between">
        <SellerTitle
          sellerName={sellerName}
          urlMoreOffer={urlMoreOffer}
          tooltip={{ title: tooltipTitle, text: tooltipText }}
        />
        {handleDeleteAll && (
          <DeleteAll alignItems="center">
            <IconRoundWrapper Icon={Trash} handleClick={handleDeleteAll} />
            <Show from={theme.breakpoint.tablet}>
              <DeleteAllLabel onClick={handleDeleteAll}>Usuń wszystko</DeleteAllLabel>
            </Show>
          </DeleteAll>
        )}
      </ProductSectionHeader>
      {productsElement}
      <AddMoreProductWrapper>
        <AddMoreProduct href={urlMoreOffer}>
          <PlusRound />
          <Show from={theme.breakpoint.tablet}>Dodaj więcej produktów od tego sprzedawcy</Show>
          <Hide from={theme.breakpoint.tablet}>Dodaj więcej produktów od sprzedawcy</Hide>
        </AddMoreProduct>
      </AddMoreProductWrapper>
    </ProductSectionContainer>
  );
};

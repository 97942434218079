import styled from '@emotion/styled';

export const FormContainer = styled.form`
  width: 100%;
  margin: 34px auto 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0;
    #fullName,
    #companyName,
    #street,
    #postalCode,
    #country,
    #phone,
    #fullName,
    #nip,
    #email {
      width: 322px;
    }
    #houseNumber,
    #apartmentNumber {
      width: 151px;
    }

    #postcode {
      width: 112px;
      margin-right: 0px;
    }

    #city {
      width: 190px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    display: flex;
    flex-wrap: wrap;
    width: 863px;
    gap: 20px;
    margin: 0;
    #fullName,
    #companyName,
    #street,
    #postalCode,
    #country,
    #phone,
    #fullName,
    #nip,
    #email {
      width: 416px;
    }
    #houseNumber,
    #apartmentNumber {
      width: 198px;
    }

    #postcode {
      width: 160px;
      margin-right: 0px;
    }

    #city {
      width: 236px;
    }
  }
`;

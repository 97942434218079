import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Wallet } from 'assets/wallet.svg';

import {
  DotpayPaymentMethod,
  PaymentMethod,
  PaymentMethodId,
} from '../../../models/data/PaymentMethod';
import { MethodSelection } from './MethodSelection';
import { Section } from '../atoms/Section';
import { Flexbox } from '../atoms/FlexContainer';
import { FastPayment } from './FastPayment';
import { PaymentMethodSubCategory } from '../../../models/data/PaymentMethodSubCategory';
import { OrderState } from '../../../store/reducers/order/orderReducer';
import { Maybe } from '../../../utils/maybe';
import { ButtonMethodView } from '../molecules/ButtonMethodView';
import { PaymentMethodIcons } from '../atoms/PaymentMethodIcons';

const Title = styled.p`
  font-size: ${({ theme }) => theme.font.m};
  margin-left: 8px;
`;

const FlexContainer = styled(Flexbox)`
  height: 32px;
`;

const SummaryIcon = styled.img`
  height: 48px;
  margin-right: 8px;
`;

const SubPaymentWrapper = styled(Flexbox)`
  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}) {
    border-top: 1px solid ${({ theme }) => theme.colors.grey1};
    width: 100%;
    padding-top: 10px;
  }
`;

const SubPaymentIcon = ({ id, name }: PaymentMethodSubCategory) => {
  if (!id) {
    return null;
  }
  const icon = `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/dotpay/${id}.svg`;
  return <SummaryIcon src={icon} alt={name} />;
};

const SubPaymentTitle = styled.p`
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    max-width: none;
  }
`;

export interface PaymentMethodSelectionSectionProps {
  paymentMethods: PaymentMethod[];
  selectPaymentMethod: (
    id: PaymentMethod['id'],
    subPaymentId?: PaymentMethodSubCategory['id']
  ) => void;
  selectedPaymentMethod: OrderState['paymentMethod'];
  payAtPickup?: boolean;
  checkOutInitiated?: boolean;
}

export const PaymentMethodSelectionSection: FC<PaymentMethodSelectionSectionProps> = ({
  paymentMethods,
  selectPaymentMethod,
  selectedPaymentMethod,
  payAtPickup,
  checkOutInitiated,
}) => {
  const [showFastPaymentPopup, setShowFastPaymentPopup] = useState(false);
  const [subPayment, setSubPayment] = useState<Maybe<PaymentMethodSubCategory>>(undefined);

  const setSubPaymentById = (subPaymentId: PaymentMethodSubCategory['id']) => {
    setSubPayment(
      (paymentMethods.find(
        (paymentMethod) => paymentMethod.id === PaymentMethodId.dotpay
      ) as DotpayPaymentMethod)?.subPayments.find((sPayment) => sPayment.id == subPaymentId)
    );
  };
  useEffect(() => {
    if (selectedPaymentMethod?.subPaymentId) {
      setSubPaymentById(selectedPaymentMethod.subPaymentId);
    }
  });

  const handleSelection = (id: PaymentMethod['id']) => {
    if (id === PaymentMethodId.dotpay) {
      if (!subPayment) {
        setShowFastPaymentPopup(true);
      } else {
        selectPaymentMethod(PaymentMethodId.dotpay, subPayment.id);
      }
    } else {
      selectPaymentMethod(id);
    }
  };

  const handleFastPaymentSelection = (id: PaymentMethodSubCategory['id']) => {
    selectPaymentMethod(PaymentMethodId.dotpay, id);
    setSubPaymentById(id);
  };

  const fastPaymentMethods = (paymentMethods.find(
    (paymentMethod) => paymentMethod.id === PaymentMethodId.dotpay
  ) as DotpayPaymentMethod)?.subPayments;

  return payAtPickup ? (
    <Section>
      <FlexContainer alignItems="center">
        <Wallet />
        <Title>Płatność przy odbiorze</Title>
      </FlexContainer>
    </Section>
  ) : (
    <>
      <MethodSelection<PaymentMethod>
        methods={paymentMethods}
        selectMethod={handleSelection}
        icon={<Wallet />}
        renderMethod={(method, isTitle) => (
          <ButtonMethodView
            isTitle={isTitle}
            editable={method.id === PaymentMethodId.dotpay}
            onEdit={() => setShowFastPaymentPopup(true)}
            name={method.name}
            iconSlot={<PaymentMethodIcons paymentMethod={method.id} />}
            additionalSlot={
              method.id === PaymentMethodId.dotpay &&
              subPayment && (
                <SubPaymentWrapper alignItems="center">
                  <SubPaymentIcon id={subPayment.id} name={subPayment.name} />
                  <SubPaymentTitle>{subPayment.name}</SubPaymentTitle>
                </SubPaymentWrapper>
              )
            }
          />
        )}
        titleCollapsed="Metoda płatności:"
        titleExpanded="Wybierz metodę płatności"
        selectedMethodId={selectedPaymentMethod?.id}
        hasError={!selectedPaymentMethod && checkOutInitiated}
      />
      {fastPaymentMethods && (
        <FastPayment
          handleSelection={handleFastPaymentSelection}
          fastPaymentMethods={fastPaymentMethods}
          isOpen={showFastPaymentPopup}
          handleClose={() => setShowFastPaymentPopup(false)}
        />
      )}
    </>
  );
};

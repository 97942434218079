import { getEnvs } from 'utils/env';
import { DefaultAddresses } from '../../models/data/DefaultAddresses';
import { get } from '../api';
import { User } from '../../models/data/Order';

const DEFAULT_ADDRESSES_URL = 'user/$userId/default-delivery-address';

export const getDefaultAddresses = async (
  userId: User['sprzedajemyUserId']
): Promise<DefaultAddresses> => {
  const response = await get<DefaultAddresses>(
    `${getEnvs().USER_BASE_URL}/${DEFAULT_ADDRESSES_URL.replace('$userId', userId.toString())}`
  );
  return response.data;
};

import { Entity } from './Entity';

export enum DeliveryMethodType {
  parcelLocker = 'parcelLocker',
  parcel = 'parcel',
  pickup = 'pickup',
}

export type UniqueDeliveryMethodName = `${DeliveryMethod['code']}_${DeliveryMethod['type']}`;

export interface DeliveryMethod extends Entity {
  id: number;
  type: DeliveryMethodType;
  code: 'inpost' | 'dhl' | 'poczta' | 'pickup' | 'orlen';
  price: number;
  deliveryTime?: string;
  parcelLockerCode?: string;
}

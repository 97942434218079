import styled from '@emotion/styled';

export const Card = styled.div<{ selected?: boolean }>`
  border: 1px dashed var(--Gray-2, #c0ced6);
  border-radius: 6px;
  width: 300px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.highlight};

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 255px;
  }

  ${({ selected, theme }) => selected && `border: solid 1px ${theme.colors.blue1}`};
  &:hover {
    border: solid 1px ${({ theme }) => theme.colors.blue1};
  }
`;

import React, { FC } from 'react';
import { DeliveryAddress } from '../../../models';
import { Flexbox } from '../atoms/FlexContainer';
import { IconWrapper } from '../../../utils/IconWrapper';
import styled from '@emotion/styled';
import { ReactComponent as AddressIcon } from 'assets/address.svg';

const AddressH3 = styled.h3`
  padding-top: 4px;
  font-weight: 600;
`;

const AddressSummaryWrapper = styled(Flexbox)`
  * {
    font-size: ${({ theme }) => theme.font.m};
  }
`;

const SelectedAddressWrapper = styled.div<{ hasToggle: boolean; noMarginTop: boolean }>`
  margin-top: 20px;
  flex-basis: 100%;
  ${({ noMarginTop }) => noMarginTop && 'margin-top: 0;'})}

  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-top: 25px;
    ${({ noMarginTop }) => noMarginTop && 'margin-top: 0;'})
  }
`;

const Spacing = styled.div`
  margin-top: 10px;
`;

const AddressP = styled.p`
  line-height: 1.43;
`;

const NameP = styled(AddressP)`
  font-weight: 600;
`;

const Description = styled.p`
  margin-top: 20px;
  flex-basis: 100%;
`;

const Summary: FC<{ summaryText: string }> = ({ summaryText }) => (
  <Flexbox alignItems="center">
    <IconWrapper Icon={AddressIcon} />
    <AddressH3>{summaryText}</AddressH3>
  </Flexbox>
);

export interface AddressSummaryProps {
  address: DeliveryAddress;
  summaryText?: string;
  description?: string;
  hasToggle?: boolean;
  noMarginTop?: boolean;
  hideEmail?: boolean;
}
export const AddressSummary: FC<AddressSummaryProps> = ({
  summaryText,
  description,
  address,
  hasToggle = false,
  noMarginTop = false,
  hideEmail = false,
}) => (
  <AddressSummaryWrapper alignItems="flex-start" wrap="wrap">
    {summaryText && <Summary summaryText={summaryText} />}
    {description && <Description>{description}</Description>}
    <SelectedAddressWrapper hasToggle={hasToggle} noMarginTop={noMarginTop}>
      <NameP>{address.fullName}</NameP>
      <AddressP>
        {address.street} {address.houseNumber}{' '}
        {address.apartmentNumber && `/ ${address.apartmentNumber}`}
      </AddressP>
      <AddressP>
        {address.postcode} {address.city}
      </AddressP>
      <AddressP>Polska</AddressP>
      <Spacing />
      <AddressP>{address.phone}</AddressP>
      {!hideEmail && <AddressP>{address.email}</AddressP>}
    </SelectedAddressWrapper>
  </AddressSummaryWrapper>
);

import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';
import styled from '@emotion/styled';

const TooltipContainer = styled.div<{ hover?: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;
  ${({ hover }) =>
    hover &&
    `
  display: flex;
  justify-content:center;
  width: 22px;  
  &:focus-within,
  &:hover {
    .tooltip {
      display: block;
    }
  }
  `}
`;

const Tooltip = styled.span<{ textarea?: boolean; hover?: boolean; textAlign?: string }>`
  position: absolute;
  font-size: ${({ theme }) => theme.font.xs};
  max-width: 300px;
  min-height: 32px;
  z-index: 1;
  padding: 15px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.error};
  border: ${({ theme }) => theme.colors.error};
  text-align: ${({ textAlign }) => textAlign};
  bottom: ${({ textarea }) => (textarea ? 170 : 50)}px;
  line-height: 18px;
  strong {
    display: block;
    margin-bottom: 10px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.error} transparent transparent transparent;
  }

  ${({ hover }) => hover && `display: none; cursor: pointer`};
  ${({ hover, theme }) =>
    hover &&
    `
  color: ${theme.colors.darkGrey1};
  background-color: ${theme.colors.white};
  bottom: 100%;
  margin-bottom: 8px;
  box-shadow: 0 0 20px 0 rgba(104, 119, 137, 0.15);
  width: max-content;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 49%;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.colors.white} transparent transparent transparent;
  }
  `};
`;

export interface InputValidationTooltipProps {
  errorMsg?: React.ReactNode;
  render: (
    onFocus: (event: SyntheticEvent) => void,
    onBlur: (event: SyntheticEvent) => void,
    hasError: boolean
  ) => ReactNode;
  textarea?: boolean;
  onHover?: boolean;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
}

export const InputValidationTooltip: FC<InputValidationTooltipProps> = ({
  errorMsg,
  render,
  textarea,
  onHover,
  textAlign = 'center',
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  if (onHover)
    return (
      <TooltipContainer hover={onHover}>
        <Tooltip textarea={textarea} hover={onHover} textAlign={textAlign} className="tooltip">
          {errorMsg}
        </Tooltip>
        {render(
          () => '',
          () => '',
          !!errorMsg
        )}
      </TooltipContainer>
    );

  return (
    <TooltipContainer>
      {!!errorMsg && showTooltip && <Tooltip textarea={textarea}>{errorMsg}</Tooltip>}
      {render(
        () => setShowTooltip(true),
        () => setShowTooltip(false),
        !!errorMsg
      )}
    </TooltipContainer>
  );
};

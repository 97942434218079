import React, { FC } from 'react';
import { InvoiceSectionProps } from '../organisms/InvoiceSection';
import styled from '@emotion/styled';

const InvoiceSummaryWrapper = styled.div<{ hideMarginTop: boolean }>`
  margin-top: 70px;
  line-height: 1.43;
  ${({ hideMarginTop }) => hideMarginTop && 'margin-top: 0'};
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-top: 25px;
    ${({ hideMarginTop }) => hideMarginTop && 'margin-top: 0;'}
  }
`;

const Spacing = styled.div`
  margin-top: 10px;
`;

const NameP = styled.p`
  line-height: 1.43;
  font-weight: 600;
`;

export const InvoiceSummary: FC<
  Required<Pick<InvoiceSectionProps, 'invoiceData'>> & { hideMarginTop?: boolean }
> = ({ invoiceData, hideMarginTop = false }) => (
  <InvoiceSummaryWrapper hideMarginTop={hideMarginTop}>
    <NameP>{invoiceData.companyName}</NameP>
    <p>
      {invoiceData.street} {invoiceData.houseNumber}
    </p>
    <p>
      {invoiceData.postcode} {invoiceData.city}
    </p>
    <p>Polska</p>
    <Spacing />
    <p>NIP: {invoiceData.nip}</p>
  </InvoiceSummaryWrapper>
);

import { call, takeLatest, put, select, take } from 'redux-saga/effects';
import {
  getDeliveryMethodsAction,
  getDeliveryMethodsSuccessAction,
} from '../../actions/DeliveryMethodsActions';
import { getDeliveryMethods } from '../../../api';
import { DeliveryMethod } from '../../../models';
import { selectOrderDeliveryMethod, selectOrderId } from '../../selectors';
import { getOrderSuccessAction } from '../../actions/OrderActions';

function* getDeliveryMethodsSaga() {
  while (true) {
    let orderId: number = yield select(selectOrderId);
    if (!orderId) {
      yield take(getOrderSuccessAction);
      orderId = yield select(selectOrderId);
    }
    let deliveryMethods: DeliveryMethod[] = yield call(getDeliveryMethods, orderId);
    const delivery:
      | { parcelLockerCode?: string; deliveryMethodId?: number }
      | undefined = yield select(selectOrderDeliveryMethod);
    deliveryMethods = deliveryMethods.map((deliveryMethod) => {
      if (deliveryMethod.id === delivery?.deliveryMethodId) {
        return { ...deliveryMethod, parcelLockerCode: delivery?.parcelLockerCode };
      }
      return deliveryMethod;
    });
    yield put(getDeliveryMethodsSuccessAction(deliveryMethods));
    return;
  }
}

export function* deliveryMethodsSaga() {
  yield takeLatest(getDeliveryMethodsAction.type, getDeliveryMethodsSaga);
}

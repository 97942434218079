import styled from '@emotion/styled';

export const Icon = styled.img<{ hasError?: boolean }>`
  ${({ hasError }) =>
    hasError &&
    'filter: brightness(0) saturate(100%) invert(38%) sepia(20%) saturate(1412%) hue-rotate(315deg) brightness(118%) contrast(117%);'}
`;

export const IconWrapper = styled.span<{ hasError?: boolean }>`
  svg {
    ${({ hasError }) =>
      hasError &&
      'filter: brightness(0) saturate(100%) invert(38%) sepia(20%) saturate(1412%) hue-rotate(315deg) brightness(118%) contrast(117%);'}
  }
`;

import { AppState } from '../reducers/rootReducer';
import { Maybe } from '../../utils/maybe';
import { DefaultAddresses } from '../../models/data/DefaultAddresses';

export const selectUserAddressesState = (state: AppState) => state.userAddresses;
export const selectUserAddresses = (state: AppState) => selectUserAddressesState(state).addresses;

export const selectDefaultAddresses = (state: AppState): Maybe<DefaultAddresses> =>
  selectUserAddressesState(state).defaultAddresses;

export const selectSessionAddresses = (state: AppState): Partial<DefaultAddresses> =>
  selectUserAddressesState(state).sessionAddresses;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as OrderSummary } from 'assets/order-summary.svg';

const OrderSummaryHeaderContainer = styled.div`
  margin-bottom: 30px;
  font-family: Arial, serif;
  padding: 0 16px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-bottom: 50px;
  }
`;
const H1 = styled.h1`
  margin-top: 15px;
  margin-bottom: 31px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.xxl};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 10px;
  }
`;
const OrderNumber = styled.div`
  margin-bottom: 21px;
`;
const OrderNumberTitle = styled.div`
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.fontMidGrey};
`;
const OrderNumberContent = styled.div`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.font.l};
  font-weight: bold;
`;
const OrderEmail = styled.div`
  font-size: ${({ theme }) => theme.font.s};
  line-height: 21px;
`;

export interface OrderSummaryHeaderProps {
  orderNumber: string;
  orderEmail: string;
}

export const OrderSummaryHeader: FC<OrderSummaryHeaderProps> = ({ orderNumber, orderEmail }) => {
  return (
    <OrderSummaryHeaderContainer>
      <OrderSummary width="258px" />
      <H1>Dziękujemy za złożenie zamówienia!</H1>
      <OrderNumber>
        <OrderNumberTitle>Potwierdzenie złożenia zamówienia nr:</OrderNumberTitle>
        <OrderNumberContent>{orderNumber}</OrderNumberContent>
      </OrderNumber>
      <OrderEmail>
        Na adres email <strong>{orderEmail}</strong> wysłaliśmy potwierdzenie tego zamówienia.
        <br />
        Przyjdą na niego informacje o statusie realizacji zamówienia
      </OrderEmail>
    </OrderSummaryHeaderContainer>
  );
};

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Section } from '../atoms/Section';
import { ProductCard } from '../molecules/ProductCard';
import { IconRoundWrapper } from 'utils/IconRoundWrapper';
import { ReactComponent as Trash } from 'assets/trash.svg';
import { Flexbox } from '../atoms/FlexContainer';
import { Product } from 'models/data/Product';
import { ReactComponent as Basket } from 'assets/basket.svg';
import { InputRadio } from '../atoms/form/InputRadio';
import {
  calculateCommission,
  calculateProductsSum,
  priceFormat,
} from '../../../utils/orderValue/orderValue';
import { TooltipCommission } from '../atoms/TooltipCommision';

const MultiSectionContainer = styled(Section)<{ active?: boolean }>`
  display: flex;
  font-family: Arial, serif;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileBig}) {
    border: none;
    background: none;
    padding: 0;
  }
  ${({ active, theme }) => active && `border-color: ${theme.colors.fontLight}`};
`;
const MultiRadioWrapper = styled.div<{ active?: boolean }>`
  width: 38px;
  padding: 33px 10px;
  box-sizing: border-box;
  background: ${({ theme, active }) =>
    active ? theme.colors.backgroundActive : theme.colors.backgroundNotActive};
  border-radius: 10px 0 0 10px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: 58px;
    padding: 33px 20px;
  }
`;
const MultiSectionWrapper = styled.div`
  width: calc(100% - 38px);
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: calc(100% - 58px);
  }
`;
const MultiSectionGap = styled.div`
  margin: 0 15px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin: 0 30px;
  }
`;
const MultiSectionHeader = styled(Flexbox)`
  padding: 27px 0 25px;
`;
const SellerName = styled(Flexbox)`
  gap: 16px;
`;
const BasketWrapper = styled.div`
  position: relative;
`;
const BasketAmount = styled.div`
  position: absolute;
  top: 0;
  right: -6px;
  width: 18px;
  height: 18px;
  padding-top: 2.5px;
  box-sizing: border-box;
  border-radius: 50%;
  text-align: center;
  font-size: ${({ theme }) => theme.font.xxs};
  background: ${({ theme }) => theme.colors.blue7};
  color: ${({ theme }) => theme.colors.white};
`;
const SellerWrapper = styled.span`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: flex;
    align-items: end;
  }
`;
const SellerTitle = styled(Flexbox)`
  font-size: ${({ theme }) => theme.font.m};
  font-weight: bold;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.font.l};
  }
`;
const SellerNameLink = styled.a`
  display: block;
  margin-top: 8px;
  margin-left: 0;
  color: ${({ theme }) => theme.colors.linkColor};
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin-top: 0;
    margin-left: 5px;
  }
`;
const DeleteAll = styled(Flexbox)`
  gap: 3px;
  margin-top: -3px;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.fontMain};
`;
const DeleteAllLabel = styled.span`
  display: none;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    display: inline-block;
  }
`;
const MultiSummary = styled.div`
  padding: 30px;
  border-top: solid 1px ${({ theme }) => theme.colors.sectionBorderColor};
`;
const MultiSummaryRow = styled(Flexbox)<{ big?: boolean }>`
  padding: 0 0 14px;
  font-size: ${({ theme }) => theme.font.xs};
  ${({ big, theme }) =>
    big &&
    `
    padding: 5px 0 0;
    font-size: ${theme.font.m}; 
    font-weight: bold;
  `};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    font-size: ${({ theme }) => theme.font.m};
    ${({ big, theme }) =>
      big &&
      `
    font-size: ${theme.font.l}; 
  `};
  } ;
`;

const SummaryLabel = styled(Flexbox)``;

export interface MultiSectionProps {
  handleDeleteProduct: (productId: string) => void;
  handleSetAmount: (productId: string, amount: number) => void;
  handleDeleteCart: (cartId: string) => void;
  handleChooseCart: (cartId: string) => void;
  cartId: string;
  isActive: boolean;
  commissionPercentage: number;
  urlMoreOffer: string;
  sellerName: string;
  products: Product[];
}

export const MultiSection: FC<MultiSectionProps> = ({
  handleDeleteProduct,
  handleSetAmount,
  handleDeleteCart,
  handleChooseCart,
  cartId,
  isActive,
  commissionPercentage,
  urlMoreOffer,
  sellerName,
  products,
}) => {
  const productInBasket = products.reduce((amount, product) => amount + product.amount, 0);
  const calculatedCommission = calculateCommission(products, commissionPercentage);
  const productsElement = products.map((product) => (
    <ProductCard
      key={product.id}
      simple={true}
      handleDeleteProduct={handleDeleteProduct}
      handleSetAmount={handleSetAmount}
      productId={product.id}
      photoUrl={product.mainImg}
      photoAlt={product.photoAlt}
      title={product.name}
      price={product.price}
      size={product.size}
      brand={product.brand}
      amount={product.amount}
      total={product.total}
      productUrl={product.url}
    />
  ));
  const handleDeleteCartFunction = () => {
    handleDeleteCart(cartId);
  };
  const handleChooseCartFunction = () => {
    handleChooseCart(cartId);
  };
  return (
    <MultiSectionContainer borderRadius="10px" active={isActive}>
      <MultiRadioWrapper active={isActive}>
        <InputRadio handleChecked={handleChooseCartFunction} checked={isActive} />
      </MultiRadioWrapper>
      <MultiSectionWrapper>
        <MultiSectionGap>
          <MultiSectionHeader alignItems="center" justifyContent="space-between">
            <SellerName alignItems="center">
              <BasketWrapper>
                <BasketAmount>{productInBasket}</BasketAmount>
                <Basket />
              </BasketWrapper>
              <SellerWrapper>
                <SellerTitle>Koszyk sprzedającego </SellerTitle>
                <SellerNameLink href={urlMoreOffer}>{sellerName}</SellerNameLink>
              </SellerWrapper>
            </SellerName>
            <DeleteAll alignItems="center">
              <IconRoundWrapper Icon={Trash} handleClick={handleDeleteCartFunction} />
              <DeleteAllLabel onClick={handleDeleteCartFunction}>Usuń koszyk</DeleteAllLabel>
            </DeleteAll>
          </MultiSectionHeader>
          {productsElement}
        </MultiSectionGap>
        <MultiSummary>
          <MultiSummaryRow justifyContent="space-between">
            <SummaryLabel>Wartość produktów</SummaryLabel>
            <strong>{priceFormat(calculateProductsSum(products).toNumber())}</strong>
          </MultiSummaryRow>
          <MultiSummaryRow justifyContent="space-between">
            <SummaryLabel>
              Opłata transakcyjna
              <TooltipCommission />
            </SummaryLabel>
            <strong>{priceFormat(calculatedCommission.toNumber())}</strong>
          </MultiSummaryRow>
          <MultiSummaryRow big justifyContent="space-between">
            <SummaryLabel>Wartość koszyka</SummaryLabel>
            <strong>
              {priceFormat(
                calculateProductsSum(products).toNumber() + calculatedCommission.toNumber()
              )}
            </strong>
          </MultiSummaryRow>
        </MultiSummary>
      </MultiSectionWrapper>
    </MultiSectionContainer>
  );
};

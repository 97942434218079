import { IconWrapper } from '../../../../utils/IconWrapper';
import { Flexbox } from '../FlexContainer';
import React, { FC } from 'react';
import { ReactComponent as Edit } from 'assets/edit.svg';
import styled from '@emotion/styled';
import { Button } from './Button';

const StyledButton = styled(Button)`
  padding: 0;
  width: 97px;
  background: inherit;
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    margin-left: 30px;
  }
`;
export const EditButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <StyledButton size="small" onClick={onClick} borderOnly>
    <Flexbox alignItems="center" justifyContent="center">
      <IconWrapper Icon={Edit} />
      Edytuj
    </Flexbox>
  </StyledButton>
);

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { FormValidationError } from '../models/errors/FormValidationError';
import { JWT } from '../models/api/JWT';
import { getJWTFromStorage, setJWTInStorage } from '../utils/auth';
import getJWT from './jwt';

axios.interceptors.request.use(async (config) => {
  const jwt = getJWTFromStorage();
  if (jwt) {
    const jwtObj = jwtDecode<JWT>(jwt);
    if (jwtObj.exp <= Date.now()) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${jwt}`;
      return config;
    }
  }
  const data = await getJWT();

  setJWTInStorage(data.jwt);

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${data.jwt}`;
  return config;
});

const handleFormError = <T>(e: Error) => {
  if (axios.isAxiosError(e)) {
    if (e.response?.status === 422) {
      const { errors } = e.response!.data;

      throw new FormValidationError<T>(errors);
    }
  }
};

export const put = async <T = {}, R = {}>(url: string, body?: T, config?: AxiosRequestConfig) => {
  try {
    return await axios.put<R>(url, body, config);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      handleFormError(e);
    }
    throw e;
  }
};

export const post = async <T = {}, R = {}>(url: string, body?: T, config?: AxiosRequestConfig) => {
  try {
    return await axios.post<R>(url, body, config);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      handleFormError(e);
    }
    throw e;
  }
};

export const del = async (url: string, config?: AxiosRequestConfig) => {
  try {
    return await axios.delete(url, config);
  } catch (e) {
    handleFormError(e as AxiosError);
    throw e;
  }
};

export const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
  axios.get<T>(url, config);

import styled from '@emotion/styled';

export enum ButtonVariant {
  primary,
  secondary,
  light,
}

type StyledButtonProps = ButtonProps;

const { primary: primaryBtn, secondary: secondaryBtn } = ButtonVariant;

export interface ButtonProps {
  variant?: ButtonVariant;
  borderOnly?: boolean;
  size: 'small' | 'middle' | 'big';
  disabled?: boolean;
  round?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  outline: none;
  border-radius: 6px;
  ${({ round }) => round && 'border-radius: 24px;'}
  width: 100%;
  font-family: inherit;
  font-weight: 700;
  padding: 0 20px;
  ${({ size, theme }) => {
    switch (size) {
      case 'small': {
        return `font-size: ${theme.font.xs}; height: 32px;`;
      }
      case 'middle': {
        return `font-size: ${theme.font.s}; height: 48px; font-weight: bold;`;
      }
      case 'big': {
        return `font-size: ${theme.font.l}; height: 48px;`;
      }
    }
  }};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  opacity: ${({ disabled }) => disabled && '.5'};
  ${({ borderOnly, variant, disabled, theme: { colors } }) => {
    if (borderOnly) {
      const background = `background-color: ${colors.white};`;
      switch (variant) {
        case primaryBtn: {
          const hover = !disabled && `:hover {background-color: ${colors.blue6}};`;
          return `${background} color: ${colors.blue1}; border: 1px solid; ${hover}`;
        }
        case secondaryBtn: {
          const hover =
            !disabled && `:hover {border-color: ${colors.blue5}; color: ${colors.blue5};};`;
          return `${background} color: ${colors.darkBlue1}; border: 1px solid; ${hover}`;
        }
        default: {
          const hover =
            !disabled && `:hover {border-color: ${colors.blue1}; color: ${colors.blue2};};`;
          return `${background} color: ${colors.darkGrey1}; border: 1px solid ${colors.grey1}; ${hover}`;
        }
      }
    } else {
      const background =
        variant === primaryBtn ? colors.gradient.primary : colors.gradient.secondary;
      const hover = !disabled && (variant === primaryBtn ? colors.blue2 : colors.darkBlue2);
      return `background: ${background}; color: ${colors.white}; border: none; :hover {background: ${hover}};`;
    }
  }};
`;

export const Button = StyledButton;

import axios from 'axios';
import { getEnvs } from 'utils/env';
import { DeliveryAddress, UserAddress } from '../../models';
import { User } from '../../models/data/Order';
import { get, post, put } from '../api';

const USER_ADDRESS_URL = 'user/$userId/addresses/parcel';

export const getUserAddresses = async (
  userId: User['sprzedajemyUserId']
): Promise<UserAddress[]> => {
  try {
    const { data } = await get<UserAddress[]>(
      `${getEnvs().USER_BASE_URL}/${USER_ADDRESS_URL.replace('$userId', userId.toString())}`
    );

    return data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 404) {
        return [];
      }
    }
    throw e;
  }
};

export const createUserAddress = async (
  address: DeliveryAddress,
  userId: User['sprzedajemyUserId']
): Promise<UserAddress> => {
  const response = await post<DeliveryAddress, { addressId: number }>(
    `${getEnvs().USER_BASE_URL}/${USER_ADDRESS_URL.replace('$userId', userId.toString())}`,
    address
  );

  return { ...address, id: response.data.addressId, default: true };
};

export const updateUserAddress = async (
  address: DeliveryAddress,
  addressId: UserAddress['id'],
  userId: User['sprzedajemyUserId']
) => {
  await put(
    `${getEnvs().USER_BASE_URL}/${USER_ADDRESS_URL.replace(
      '$userId',
      userId.toString()
    )}/${addressId}`,
    address
  );
};

export const deleteUserAddress = async (
  id: UserAddress['id'],
  userId: User['sprzedajemyUserId']
) => {
  await axios.delete(
    `${getEnvs().USER_BASE_URL}/${USER_ADDRESS_URL.replace('$userId', userId.toString())}/${id}`
  );
};

import React, { FC, SyntheticEvent, useState } from 'react';
import styled from '@emotion/styled';
import { Flexbox } from '../atoms/FlexContainer';
import { DeliveryMethod, ParcelLockerAddress } from '../../../models';
import { MethodSelection } from './MethodSelection';
import { getParsedPrice } from '../../../utils/parsedPrice/parsedPrice';
import { DeliveryMethodIcon } from '../atoms/DeliveryMethodIcon';
import { ReactComponent as Delivery } from 'assets/delivery.svg';
import { ParcelLockerSelection, ParcelLockerSelectionProps } from './ParcelLockerSelection';
import { IconWrapper } from '../../../utils/IconWrapper';
import { Button } from '../atoms/buttons/Button';
import { ReactComponent as Edit } from '../../../assets/edit.svg';

const Price = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin: 0 12px;
`;

const ParcelLockerCode = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.s};
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    max-width: 100px;
  }
`;

const Divier = styled.div`
  content: '';
  margin: 0 5px;
  width: 1px;
  height: 26px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    margin: 0 12px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0 30px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    border: ${({ theme }) => `1px solid ${theme.colors.sectionBorderColor}`};
  }
`;

const DeliveryMethodButtonInner = styled(Flexbox)`
  flex-direction: column;
  flex: 1 0 auto;
  gap: 10px;
  color: ${({ theme }) => theme.colors.fontMain};
  @media (min-width: ${({ theme }) => theme.breakpoint.laptop}) {
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
  }
`;

const DeliveryMethodDetails = styled(Flexbox)`
  gap: 20px;
  font-size: 14px;
  line-height: 1.5;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    gap: 30px;
  }
`;

const DeliveryMethodImageWithName = styled(DeliveryMethodDetails)`
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    min-width: 253px;
  }
`;

const DeliveryInfo = styled(Flexbox)`
  flex: 1;
`;

const DeliveryTimeInfo = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkBlue1};
`;

const EditButton = styled(Button)`
  padding: 0;
  width: 97px;
`;

type DeliveryMethodViewProps = Pick<
  DeliveryMethod,
  'id' | 'name' | 'price' | 'deliveryTime' | 'code' | 'type'
> & {
  onParcelLockerChange: (e: React.SyntheticEvent) => void;
  parcelLockerCode?: string;
  title?: boolean;
};

const DeliveryMethodView: FC<DeliveryMethodViewProps> = ({
  id,
  name,
  price,
  deliveryTime,
  code,
  type,
  onParcelLockerChange,
  parcelLockerCode,
  title,
}) => (
  <DeliveryMethodButtonInner>
    <DeliveryMethodDetails alignItems="center">
      <DeliveryMethodImageWithName alignItems="center">
        <DeliveryMethodIcon deliveryMethod={`${code}_${type}`} />
        {name}&nbsp;
      </DeliveryMethodImageWithName>
    </DeliveryMethodDetails>
    <DeliveryInfo justifyContent="space-between">
      <Flexbox alignItems="center">
        <DeliveryTimeInfo>{deliveryTime}</DeliveryTimeInfo>
        <Divier />
        {parcelLockerCode && (
          <ParcelLockerCode>
            {code === 'inpost' ? 'Paczkomat' : 'Punkt'} {parcelLockerCode}
          </ParcelLockerCode>
        )}
      </Flexbox>
      <Flexbox alignItems="center">
        <Price>{getParsedPrice(price)}</Price>
        {!title && parcelLockerCode && (
          <EditButton size="small" borderOnly onClick={onParcelLockerChange}>
            <IconWrapper Icon={Edit} />
            Edytuj
          </EditButton>
        )}
      </Flexbox>
    </DeliveryInfo>
  </DeliveryMethodButtonInner>
);

export interface DeliveryMethodSelectionSectionProps {
  deliveryMethods: Array<DeliveryMethod & { parcelLockerCode?: string }>;
  selectDeliveryMethod: (deliveryMethod: DeliveryMethod) => void;
  selectedDeliveryMethodId?: number;
  checkOutInitiated?: boolean;
  handleShowInPostWidget: (show: boolean) => {};
  title?: boolean;
}

export const DeliveryMethodSelectionSection: FC<DeliveryMethodSelectionSectionProps> = ({
  deliveryMethods,
  selectDeliveryMethod,
  selectedDeliveryMethodId,
  checkOutInitiated,
  handleShowInPostWidget,
}) => {
  const [isExpanded, setIsExpanded] = useState(!selectedDeliveryMethodId);
  const [partialDeliveryMethod, setParcialDeliveryMethod] = useState<DeliveryMethod>();
  const handleParcelLockerSelection = (code: ParcelLockerAddress['parcelLockerCode']) => {
    handleShowInPostWidget(false);
    selectDeliveryMethod({ ...partialDeliveryMethod!, parcelLockerCode: code });
    setIsExpanded(false);
  };

  const handleDeliveryMethodSelection = (
    deliveryMethodId: DeliveryMethod['id'],
    isEdit?: boolean
  ) => {
    const deliveryMethod = deliveryMethods.find((method) => method.id === deliveryMethodId);
    setIsExpanded(true);
    if (!deliveryMethod) {
      return;
    }
    if (deliveryMethod.type === 'parcelLocker' && (isEdit || !deliveryMethod.parcelLockerCode)) {
      setParcialDeliveryMethod(deliveryMethod);
      handleShowInPostWidget(true);
    } else {
      selectDeliveryMethod(deliveryMethod);
      setIsExpanded(false);
    }
  };

  const handleEditButtonClick = (e: SyntheticEvent, deliveryMethodId: DeliveryMethod['id']) => {
    e.stopPropagation();
    handleDeliveryMethodSelection(deliveryMethodId, true);
  };
  return (
    <>
      <ParcelLockerSelection
        handleParcelLockerSelection={handleParcelLockerSelection}
        handleParcelLockerClose={() => handleShowInPostWidget(false)}
        type={partialDeliveryMethod?.code as ParcelLockerSelectionProps['type']}
      />
      <MethodSelection<DeliveryMethod & { parcelLockerCode?: string }>
        expanded={isExpanded}
        methods={deliveryMethods}
        selectedMethodId={selectedDeliveryMethodId}
        selectMethod={handleDeliveryMethodSelection}
        icon={<Delivery />}
        hasError={checkOutInitiated && !selectedDeliveryMethodId}
        renderMethod={(deliveryMethod, title) => (
          <DeliveryMethodView
            {...deliveryMethod}
            title={title}
            onParcelLockerChange={(e) => {
              handleEditButtonClick(e, deliveryMethod.id);
            }}
          />
        )}
        titleCollapsed="Sposób dostawy:"
        titleExpanded="Wybierz sposób dostawy"
      />
    </>
  );
};

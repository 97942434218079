import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blue1: '#0189cd',
    blue2: '#3a8cd6',
    blue3: '#3b8cd6',
    blue4: '#47a4e0',
    blue5: '#2499d3',
    blue6: '#0189cd1a',
    blue7: '#0587D2',
    darkBlue1: '#4b6487',
    darkBlue2: '#435C7D',
    darkBlue3: '#4c6488',
    grey1: '#dbe4e8',
    darkGrey1: '#313d4f',
    fontMidGrey: '#88949E',
    fontMain: '#22354f',
    fontLight: '#8094A8',
    sectionBorderColor: '#DFE6EA',
    highlight: '#fafbfc',
    linkColor: '#3b5998',
    placeholder: '#989EA7',
    error: '#e55951',
    errorBackground: '#fdf4f3',
    grayBackground: '#F3F6F7',
    backgroundActive: '#d2dbe0',
    backgroundNotActive: '#e0e6e9',
    gradient: {
      primary: `linear-gradient(to top, #3b8cd6, #47a4e0);`,
      secondary: 'linear-gradient(to bottom, #4c6488, #425b7c 97%);',
    },
  },
  font: {
    xxs: '0.7rem',
    xs: '0.8rem',
    s: '0.875rem',
    m: '1rem',
    l: '1.125rem',
    xl: '1.5rem',
    xxl: '2rem',
  },
  breakpoint: {
    mobile: '360px',
    mobileBig: '576px',
    tablet: '768px',
    tabletBig: '992px',
    laptop: '1200px',
    desktop: '1440px',
  },
};

export default theme;

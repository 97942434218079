import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import {
  deliveryMethodsReducer,
  DeliveryMethodsState,
} from './delivery-methods/deliveryMethodsReducer';
import { orderReducer, OrderState } from './order/orderReducer';
import { userAddressesReducer, UserAddressesState } from './user-addresses/userAddressesReducer';
import { formsReducer, FormsState } from './forms/formsReducer';
import { utilReducer, UtilState } from './util/utilReducer';
import { paymentReducer, PaymentState } from './payment/paymentReducer';
import { errorsReducer, ErrorsState } from './errors/errorsReducer';
import cartReducer, { CartState } from '../slices/cartSlice';

export interface AppState {
  cartOrders: CartState;
  deliveryMethods: DeliveryMethodsState;
  order: OrderState;
  userAddresses: UserAddressesState;
  payment: PaymentState;
  forms: FormsState;
  util: UtilState;
  router: RouterState;
  errors: ErrorsState;
}

export const createRootReducer = (history: History) =>
  combineReducers<AppState>({
    deliveryMethods: deliveryMethodsReducer,
    order: orderReducer,
    userAddresses: userAddressesReducer,
    payment: paymentReducer,
    forms: formsReducer,
    util: utilReducer,
    errors: errorsReducer,
    router: connectRouter(history),
    cartOrders: cartReducer,
  });

import styled from '@emotion/styled';

export const SectionRedesigned = styled.section<{ hasError?: boolean }>`
  width: 100%;
  border: solid 1px
    ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.sectionBorderColor)};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 25px 15px;
  position: relative;
`;

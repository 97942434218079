import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import reportWebVitals from './reportWebVitals';
import { App, AppProps } from './App';
import { Env, getEnvs } from './utils/env';
import { createRoot } from 'react-dom/client';

interface IGlobal {
  sprzedajemy: object;
  window: Window;
}

declare let global: IGlobal;

export const KTPlugin = (
  handleForbidden: AppProps['handleForbidden'],
  handleUnauthorized: AppProps['handleUnauthorized'],
  env?: Partial<Record<Env, string>>
) => {
  // eslint-disable-next-line
  (window as any)._env_ = { ...getEnvs(), ...(env || {}) };
  const element = document.getElementById('root');

  if (!element) {
    return null;
  }

  const root = createRoot(element);

  root.render(React.createElement(App, { handleForbidden, handleUnauthorized }));
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

global.sprzedajemy = {
  KTPlugin,
};

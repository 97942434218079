import { UniqueDeliveryMethodName } from '../../../models';
import React, { ReactElement } from 'react';

import { ReactComponent as InPost } from 'assets/inpost.svg';
import { ReactComponent as DHL } from 'assets/dhl.svg';
import { ReactComponent as Orlen } from 'assets/orlen.svg';
import { ReactComponent as Pocztex } from 'assets/pocztex.svg';
import styled from '@emotion/styled';

const icons: Partial<Record<UniqueDeliveryMethodName, ReactElement>> = {
  inpost_parcelLocker: <InPost />,
  orlen_parcelLocker: <Orlen />,
  inpost_parcel: <InPost />,
  dhl_parcel: <DHL />,
  poczta_parcel: <Pocztex />,
};

const IconWrapper = styled.div`
  min-width: 100px;
`;

export const DeliveryMethodIcon = ({
  deliveryMethod,
}: {
  deliveryMethod: UniqueDeliveryMethodName;
}) => {
  return <IconWrapper>{icons[deliveryMethod] || null}</IconWrapper>;
};

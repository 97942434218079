import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { SchemaOf, object, string } from 'yup';
import styled from '@emotion/styled';
import { DeliveryAddress } from 'models';
import { Flexbox } from 'components/UI/atoms/FlexContainer';
import { SaveCancelButtons } from '../molecules/SaveCancelButtons';
import { FormContainer } from '../atoms/form/Form';
import { FormInputField, Label } from '../atoms/form/FormInputField';
import { fullNameRegExp, phoneRegExp, postCodeRegExp } from '../../../utils/regex';
import { createHandlePostalCode } from '../../../utils/form/handlePostalCode';
import Select from '../atoms/Select';

const initialValues: DeliveryAddress = {
  fullName: '',
  street: '',
  city: '',
  postcode: '',
  phone: '',
  email: '',
  companyName: '',
  houseNumber: '',
  apartmentNumber: null,
  country: 'PL',
};

const validationSchema: SchemaOf<DeliveryAddress> = object().shape({
  fullName: string()
    .matches(fullNameRegExp, 'Proszę podać imię i nazwisko np. (Jan Kowalski)')
    .required('Musisz wpisać Imię i Nazwisko'),
  street: string().required('Musisz wpisać adres'),
  city: string().required('Musisz wpisać miasto'),
  postcode: string()
    .matches(postCodeRegExp, 'Niepoprawny format kodu pocztowego')
    .required('Musisz wpisać kod pocztowy'),
  phone: string()
    .matches(phoneRegExp, 'Niepoprawny format numeru telefonu')
    .required('Numer telefony jest wymagany'),
  email: string().email('Niepoprawny format adresu email').required('Email jest wymagany'),
  houseNumber: string().required('Numer domu jest wymagany'),
  apartmentNumber: string().nullable(),
  country: string().required('Kraj jest wymagany'),
  companyName: string().nullable(),
});

const InputContainer = styled(Flexbox)`
  margin-bottom: 16px;
`;

export interface DeliveryAddressFormProps {
  handleClose: () => void;
  handleSubmit: (address: DeliveryAddress) => void;
  inPopup?: boolean;
  checkoutInitiated?: boolean;
  initialAddressValues?: DeliveryAddress;
  formErrors?: Partial<Record<keyof DeliveryAddress, string>>;
}

export const DeliveryAddressForm: FC<DeliveryAddressFormProps> = ({
  handleClose,
  inPopup,
  checkoutInitiated,
  handleSubmit,
  initialAddressValues,
  formErrors,
}) => {
  const formik = useFormik<DeliveryAddress>({
    initialValues: initialAddressValues
      ? { ...initialAddressValues, companyName: initialAddressValues?.companyName || undefined }
      : initialValues,
    validationSchema,
    onSubmit(values: DeliveryAddress) {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (checkoutInitiated) formik.validateForm();
  }, [checkoutInitiated]);

  const handlePostal = createHandlePostalCode(formik);

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <InputContainer direction="column" id="fullName" alignItems="flex-start">
        <FormInputField
          labelText="Imię i Nazwisko"
          name="fullName"
          value={formik.values.fullName}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.fullName && formik.errors.fullName) || formErrors?.fullName}
          inPopup={inPopup}
          placeholder={'Podaj imię i nazwisko'}
        />
      </InputContainer>
      <InputContainer direction="column" id="companyName" alignItems="flex-start">
        <FormInputField
          labelText="Nazwa firmy (opcjonalnie)"
          name="companyName"
          value={formik.values.companyName || undefined}
          handleChange={formik.handleChange}
          errorMsg={
            (formik.touched.companyName && formik.errors.companyName) || formErrors?.companyName
          }
          inPopup={inPopup}
          placeholder={'Podaj nazwę firmy'}
        />
      </InputContainer>
      <InputContainer direction="column" id="street" alignItems="flex-start">
        <FormInputField
          labelText="Ulica"
          name="street"
          value={formik.values.street}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.street && formik.errors.street) || formErrors?.street}
          inPopup={inPopup}
          placeholder={'Podaj ulicę'}
        />
      </InputContainer>
      <InputContainer direction="column" id="houseNumber" alignItems="flex-start">
        <FormInputField
          labelText="Nr domu"
          name="houseNumber"
          value={formik.values.houseNumber}
          handleChange={formik.handleChange}
          errorMsg={
            (formik.touched.houseNumber && formik.errors.houseNumber) || formErrors?.houseNumber
          }
          inPopup={inPopup}
          placeholder={'Nr domu'}
        />
      </InputContainer>
      <InputContainer direction="column" id="apartmentNumber" alignItems="flex-start">
        <FormInputField
          labelText="Nr lokalu (opcjonalnie)"
          name="apartmentNumber"
          value={formik.values.apartmentNumber ?? undefined}
          handleChange={formik.handleChange}
          errorMsg={
            (formik.touched.apartmentNumber && formik.errors.apartmentNumber) ||
            formErrors?.apartmentNumber
          }
          inPopup={inPopup}
          placeholder={'Nr lokalu'}
        />
      </InputContainer>
      <InputContainer direction="column" id="postcode" alignItems="flex-start">
        <FormInputField
          labelText="Kod pocztowy"
          name="postcode"
          value={formik.values.postcode}
          handleChange={handlePostal}
          errorMsg={(formik.touched.postcode && formik.errors.postcode) || formErrors?.postcode}
          inPopup={inPopup}
          placeholder={'xx-xxx'}
        />
      </InputContainer>
      <InputContainer direction="column" id="city" alignItems="flex-start">
        <FormInputField
          labelText="Miejscowość"
          name="city"
          value={formik.values.city}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.city && formik.errors.city) || formErrors?.city}
          inPopup={inPopup}
          placeholder={'Podaj miejscowość'}
        />
      </InputContainer>
      <InputContainer direction="column" alignItems="flex-start" id="country">
        <Label htmlFor={'country'}>{'Kraj'}</Label>
        <Select
          selectedItems={[{ label: 'Polska', value: 'PL' }]}
          onSelectOption={({ value }) => formik.setFieldValue('country', value)}
          selectLabel="Polska"
        />
      </InputContainer>
      <InputContainer direction="column" id="phone" alignItems="flex-start">
        <FormInputField
          labelText="Telefon"
          name="phone"
          placeholder="xxx-xxx-xxx"
          value={formik.values.phone}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.phone && formik.errors.phone) || formErrors?.phone}
          type="phone"
          inPopup={inPopup}
        />
      </InputContainer>
      <InputContainer direction="column" id="email" alignItems="flex-start">
        <FormInputField
          labelText="E-mail"
          name="email"
          value={formik.values.email}
          handleChange={formik.handleChange}
          errorMsg={(formik.touched.email && formik.errors.email) || formErrors?.email}
          inPopup={inPopup}
          placeholder={'Podaj e-mail'}
        />
      </InputContainer>
      <SaveCancelButtons handleCancel={handleClose} />
    </FormContainer>
  );
};
